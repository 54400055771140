import React, { useRef } from 'react';
import { InputSelect } from './InputSelect';
import PropTypes from 'prop-types';

export const InputSelectRequest = ({isGemseki, mode, status, onChangeStatus}) => {
  const selectRef = useRef(null);
  let selectArray;
  if (isGemseki) {
    if (mode === 'NCD') {
      selectArray = [
        {label: 'New Request', value: 'newRequest'},
        {label: 'Confirming', value: 'confirming'},
        {label: 'Declined by Originator', value: 'declinedByOriginator'},
        {label: 'Approved by Originator', value: 'approvedByOriginator'},
        {label: 'Pending', value: 'pending'},
        {label: 'Declined by Licensee', value: 'declinedByLicensee'},
      ];
    } else {
      selectArray = [
        {label: 'New Request', value: 'newRequest'},
        {label: 'Confirming', value: 'confirming'},
        {label: 'Declined by Originator', value: 'declinedByOriginator'},
        {label: 'Evaluating', value: 'evaluating'},
        {label: 'Pending', value: 'pending'},
        {label: 'Declined by Licensee', value: 'declinedByLicensee'},
      ];
    }
  } else {
    if ((status === 'newRequest') || (status === 'declinedByLicensee')) {
      selectArray = [
        {label: 'New Request', value: 'newRequest'},
        {label: 'Declined by Licensee', value: 'declinedByLicensee'},
      ];
    } else if (status === 'confirming') {
      selectArray = [
        {label: 'Confirming', value: 'confirming'},
      ];
      if (mode === 'NCD') {
        selectArray.push(
          {label: 'Declined by Licensee', value: 'declinedByLicensee'},
        );
      }
    } else if (status === 'declinedByOriginator') {
      selectArray = [
        {label: 'Declined by Originator', value: 'declinedByOriginator'},
      ];
    } else if (status === 'evaluating') {
      selectArray = [
        {label: 'Evaluating', value: 'evaluating'},
        {label: 'Pending', value: 'pending'},
        {label: 'Declined by Licensee', value: 'declinedByLicensee'},
      ];
    } else if (status === 'approvedByOriginator') {
      selectArray = [
        {label: 'Approved by Originator', value: 'approvedByOriginator'},
        {label: 'Pending', value: 'pending'},
        {label: 'Declined by Licensee', value: 'declinedByLicensee'},
      ];
    } else if (status === 'pending') {
      selectArray = [
        {label: 'Pending', value: 'pending'},
        {label: 'Declined by Licensee', value: 'declinedByLicensee'},
      ];
    }
  }

  return (
    <InputSelect name="status" ref={selectRef} value={status} size="280px" placeholder="" valueGroup={selectArray} isClearable={false} onChange={(item) => {
        if (status !== item.value) {
            selectRef.current.value = status;
            onChangeStatus(item);
        }
    }}/>
  );
};

InputSelectRequest.propTypes = {
  isGemseki: PropTypes.bool,
  mode: PropTypes.string,
  status: PropTypes.string,
  onChangeStatus: PropTypes.func,
};

InputSelectRequest.defaultProps = {
  isGemseki: false,
  mode: 'NCD',
  status: null,
  onChangeStatus: () => {},
};
