import {
  PAUSE,
  RESUME,
} from '../actions/api';
import {
  LOGIN_ERROR,
  ERROR,
  ERROR_CLEAR,
  ERROR_CLEAR_ALL,
  REQUEST_CORE,
  RECEIVE_CORE,
  RECEIVE_NAMES,
  REDIRECT_SAVE,
} from '../actions/core';
import {
  VALIDATION_ERROR,
  VALIDATION_CLEAR,
} from '../actions/validator';

const INITIAL = {
  loading: 0,
  hasValidationError: false,
  validationErrors: [],
  user: {},
  organization: {},
  errors: [],
};

export default function core(state = INITIAL, action) {
  const {type, ...data} = action;
  switch (action.type) {
    case PAUSE:
      return {
        ...state,
        loading: state.loading + 1,
      };
    case RESUME:
      return {
        ...state,
        loading: Math.max(0, state.loading - 1),
      };
    case ERROR:
      return {
        ...state,
        hasError: true,
        errors: data.errors ? data.errors : [
          ...state.errors.filter(e => e.errorType !== data.errorType),
          {errorType: data.errorType, conflict: data.conflict},
        ],
      };
    case ERROR_CLEAR:
      const errors = state.errors.filter(e => e.errorType !== data.errorType);
      return {
        ...state,
        hasError: !!errors.length,
        errors: errors,
      };
    case ERROR_CLEAR_ALL:
      return {
        ...state,
        hasError: false,
        errors: [],
      };
    case VALIDATION_ERROR:
      return {
        ...state,
        hasValidationError: true,
        validationErrors: data.errors,
      };
    case LOGIN_ERROR:
      return {
        ...state,
        loginError: true,
      };
    case VALIDATION_CLEAR:
      return {
        ...state,
        hasValidationError: false,
        validationErrors: [],
      };
    case REDIRECT_SAVE:
      return {
        ...state,
        redirectTo: data.location,
      };
    case REQUEST_CORE:
    case RECEIVE_CORE:
      return {
        ...state,
        ...data,
      };
    case RECEIVE_NAMES:
      return {
        ...state,
        originatorNames: data.originatorNames,
        licenseeNames: data.licenseeNames,
      };
    default:
      return state;
  }
};
