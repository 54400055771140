import React from 'react';
import { Link } from 'react-router-dom';
import { ButtonBack } from './ButtonBack';

import PropTypes from 'prop-types';
import './chatTitle.css';

export const ChatTitle = ({room, user, children}) => {
  const isGemseki = user?.organizationType === 'gemseki';
  const userDir = user?.organizationType || 'licensee';
  return (
    <div className="chatTitle">
      <div className="chatTitleL">
        <ButtonBack/>
        <h3>{room?.asset?.assetNumber}</h3>
        <ul className="listRequestHeader">
            {isGemseki && <li><span>Licensee</span><Link className={`isValid--${room?.licenseeOrgStatus}`} to={`/gemseki/licensee/${room?.licenseeOrgId}`}>{room?.licenseeOrgName}</Link></li>}
            {isGemseki && <li><span>Originator</span><Link className={`isValid--${room?.originatorOrgStatus}`} to={`/gemseki/originator/${room?.originatorOrgId}`}>{room?.originatorOrgName}</Link></li>}
        </ul>
        {children}
      </div>
      <Link className="chatTitleDetail" to={`/${userDir}/asset/${room?.asset?._id}`}>Asset detail &gt;</Link>
    </div>
  );
};

ChatTitle.propTypes = {
  /**
   * ルーム情報
   */
  room: PropTypes.object,
  /**
   * ログインユーザー情報
   */
  user: PropTypes.object,
};

ChatTitle.defaultProps = {
};
