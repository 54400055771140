import React from 'react';
import PropTypes from 'prop-types';
import './popup.css';
import { Button } from './Button';

export class Popup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: !!props.initialShow,
    };
  }

  get name() {
    return this.props.name;
  }

  show() {
    this.setState({show: true});
  }

  hide() {
    this.setState({show: false});
  }

  render() {
    const {children, title, titleAlign, cancelButton, submitButton, onSubmit, onCancel} = this.props;
    return (
      <div className={['popup', this.state.show ? 'visible' : 'hide'].join(' ')}>
        <div className="popupBase" onClick={() => this.hide()}/>
        <div className="popupFrame">
          {title && <h2 className={`popupTitle--${titleAlign}`}>{title}</h2>}
          {children}
          <div className="popupButton">
            {cancelButton && <Button label={cancelButton} size="popupCancel" onClick={() => {
              onCancel?.call();
              this.hide();
            }}/>}
            {submitButton && <Button label={submitButton} size="popupSubmit" onClick={onSubmit}/>}
          </div>
        </div>
      </div>
    );
  }
}

Popup.propTypes = {
  /**
   * フォーム名
   */
  name: PropTypes.string,
  /**
   * 見出しテキスト
   */
  title: PropTypes.string,
  /**
   * 見出しテキストの左右揃え
   */
  titleAlign: PropTypes.string,
   /**
    * キャンセルボタンテキスト
  */
  cancelButton: PropTypes.string,
  /**
   * 送信ボタンテキスト
   */
  submitButton: PropTypes.string,
  /**
   * true で初期表示
   */
  initialShow: PropTypes.bool,
  /**
   * サブミット時コールバック
   */
  onSubmit: PropTypes.func,
  /**
   * サブミット時コールバック
   */
  onCancel: PropTypes.func,
};

Popup.defaultProps = {
  title: 'NCDリクエストを送信します。',
  titleAlign: 'left',
  cancelButton: 'Cancel',
  submitButton: 'Send',
  initialShow: false,
  onSubmit: () => {
  },
};
