import { applyMiddleware, combineReducers, createStore as create } from 'redux';
import thunk from 'redux-thunk';
import { reducers } from './reducers';

export const createStore = (...middleware) => {
  return create(combineReducers(reducers), applyMiddleware(thunk, ...middleware));
};

const defaultStore = createStore();
export default defaultStore;
