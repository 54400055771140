import React from 'react';
import './assetMarkChecks.css';
import PropTypes from 'prop-types';
import { isMark } from "./refs";

export class AssetMarkChecks extends React.Component {
  constructor(props) {
    super(props);
    this.markFeaturedRef = React.createRef();
    this.markUpdatedRef = React.createRef();
  }

  get name() {
    return this.props.name;
  }

  get markFeatured() {
    return this.markFeaturedRef?.current?.checked ?? false;
  }

  get markUpdated() {
    return this.markUpdatedRef?.current?.checked ?? false;
  }

  render() {
    const {name} = this.props;
    const marks = this.props.marks || {featured: null, updated: null};
    return (
      <div className="assetMarkChecks">
        <div key={`checkBoxGroup:${name}:0`} className="checkBoxGroupBox">
          <label className={`input-${name}`}>
            <input
              type="checkbox"
              id={`checkBoxGroup-ID--${name}0`}
              defaultChecked={isMark(marks.featured)}
              ref={this.markFeaturedRef}
            />
            <span>Featured</span>
          </label>
        </div>
        <div key={`checkBoxGroup:${name}:1`} className="checkBoxGroupBox">
          <label className={`input-${name}`}>
            <input
              type="checkbox"
              id={`checkBoxGroup-ID--${name}1`}
              defaultChecked={isMark(marks.updated)}
              ref={this.markUpdatedRef}
            />
            <span>Updated</span>
          </label>
        </div>
      </div>
    );
  }
}

AssetMarkChecks.propTypes = {
  /**
   * 要素の名前　重複注意
   */
  name: PropTypes.string.isRequired,
  /**
   * アセットマーク
   */
  marks: PropTypes.object,
};

AssetMarkChecks.defaultProps = {
  name: 'name',
};
