export const VALIDATION_ERROR = 'VALIDATION_ERROR';
export const VALIDATION_CLEAR = 'VALIDATION_CLEAR';

const resolveValues = (parent, names, target) => {
  if (names.length === 0) return [{p: parent.join('_'), v: null}];
  const name = names[0];
  if (!target) return [{p: [...parent, name].join('_'), v: null}];
  const current = [...parent, name];
  switch (names.length) {
    case 1:
      return [{p: current.join('_'), v: target[name]}];
    default:
      const child = target[name];
      const next = names.slice(1);
      if (Array.isArray(child)) {
        return child.flatMap((c, i) => resolveValues([...parent, `${name}${i}`], next, c));
      } else {
        return resolveValues(current, next, child);
      }
  }
};

const recursive = (callback, name, ...otherParams) => {
  const names = name.split(/\./g);
  return (target) => {
    const values = resolveValues([], names, target);
    const result = [];
    for (const value of values) {
      const r = callback(value.p, value.v, ...otherParams);
      if (r) {
        result.push(r);
      }
    }
    return result;
  };
};

export const required = recursive.bind(null, (name, value) => {
  return !value ? {name, type: 'required'} : false;
});

export const notEmpty = recursive.bind(null, (name, value) => {
  return !value || !Array.isArray(value) || !value.length ? {name, type: 'required'} : false;
});

export const minPassword = recursive.bind(null, (name, value) => {
  return value && (!value.length || value.length < 8) ? {name, type: 'minPassword'} : false;
});

export const patternPassword = recursive.bind(null, (name, value) => {
  return value && value.replace(/[A-Za-z0-9!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/g, '').length !== 0 ? {name, type: 'patternPassword'} : false;
});

export const patternEmail = recursive.bind(null, (name, value) => {
  return value && !value.match(/^[^@]+@[^@]+\.[^@]+$/) ? {name, type: 'patternEmail'} : false;
});

export const matchesConfirm = (name, to) => {
  return (target) => {
    const value = target[name];
    const toValue = target[to];
    return value !== toValue ? {name, type: 'matchesConfirm'} : false;
  };
};

export const validate = (target, ...rules) => {
  const result = [];
  for (const rule of rules) {
    const r = rule(target);
    if (Array.isArray(r)) {
      result.push(...r);
    } else if (r) {
      result.push(r);
    }
  }
  return {
    error: !!result.length,
    result,
  };
};

export const clearValidation = () => async (dispatch) => {
  dispatch({type: VALIDATION_CLEAR});
};

export const defaultInvalidHandler = (validation, dispatch) => {
  dispatch({
    type: VALIDATION_ERROR,
    errors: validation.result,
  });
  return true;
};

export const isValid = (validation, dispatch, invalidHandler = defaultInvalidHandler, successHandler = () => false) => {
  if (validation.error) {
    return invalidHandler(validation, dispatch);
  }
  dispatch({type: VALIDATION_CLEAR});
  return successHandler();
};
