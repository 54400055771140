import {
  REQUEST_CDA_REQUEST,
  RECEIVE_CDA_REQUEST,
} from '../actions/cda_request';

export default function cda_request(state = {}, action) {
  const {type, ...data} = action;
  switch (type) {
    case REQUEST_CDA_REQUEST:
    case RECEIVE_CDA_REQUEST:
      return {
        ...state,
        ...data,
      };
    default:
      return state;
  }
};
