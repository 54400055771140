import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Input1Line } from './Input1Line';
import { Button } from './Button';

import './search.css';
import { useHistory, useLocation } from 'react-router';
import { useQuery } from './refs';

export const Search = ({selectType, children, resolveParams}) => {
  const history = useHistory();
  const wordsRef = useRef(null);
  const location = useLocation();
  const query = useQuery();
  const {pathname, hash} = location;
  const freeWord = selectType === "request" ? 'Free Word (About Assets)' : 'Free Word';

  useEffect(() => {
    wordsRef.current.value = query.get('words');
  }, [query, pathname, hash]);

  return (
    <div className={`search searchLayout--${selectType}`}>
      {(selectType !== "guestTech" && selectType !== "guestSeeds") && children}
      <Input1Line name="searchWord" label={freeWord} ref={wordsRef} placeholder="Type here…" defaultValue={query.get('words')}/>
      {(selectType === "guestTech" || selectType === "guestSeeds") && children}
      <Button label="Search" primary={true} onClick={() => {
      const words = wordsRef.current.value;
      if (words) {
        query.set('words', words);
      } else {
        query.delete('words');
      }
      query.delete('page');
      const params = resolveParams() || {};
      for (const key of Object.getOwnPropertyNames(params)) {
        const value = params[key];
        if (value) {
          query.set(key, value);
        } else {
          query.delete(key);
        }
      }
      history.push(`?${query}${location.hash}`);
    }}/>
    </div>
  );
};

Search.propTypes = {
  /**
   * Selectを入れる位置のタイプ
   */
  selectType: PropTypes.string,
   /**
   * children の値を解決する為のコールバック
   */
  resolveParams: PropTypes.func,
};

Search.defaultProps = {
  selectType: "default",
  resolveParams: () => {},
};
