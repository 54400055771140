import React, { useCallback, useEffect, useState  } from 'react';

import { ButtonH1Side } from '../../parts/ButtonH1Side';
import { Card } from '../../parts/Card';
import { ListRequest } from '../../parts/ListRequest';
import { PageNavi } from '../../parts/PageNavi';

import { useDispatch, useSelector } from 'react-redux';
import { findCdaRequestByGemseki, requestCda, updateCda } from '../../actions/cda_request';
import { getNames } from '../../actions/core';
import { loadGemsekiCounts } from '../../actions/dashboard';
import { ref, useQuery } from '../../parts/refs';
import {Search} from '../../parts/Search';
import {InputSelect} from '../../parts/InputSelect';
import {DataDevelopmentStage, DataDiseaseArea} from '../../reducers/datas';
import { SortBy } from '../../parts/SortBy';

import '../base.css';
import { RequestPopups, useCdaControl } from '../../parts/RequestPopups';
import { useTitle } from '../../parts/useTitle';

export const CdaRequest = () => {
  useTitle('CDA request');

  const dispatch = useDispatch();
  const query = useQuery();
  const page = parseInt(query.get('page') || '0');

  const boolAll = query.get('status') ? false : true;
  const status = query.get('status') || 'all';

  useEffect(() => {
    dispatch(getNames());
  }, [dispatch]);

  useEffect(() => {
    dispatch(findCdaRequestByGemseki(query));
  }, [dispatch, query]);

  const [cdaRequestsByGemseki, total, user, originatorNames, licenseeNames] = useSelector(s => [s.cda_request.cdaRequestsByGemseki || [], s.cda_request.total || 1, s.core.user, s.core.originatorNames || [], s.core.licenseeNames || []]);

  const newCallback = useCallback(async (assetId, licenseeOrgId, comment) => {
    return await dispatch(requestCda(assetId, licenseeOrgId, comment));
  }, [dispatch]);

  const updateCallback = useCallback(async (targetId, newStatus, comment, reasons) => {
    return await dispatch(updateCda(targetId, newStatus, comment, reasons));
  }, [dispatch]);

  const reloadCallback = useCallback(() => {
    dispatch(findCdaRequestByGemseki(query));
    dispatch(loadGemsekiCounts());
  }, [dispatch, query]);

  const [showStatusChange, popupControl] = useCdaControl(
    user.organizationType,
    newCallback,
    updateCallback,
    reloadCallback,
  );

  function isNew(request, user) {
    return request.status === 'newRequest' && new Date(request.updatedAt) > new Date(user.beforeLoginAt);
  }

  const pForms = {};
  const resolveParams = () => {
    const params = {};

    params.developmentStage = pForms.developmentStage.value;
    params.diseaseArea = pForms.diseaseArea.value;
    params.licenseeOrgId = pForms.licenseeOrgId.value;
    params.originatorOrgId = pForms.originatorOrgId.value;

    return params;
  };

  /* 開閉状態保持バージョン
  var isOpen = useSelector(s => !!s.core.isSelectorOpen);
  const onOpenClose = () => {
    dispatch(setSelectorOpen(!isOpen));
  };*/

  var [isOpen, setIsOpen] = useState(false);
  const onOpenClose = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div id="container" className="layout-login contsRequest">

      <main>
        <h1>CDA request</h1>
        <div id='containerMain'>
        <ButtonH1Side selected={status} boolAll={boolAll}
                                     valueGroup={[
                                       {label: 'All', value: 'all', url: '/gemseki/cda_requests'},
                                       {label: 'New Request', value: 'newRequest', url: '/gemseki/cda_requests?status=newRequest'},
                                       {label: 'Confirming', value: 'confirming', url: '/gemseki/cda_requests?status=confirming'},
                                       {label: 'Declined by Originator', value: 'declinedByOriginator', url: '/gemseki/cda_requests?status=declinedByOriginator'},
                                       {label: 'Evaluating', value: 'evaluating', url: '/gemseki/cda_requests?status=evaluating'},
                                       {label: 'Pending', value: 'pending', url: '/gemseki/cda_requests?status=pending'},
                                       {label: 'Declined by Licensee', value: 'declinedByLicensee', url: '/gemseki/cda_requests?status=declinedByLicensee'},
                                     ]}/>
        <div className={['searchSortBox', `searchSortOpen--${isOpen}`].join(' ')}>
          <h2 onClick={() => onOpenClose()}>Search / Sort</h2>
          <div className="searchSortBody">
            <Search selectType="request" resolveParams={resolveParams}>
              <InputSelect name="developmentStage" label="Development Stage" ref={ref(pForms)} value={query.get('developmentStage')} placeholder="..." valueGroup={DataDevelopmentStage}/>
              <InputSelect name="diseaseArea" label="Disease Area" ref={ref(pForms)} value={query.get('diseaseArea')} placeholder="..." valueGroup={DataDiseaseArea}/>
              <InputSelect name="licenseeOrgId" label="Licensee" ref={ref(pForms)} value={query.get('licenseeOrgId')} placeholder="..." valueGroup={licenseeNames}/>
              <InputSelect name="originatorOrgId" label="Originator" ref={ref(pForms)} value={query.get('originatorOrgId')} placeholder="..." valueGroup={originatorNames}/>
            </Search>
            <SortBy selected={0}/>
          </div>
        </div>
        <div className="cardBox">
          {cdaRequestsByGemseki.map(o => {
            return (
              <Card size="request" key={`cardRow:${o._id}`}>
                <ListRequest
                  user="Gemseki"
                  mode="CDA"
                  requestData={o}
                  unread={isNew(o, user)}
                  onChangeStatus={(newStatus) => showStatusChange(o?.licenseeOrgId, o?.assetId, o?._id, newStatus)}
                />
              </Card>
            );
          })}
          {!cdaRequestsByGemseki?.length && (
            <Card>
              <div className="cardCenter">No requests.</div>
            </Card>
          )}
        </div>
        <PageNavi selected={page} total={total}/>
        </div>
      </main>
      <RequestPopups {...popupControl}/>
    </div>
  );
};
