import { api, copyQuery } from './api.js';
import { clearError, isError } from './core';

export const REQUEST_ASSET_ROOM = 'REQUEST_ASSET_ROOM';
export const RECEIVE_ASSET_ROOM = 'RECEIVE_ASSET_ROOM';

export const findRoomsByGemseki = (query) => async (dispatch) => {
  const q = copyQuery(query, 'page', 'assetId', 'licenseeOrgId', 'originatorOrgId','order','dir');

  q.set('limit', '6');

  const res = await api.get(dispatch, `asset_room/find_by_gemseki?${q}`);
  if (isError(res, dispatch)) return;

  const total = Math.ceil(res.body.total / 6);
  const rooms = res.body.data;

  dispatch({
    type: RECEIVE_ASSET_ROOM,
    total,
    rooms,
  });
};

export const findRoomsByLicensee = (query) => async (dispatch) => {
  const q = copyQuery(query, 'page', 'assetId','order','dir');

  q.set('limit', '6');

  const res = await api.get(dispatch, `asset_room/find_by_licensee?${q}`);
  if (isError(res, dispatch)) return;

  const totalCount = res.body.total;
  const total = Math.ceil(totalCount / 6);
  const rooms = res.body.data;

  dispatch({
    type: RECEIVE_ASSET_ROOM,
    totalCount,
    total,
    rooms,
  });
};

export const getRoom = (roomId) => async (dispatch) => {
  dispatch({
    type: REQUEST_ASSET_ROOM,
    messages: [],
    oldNewestId: null,
    oldLatestId: null,
    newestId: null,
    latestId: null,
    endMessages: false,
  });

  const query = new URLSearchParams();
  query.set('limit', '10');
  const res = await api.get(dispatch, `asset_room/${roomId}?${query}`);
  if (isError(res, dispatch)) return;

  const room = res.body;
  const messages = room?.messages?.data || [];
  const latestId = messages.length ? messages[0]._id : null;
  const newestId = messages.length ? messages[messages.length - 1]._id : null;

  dispatch({
    type: RECEIVE_ASSET_ROOM,
    room,
    messages,
    newestId,
    latestId,
  });
};

export const loadOldMessages = (roomId) => async (dispatch, getState) => {
  const state = getState().asset_room;
  if (state.endMessages || !state.latestId) return;

  const query = new URLSearchParams();
  query.set('limit', '5');
  query.set('oldestId', state.latestId);
  const res = await api.get(dispatch, `asset_room/${roomId}/find?${query}`);
  if (isError(res, dispatch)) return;

  const total = res.body.total;
  const messages = res.body.data || [];
  const latestId = messages.length ? messages[0]._id : state.latestId;
  const oldLatestId = latestId === state.latestId ? state.oldLatestId : state.latestId;

  dispatch({
    type: RECEIVE_ASSET_ROOM,
    total,
    messages: messages.concat(state.messages),
    latestId,
    oldLatestId,
    endMessages: messages.length === 0,
  });
};

export const loadNewMessages = (roomId) => async (dispatch, getState) => {
  const state = getState().asset_room;

  const query = new URLSearchParams();
  query.set('limit', '5');
  query.set('order', '1');
  query.set('oldestId', state.newestId);
  const res = await api.get(dispatch, `asset_room/${roomId}/find?${query}`);
  if (isError(res, dispatch)) return;

  const total = res.body.total;
  const messages = res.body.data || [];
  const newestId = messages.length ? messages[messages.length - 1]._id : state.newestId;
  const oldNewestId = newestId === state.newestId ? state.oldNewestId : state.newestId;

  dispatch({
    type: RECEIVE_ASSET_ROOM,
    total,
    messages: state.messages.concat(messages),
    newestId,
    oldNewestId,
  });
};

export const sendMessage = (roomId, body) => async (dispatch) => {
  if (!body || !body.trim()) return;
  dispatch(clearError());

  const res = await api.post(dispatch, `asset_room/${roomId}/message`, {body});
  isError(res, dispatch);
};
