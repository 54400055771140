import React from 'react';
import PropTypes from 'prop-types';

import './card.css';
import { Card } from "./Card";

export const CardNew = ({ children, mode, user, ...props }) => {
  return (
    <Card {...props}>
      <div className={['cardNew', `cardNew--${mode}`].join(' ')}>
        <ul className="cardNewBody">
          {children}
        </ul>
      </div>
    </Card>
  );
};

CardNew.propTypes = {
  /**
   * Card の種類
   */
  mode: PropTypes.oneOf(['Message', 'CDA', 'NCD']),
  /**
   * Input のタイプ
   */
  size: PropTypes.oneOf(['full', 'half', 'dashboard']),
  /**
   * User種別
  */
  user: PropTypes.oneOf(['Licensee', 'Gemseki']),
};

CardNew.defaultProps = {
  mode: 'CDA',
  size: 'dashboard',
  user: 'Licensee',
};
