import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from '../../parts/Button';
import { MemberList } from '../../parts/MemberList';
import { Card } from '../../parts/Card';

import '../base.css';
import { fd } from '../../parts/refs';
import { loadProfiles } from '../../actions/core';
import { useTitle } from '../../parts/useTitle';

export const ProfileSettings = () => {
  useTitle('Account settings');

  const dispatch = useDispatch();

  const [user, organization, members] = useSelector(s => [s.core.user, s.core.organization, s.core.members]);

  useEffect(() => {
    dispatch(loadProfiles());
  }, [dispatch]);

  const history = useHistory();
  return (
    <div id="container" className="layout-login contsProfileSettings">

      <main>
        <h1>Account settings</h1>
        <div id="containerMain">
          <div className="accountBody">
            <h2>User Account</h2>
            <Card>
              <table className="tableGray tableGrayThVertical">
                <tbody>
                <tr>
                  <th><label className="tableGrayLabel">Registration Date</label></th>
                  <td><p>{fd(user?.createdAt)}</p></td>
                  <th><label className="tableGrayLabel">Update Date</label></th>
                  <td><p>{fd(user?.updatedAt)}</p></td>
                </tr>
                <tr>
                  <th><label className="tableGrayLabel">User Name</label></th>
                  <td><p>{user?.firstName} {user?.lastName}</p></td>
                  <th><label className="tableGrayLabel">Job Title</label></th>
                  <td><p>{user?.jobTitle}</p></td>
                </tr>
                <tr>
                  <th><label className="tableGrayLabel">Email address</label></th>
                  <td><p><a href={`mailto:${user?.email}`} target="mail">{user?.email}</a></p></td>
                  <th><label className="tableGrayLabel">Email reception settings</label></th>
                  <td>
                    {user?.isReceiveSystemEmail ? <p>Receive System Email</p> : ''}
                    {user?.isReceiveMarketingEmail ? <p>Receive Marketing Emails</p> : ''}
                  </td>
                </tr>
                </tbody>
              </table>
              <div className="buttonBoxL">
                <Button label="Edit" size="111px" onClick={() => history.push('/licensee/profile_edit')}/>
                <Button label="Change password" size="221px" onClick={() => history.push('/licensee/change_password')}/>
              </div>
            </Card>
          </div>
          <div className="accountBody">
            <h2>Corporate Account</h2>
            <Card>
              <table className="tableGray tableGrayThVertical">
                <tbody>
                <tr>
                  <th><label className="tableGrayLabel">Date (Company Registered)</label></th>
                  <td><p>{fd(organization?.createdAt)}</p></td>
                  <th><label className="tableGrayLabel">Date (Company Updated)</label></th>
                  <td><p>{fd(organization?.updatedAt)}</p></td>
                </tr>
                <tr>
                  <th><label className="tableGrayLabel">Company ID</label></th>
                  <td><p>{organization?.displayId}</p></td>
                  <th><label className="tableGrayLabel">Company</label></th>
                  <td><p>{organization?.organizationName}</p></td>
                </tr>
                <tr>
                  <th><label className="tableGrayLabel">Area</label></th>
                  <td><p>{organization?.area}</p></td>
                  <th><label className="tableGrayLabel">Country</label></th>
                  <td><p>{organization?.country}</p></td>
                </tr>
                <tr>
                  <th><label className="tableGrayLabel">Type of Company</label></th>
                  <td colSpan="3"><p>{organization?.typeOfCompany?.join('　　')}</p></td>
                </tr>
                <tr>
                  <th><label className="tableGrayLabel">Licensing Criteria (Disease Area)</label></th>
                  <td colSpan="3"><p>{organization?.diseaseArea?.join('　　')}</p></td>
                </tr>
                <tr>
                  <th><label className="tableGrayLabel">Licensing Criteria (Route)</label></th>
                  <td colSpan="3"><p>{organization?.route?.join('　　')}</p></td>
                </tr>
                <tr>
                  <th><label className="tableGrayLabel">Licensing Criteria (Modality)</label></th>
                  <td colSpan="3"><p>{organization?.modality?.join('　　')}</p></td>
                </tr>
                <tr>
                  <th><label className="tableGrayLabel">Licensing Criteria (Development Stage)</label></th>
                  <td colSpan="3"><p>{organization?.developmentStage?.join('　　')}</p></td>
                </tr>
                <tr>
                  <th><label className="tableGrayLabel">Licensing Criteria (Territory)</label></th>
                  <td colSpan="3"><p>{organization?.territoryForInLicensing?.join('　　')}</p></td>
                </tr>
                <tr>
                  <th><label className="tableGrayLabel">Specific Requirements</label></th>
                  <td colSpan="3"><p>{organization?.licensingCriteria}</p></td>
                </tr>
                <tr>
                  <th><label className="tableGrayLabel">Website</label></th>
                  <td colSpan="3"><p><a href={organization?.url} target="organization_url">{organization?.url}</a></p></td>
                </tr>
                </tbody>
              </table>
              <div className="buttonBoxL">
                <Button label="Edit" size="111px" onClick={() => history.push('/licensee/organization_edit')}/>
              </div>
            </Card>
          </div>
          <div className="accountBody">
            <h2>Member list</h2>
            <Card>
              <MemberList editMode={false} organization={organization} valueGroup={members}/>
              <div className="buttonBoxL">
                <Button label="Edit &amp; invite members" size="257px" onClick={() => history.push('/licensee/member_edit')}/>
              </div>
            </Card>
          </div>
        </div>
      </main>
    </div>
  );
};
