import React, { useState } from 'react';
import { Input1Line } from './Input1Line';
import { ref } from './refs';
import { CheckBoxGroup } from './CheckBoxGroup';
import { Button } from './Button';
import { DebugMenu } from './DebugMenu';

export const InvitationCreatePanel = ({invitationData, onSignUp}) => {
  const [agree, setAgree] = useState(false);
  const isLicensee = invitationData.organizationType !== 'gemseki';

  /**
   * @type {{}}
   * @property agreeService
   */
  const forms = {};

  const resolveData = () => {
    return {
      firstName: forms.firstName.value,
      lastName: forms.lastName.value,
      password: forms.password.value,
      jobTitle: forms?.jobTitle?.value || '',
      confirmPassword: forms.confirmPassword.value,
      organizationId: invitationData.organizationId,
      email: invitationData.email,
      requestKey: invitationData.requestKey,
    };
  };

  return (
    <div id="container" className="layout-white contsCreateAccount">
      <main>
        <h1>Create Account</h1>

        <input style={{width: 0, height: 0, border: 'none', padding: 0, margin: 0, position: 'absolute'}} defaultValue={invitationData.email} autoComplete="username"/>
        <div className="inputBoxes">

          <label className="inputBoxLabel">Company<span className="required_ico">*</span></label>
          <p className='invitationCompany'>{invitationData.organizationName}</p>

          <label className="inputBoxLabel" htmlFor="input1Line-ID--firstName">Name<span className="required_ico">*</span></label>
          <div className="inputBoxLR">
            <Input1Line name="firstName" ref={ref(forms)} size="335px" placeholder="First Name"/>
            <Input1Line name="lastName" ref={ref(forms)} size="335px" placeholder="Last Name"/>
          </div>

          {isLicensee && <label className="inputBoxLabel" htmlFor="input1Line-ID--jobTitle">Job Title</label>}
          {isLicensee && <Input1Line name="jobTitle" ref={ref(forms)} placeholder="Type here…"/>}

          <Input1Line name="password" ref={ref(forms)} type="password" placeholder="Please enter at least 8 characters." autoComplete="new-password" required={true} label="Password"/>

          <Input1Line name="confirmPassword" ref={ref(forms)} type="password" placeholder="Type here…" required={true} label="Confirm password"/>

        </div>

        <div className="TermBox">
          <label className="TermBoxLabel">Terms of service</label>
          <div className="TermBoxTerm">
            <p>
            <h4 className="tistyle1 pb10 inv2 animated" style={{paddingBottom:"10px !important"}}>“Drug Candidate Marketplace” Terms of Use</h4>
            <h5 className="fs16 pb10 inv1 animated" style={{fontWeight:"normal"}}>This “Drug Candidate Marketplace” Terms of Use (this “<span className="fB">Terms of Use</span>”) sets forth the terms and conditions concerning the use of the market platform for drug technology discovery and seeds “Drug Candidate Marketplace” (the “<span className="fB">Platform</span>”) provided by Gemseki Inc. (the “<span className="fB">Company</span>”) with the user using the Platform (the “<span className="fB">User</span>”).</h5>

<div className="inv1 animated">
<dl className="terms_text">
 	<dt>1.Consent to Terms of Use</dt>
 	<dd className="indent">(1) Consent to the entire Terms of Use is required to use the Platform. When the User uses the Platform, the User will be deemed to have consented to this Terms of Use.</dd>
 	<dd className="indent">(2) If the User uses the Platform for a service provider, the Platform must be used upon the consent to this Terms of Use by the duly authorized representative of such service provider.</dd>
 	<dt>2.Account Registration</dt>
 	<dd className="indent">(1) When using the Platform, the User shall register the information requested by the Company and create an account (the “<span className="fB">User Account</span>”). In such instance, the User is required to register true, accurate, up-to-date and complete information.</dd>
 	<dd className="indent">(2) The User shall manage the ID and password of the User Account at User’s responsibility. The Company may deem any acts conducted by using the ID and password of the User Account as the acts of the User themself.</dd>
 	<dd className="indent">(3) The User Account exclusively belongs to the User, and may not be transferred, loaned, or succeeded to a third party.</dd>
 	<dt>3.Handling of Personal Information</dt>
 	<dd>The Company will properly handle the User’s personal information pursuant to the Company’s privacy policy. The User will be deemed to have consented to such privacy policy if the User uses the Platform.</dd>
 	<dt>4.Use of the Platform</dt>
 	<dd className="indent">(1) The User may access, through the Platform, information in connection with the drug technology discovery and seeds provided by the provider of such drug technology discovery and seeds (the “<span className="fB">Originator</span>”) that is information disclosed on the Platform by the Company (the “<span className="fB">Contents</span>”), pursuant to the terms and conditions set forth in this Terms of Use.</dd>
 	<dd className="indent">(2) The Company will not be liable for any dispute between the User and the Originator that arises in connection with the Platform or the Contents.</dd>
 	<dt>5.No Warranty and Disclaimer</dt>
 	<dd className="indent">(1) The Platform and the Contents will be provided as-is. The Company makes no warranty, whether expressly or impliedly, in connection with the Platform and the Contents, that there are no defects, errors, bugs or other failures regarding security, etc., and as to their safety, lawfulness, accuracy, completeness, marketability, utility, fitness for particular purpose, non-infringement of rights or otherwise.</dd>
 	<dd className="indent">(2) The Platform and the Contents are for the purpose of solely matching the Originator and the User (the “<span className="fB">Purpose</span>”). The Platform and the Contents shall not be for the purpose for the diagnosis, treatment, consultation, or provision of other medical practice, and the Contents and any and all other information acquired through the Platform are not substitutable for the professional judgment of a doctor. In any case, the Company shall not be liable for damages incurred by the User due to having used the Platform and the Contents for purposes other than the Purpose.</dd>
 	<dt>6.Prohibited Acts</dt>
 	<dd>The User may not engage in any of the following acts when using the Platform:
<ol>
 	<li>(i) A criminal act, an act that leads to a criminal act, or an act that has the likelihood thereof;</li>
 	<li>(ii) The reproduction, public transmission, translation, adaptation, alteration, or an act that infringes rights of the Company, the Originator, or a third party, including copyrights, trademark rights, or other intellectual property rights, or an act that has the likelihood thereof;</li>
 	<li>(iii) An act of providing services similar to the Platform by using the Contents, or an act of using the Platform or the Contents for a purpose other than the Purpose;</li>
 	<li>(iv) The reverse assembly, reverse compiling, or reverse engineering of the Platform and/or the Contents, or an act of analyzing the source code, construction, idea, etc. of the Platform and/or the Contents;</li>
 	<li>(v) Discriminating against or slandering the Company, the Originator, or a third party, or an act that damages the credibility thereof;</li>
 	<li>(vi) Unauthorized access to the system, distribution of malware, or other act that interferes with the operation of the Platform;</li>
 	<li>(vii) An act that violates the laws and regulations, public order and morals, or this Terms of Use, or an act that has the likelihood thereof;</li>
 	<li>(viii) An act of soliciting, or conducting publicity and advertising activities (excluding communications for the Purpose) to the Originator or another User;</li>
 	<li>(ix) An act of granting benefits to or other cooperation with anti-social forces;</li>
 	<li>(x) An act of aiding or abetting any of the above acts; and</li>
 	<li>(xi) An act that the Company otherwise determines to be improper</li>
</ol>
</dd>
 	<dt>7.Suspension of Use of the Platform</dt>
 	<dd>If the Company determines that any of the below applies, the Company may take measures that the Company determines to be proper and reasonable, including the suspension of use of the Platform and cancellation of the account, without any formal notice to the User. In such instance, the Company shall not be liable for damages incurred by the User due to the suspension of use of the Platform or other measures:
<ol>
 	<li>(i) If User is in breach of this Terms of Use;</li>
 	<li>(ii) If the information provided by the User to the Company is discovered to be false or inaccurate;</li>
 	<li>(iii) If there is a petition for commencement of bankruptcy proceedings, commencement of civil rehabilitation proceedings, or commencement of insolvency proceedings similar thereto with respect to the User;</li>
 	<li>(iv) If the User is discovered to be anti-social forces, or is discovered to have some sort of relationship or involvement with anti-social forces; or</li>
 	<li>(v) The use of the Platform by the User is otherwise improper.</li>
</ol>
</dd>
 	<dt>8.Suspension of the Provision of the Platform</dt>
 	<dd className="indent">(1) If the Company determines that any of the below applies, the Company may suspend the provision of the Platform. In such instance, the Company shall not be liable for damages incurred by the User due to the suspension of provision of the Platform:
<ol>
 	<li>(i) If maintenance, inspection, construction or other work of the equipment required for the provision of the Platform will be conducted;</li>
 	<li>(ii) If there is an obstruction, failure, etc. of the equipment required for the provision of the Platform;</li>
 	<li>(iii) If there is a request from the state, local government, or other public agency to the Company to suspend the provision of the Platform;</li>
 	<li>(iv) If the provision of the Platform is difficult due to war, riot, labor dispute, natural disaster, fire, power outage, infectious disease, or other force majeure; or</li>
 	<li>(v) If there is a need to suspend the provision of the Platform due to an operational or technical reason.</li>
</ol>
</dd>
 	<dd className="indent">(2) The Company may change the contents of the Platform (including deletion or amendment of the Contents in whole or in part) at any time without notifying the User in advance.</dd>
 	<dd className="indent">(3) The Company may terminate the provision of the Platform at any time upon informing the User in advance; provided, however, that the provision of the Platform may be terminated without informing the User in advance in cases of emergency.</dd>
 	<dt>9.Limitation of Liability</dt>
 	<dd className="indent">(1) The Company will not be liable for any and all damages incurred by the User as a result of the use of the Platform, regardless of the cause thereof, unless such damages occur due to the Company’s intent or gross negligence.</dd>
 	<dd className="indent">(2) Even if the Company is liable for compensating damages incurred by the User due to the use of the Platform, the Company will only be liable for ordinary and direct damages, and in any case, the Company will not be liable for lost profits, indirect damages, incidental damages, consequential damages, or damages occurring due to loss of data, loss or suspended business opportunities, or other special circumstances, whether foreseeable or not.</dd>
 	<dd className="indent">(3) Even if the Company is liable for damages incurred by the User under applicable laws and regulations, the total amount thereof shall not exceed JPY1,000,000, unless such damages occurred due to the Company’s intent or gross negligence.</dd>
 	<dt>10.Intellectual Property Rights</dt>
 	<dd>All of the patent rights, utility model rights, design rights, trademark rights, copyrights, and other intellectual property rights concerning the Platform and the Contents (including the rights set forth in Articles 27 and 28 of the Copyright Act and referred to as the “<span className="fB">Intellectual Property Rights</span>”) belong to the Company or the Originator. The User may not use the Intellectual Property Rights without the prior written consent of the Company and the Originator.</dd>
 	<dt>11.Compensation</dt>
 	<dd>The User shall defend, indemnify, and compensate the Company with respect to any demands, claims, damages, losses, expenses, sanctions, etc. that occur or may occur in connection with a breach of this Terms of Use by the User.</dd>
 	<dt>12.Notification Method</dt>
 	<dd className="indent">(1) Notifications from the Company to the User may be by a method the Company believes to be proper, including by transmission to the email address or other communication means or address registered by the User or by indication on the Company’s official website, and even if a notification is not delivered due to a change or error in the User’s contact information, such notification shall be deemed to have arrived on the day after transmission for emails and other communication means, and on the day of indication for indications on the website.</dd>
 	<dd className="indent">(2) Communications from the User to the Company regarding the Platform are required to be made from the inquiry form established on the control screen of the Platform or other methods designated by the Company.</dd>
 	<dt>13.Language</dt>
 	<dd>This Terms of Use will be prepared in Japanese and translated into English and other languages. If there is an inconsistency between the Japanese version and the translated version of this Terms of Use, the provisions of the Japanese version shall prevail.</dd>
 	<dt>14.Amendment of Terms of Use</dt>
 	<dd>The Company may amend this Terms of Use at any time if the Company determines it to be necessary. In such instance, the Company will make known that this Terms of Use will be amended, the contents of the amended Terms of Use, and the effective date thereof by a method the Company determines to be proper. The amendment of this Terms of Use shall become effective from such effective date.</dd>
 	<dt>15.Severability</dt>
 	<dd>Even if a part of this Terms of Use is declared to be invalid by the laws and regulations or a court decision, etc., the other parts shall continue to remain in effect.</dd>
 	<dt>16.Survival</dt>
 	<dd>Article 2(3), Article 4(2), Article 5 to Article 7, Article 8(1), Article 9 to Article 11, Article 13, and Article 15 to Article 17 shall survive the termination of the provision of the Platform to the User.</dd>
 	<dt>17.Governing Law and Jurisdiction</dt>
 	<dd>This Terms of Use will be governed by the laws of Japan, without regard for the provisions of the applicable laws and regulations on the conflict of laws. The Tokyo District Court will be the court of exclusive agreed jurisdiction of the first instance with respect to disputes that arise between the User and the Company as a result of or in connection with the Platform or the Contents.</dd>
</dl>
Enacted: March 24, 2022

</div>
            </p>
          </div>
          <CheckBoxGroup name="agreeService" ref={ref(forms)} valueGroup={[
            {label: 'I agree to the terms of service', value: 'agree'},
          ]} onChange={() => {
            setAgree(forms.agreeService.values.includes('agree'));
          }}/>
          <div className="createAccountSubmit">
            <Button disabled={!invitationData.organizationType || !agree} label="Sign up" onClick={() => onSignUp(resolveData())}/>
          </div>
        </div>

        <DebugMenu links={[
          {name: 'Home', to: '/'},
          {name: 'Forgot Password', to: '/forgot_password'},
          {name: 'Signup', to: '/signup'},
          {name: 'Licensee', to: '/licensee'},
          {name: 'Gemseki', to: '/gemseki'},
        ]}/>
      </main>
    </div>
  );
};
