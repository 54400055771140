import React, { useEffect, useState } from 'react';

import { Card } from '../../parts/Card';
import { ListMessage } from '../../parts/ListMessage';
import { PageNavi } from '../../parts/PageNavi';
import {Search} from '../../parts/Search';
import {InputSelect} from '../../parts/InputSelect';

import '../base.css';

import { getAssetNumbers } from '../../actions/asset';
import { useDispatch, useSelector } from 'react-redux';
import { findRoomsByLicensee } from '../../actions/asset_room';
import { ShortMessageBody } from '../../parts/MessageBody';
import { useQuery, ref } from '../../parts/refs';
import { useTitle } from '../../parts/useTitle';
import { SortBy } from '../../parts/SortBy';
import TutorialImg from '../../parts/assets/tutorial_message.png';

export const MessageList = () => {
  useTitle('Message list');

  const dispatch = useDispatch();
  const query = useQuery();
  const page = parseInt(query.get('page') || '0');

  useEffect(() => {
    dispatch(getAssetNumbers());
  }, [dispatch]);

  useEffect(() => {
    dispatch(findRoomsByLicensee(query));
  }, [dispatch, query]);

  const [rooms, totalCount, total, messageCount, user, assetNumbers] = useSelector(s => [s.asset_room.rooms || [], s.asset_room.totalCount || 0, s.asset_room.total || 1,s.dashboard.messageCount || 0, s.core.user, s.asset.assetNumbers || []]);
  const pForms = {};
  const resolveParams = () => {
    const params = {};
    params.assetId = pForms.assetId.value;
    return params;
  };
  
  var [tutorialBoolOpen, setTutorialBoolOpen] = useState(true);
  const tutorialOpenClose = (b) => {
    setTutorialBoolOpen(tutorialBoolOpen = b);
  };

  return (
    <div id="container" className={`layout-login contsMessageList totalCount_${totalCount}`}>
      <main>
        <h1>Message list{messageCount !== 0 && <span className='badge'>{messageCount}</span>}</h1>
        <div id='containerMain'>
          {(!totalCount && tutorialBoolOpen) && 
            <div className='tutorial'>
              <img src={TutorialImg} alt='tutorial'></img>
              <div className='tutorialMain'>
                <p>You can see all your message<br/>history here.</p>
              </div>
              <button className='tutorialClose' onClick={() => tutorialOpenClose(false)}></button>
            </div>
          }
          <Search selectType="messageList" resolveParams={resolveParams}>
            <InputSelect name="assetId" label="Asset No." ref={ref(pForms)} value={query.get('assetNumber')} placeholder="..." valueGroup={assetNumbers}/>
          </Search>
          <SortBy selected={0}/>
          {rooms.map(room => (
              <Card key={`room:${room._id}`} size="full">
                <ListMessage room={room} user={user} unread={!room.isRead}>
                  <ShortMessageBody room={room} message={room.latestMessage}/>
                </ListMessage>
              </Card>
            ))}
          {!rooms?.length && (
            <Card>
              <div className="cardCenter">No messages.</div>
            </Card>
          )}
          <PageNavi selected={page} total={total}/>
        </div>
      </main>
    </div>
  );
};
