import React, { useEffect } from 'react';

import { ButtonBack } from '../../parts/ButtonBack';
import { Button } from '../../parts/Button';
import { Input1Line } from '../../parts/Input1Line';
import { Card } from '../../parts/Card';

import '../base.css';
import { useHistory, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { getMember, updateMember } from '../../actions/core';
import { fd, ref } from '../../parts/refs';
import { useTitle } from '../../parts/useTitle';

export const LicenseeProfileEdit = () => {
  useTitle('Licensee user edit');

  const {memberId} = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(getMember(memberId));
  }, [dispatch, memberId]);

  const member = useSelector(s => s.core.member);

  const forms = {};

  const resolveData = () => {
    return {
      _id: memberId,
      firstName: forms.firstName.value,
      lastName: forms.lastName.value,
      jobTitle: forms.jobTitle.value,
    };
  };

  return (
    <div id="container" className="layout-login contsLicenseeProfile">
      <main>
        <h1><ButtonBack/>Licensee user edit</h1>
        <div id='containerMain'>
        <Card>

        <table className="tableGray tableGrayThVertical">
          <tbody>
          <tr>
            <th><label className="tableGrayLabel">Registration Date</label></th>
            <td><p>{fd(member?.createdAt)}</p></td>
            <th><label className="tableGrayLabel">Update Date</label></th>
            <td><p>{fd(member?.updatedAt)}</p></td>
          </tr>
          <tr>
            <th style={{paddingTop:"26px"}}><label className="tableGrayLabel">User Name</label></th>
            <td>
              <div className="buttonBoxL"><Input1Line name="firstName" placeholder="…" ref={ref(forms)} size="133px" defaultValue={member?.firstName}/><Input1Line name="lastName" placeholder="…" ref={ref(forms)} size="133px" defaultValue={member?.lastName}/></div>
            </td>
            <th style={{paddingTop:"26px"}}><label className="tableGrayLabel">Job Title</label></th>
            <td><Input1Line name="jobTitle" ref={ref(forms)} size="100%" defaultValue={member?.jobTitle}/></td>
          </tr>
          <tr>
            <th><label className="tableGrayLabel">Email address</label></th>
            <td colSpan={3}><p><a href={`mailto:${member?.email}`} className="buttonSetting" target="mail">{member?.email}</a></p></td>
          </tr>
          </tbody>
        </table>
        <div className="buttonBoxCenter">
          <Button label="Cancel" size="150px" type="gray" onClick={() => history.goBack()}/>
          <Button label="Save" size="150px" onClick={() => dispatch(updateMember(resolveData(), history))}/>
        </div>
        </Card>
        </div>
      </main>
    </div>
  );
};
