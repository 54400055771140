import React, { useEffect } from 'react';

import '../base.css';
import { ButtonBack } from '../../parts/ButtonBack';
import { Input1Line } from '../../parts/Input1Line';
import { Button } from '../../parts/Button';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { fd, ref } from '../../parts/refs';
import { loadProfiles, updateUser } from '../../actions/core';
import { useTitle } from '../../parts/useTitle';
import { Card } from '../../parts/Card';

export const ProfileEdit = () => {
  useTitle('User Account edit');

  const [user] = useSelector(s => [s.core.user]);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(loadProfiles());
  }, [dispatch]);

  const forms = {};

  const resolveData = () => {
    return {
      firstName: forms.firstName.value,
      lastName: forms.lastName.value,
    };
  };

  return (
    <div id="container" className="layout-login contsProfileEdit">
      <main>
        <h1><ButtonBack/>User Account edit</h1>
        <div id="containerMain">
          <Card>
        <table className="tableGray tableGrayThVertical">
          <tbody>
          <tr>
            <th><label className="tableGrayLabel">Registration Date</label></th>
            <td><p>{fd(user?.createdAt)}</p></td>
            <th><label className="tableGrayLabel">Update Date</label></th>
            <td><p>{fd(user?.updatedAt)}</p></td>
          </tr>
          <tr>
            <th style={{paddingTop: '26px'}}><label className="tableGrayLabel" htmlFor="input1Line-ID--name">User Name</label></th>
            <td>
              <div className="buttonBoxL"><Input1Line name="firstName" placeholder="…" defaultValue={user?.firstName} ref={ref(forms)} size="133px"/><Input1Line name="lastName" placeholder="…" defaultValue={user?.lastName} ref={ref(forms)} size="133px"/></div>
            </td>
            <th style={{paddingTop: '26px'}}><label className="tableGrayLabel" htmlFor="input1Line-ID--mail">Email address</label></th>
            <td style={{paddingTop: '26px'}}><p><a href={`mailto:${user?.email}`} target="mail">{user?.email}</a></p></td>
          </tr>
          </tbody>
        </table>
        <div className="buttonBoxCenter">
          <Button label="Cancel" size="150px" type="gray" onClick={() => history.goBack()}/>
          <Button label="Save" size="150px" onClick={() => dispatch(updateUser(resolveData(), history))}/>
        </div>
        </Card>
        </div>
      </main>
    </div>
  );
};
