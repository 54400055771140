import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import '../base.css';
import { ButtonBack } from '../../parts/ButtonBack';
import { Input1Line } from '../../parts/Input1Line';
import { Button } from '../../parts/Button';
import { fd, ref } from '../../parts/refs';
import { loadProfiles, updateUser } from '../../actions/core';
import { useTitle } from '../../parts/useTitle';
import { Card } from '../../parts/Card';

export const ProfileEdit = () => {
  useTitle('User Account edit');

  const [user] = useSelector(s => [s.core.user]);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(loadProfiles());
  }, [dispatch]);

  const forms = {};

  const resolveData = () => {
    return {
      firstName: forms.firstName.value,
      lastName: forms.lastName.value,
      jobTitle: forms.jobTitle.value,
      isReceiveSystemEmail: !!forms.isReceiveSystemEmail.checked,
      isReceiveMarketingEmail: !!forms.isReceiveMarketingEmail.checked,
    };
  };

  return (
    <div id="container" className="layout-login contsProfileEdit">

      <main>
        <h1><ButtonBack/>User Account edit</h1>
        <div id="containerMain">
          <Card>
            <table className="tableGray tableGrayThVertical">
              <tbody>
              <tr>
                <th><label className="tableGrayLabel">Registration Date</label></th>
                <td><p>{fd(user?.createdAt)}</p></td>
                <th><label className="tableGrayLabel">Update Date</label></th>
                <td><p>{fd(user?.updatedAt)}</p></td>
              </tr>
              <tr>
                <th style={{paddingTop: '26px'}}><label className="tableGrayLabel">User Name</label></th>
                <td>
                  <div className="buttonBoxL"><Input1Line name="firstName" placeholder="…" defaultValue={user?.firstName} ref={ref(forms)} size="133px"/><Input1Line name="lastName" placeholder="…" defaultValue={user?.lastName} ref={ref(forms)} size="133px"/></div>
                </td>
                <th style={{paddingTop: '26px'}}><label className="tableGrayLabel" htmlFor="input1Line-ID--job">Job Title</label></th>
                <td><Input1Line name="jobTitle" size="100%" ref={ref(forms)} defaultValue={user?.jobTitle}/></td>
              </tr>
              <tr>
                <th><label className="tableGrayLabel">Email address</label></th>
                <td colSpan="3"><p><a href={`mailto:${user?.email}`} target="mail">{user?.email}</a></p></td>
              </tr>
              <tr>
                <th><label className="tableGrayLabel">Email reception settings</label></th>
                <td colSpan="3">
                  <div className="checkBoxGroup">
                    <div className="checkBoxGroupBox">
                      <label>
                        <input id="isReceiveSystemEmail" name="isReceiveSystemEmail" type="checkbox" ref={ref(forms)} defaultChecked={user?.isReceiveSystemEmail}/>
                        <span>Receive System Email</span>
                      </label>
                    </div>
                    <div className="checkBoxGroupBox">
                      <label>
                        <input id="isReceiveMarketingEmail" name="isReceiveMarketingEmail" type="checkbox" ref={ref(forms)} defaultChecked={user?.isReceiveMarketingEmail}/>
                        <span>Receive Marketing Emails</span>
                      </label>
                    </div>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
            <div className="buttonBoxCenter">
              <Button label="Cancel" size="150px" type="gray" onClick={() => history.goBack()}/>
              <Button label="Save" size="150px" onClick={() => dispatch(updateUser(resolveData(), history))}/>
            </div>
          </Card>
          <div className="accountBody">
            <h2 style={{paddingTop: '20px', marginBottom: '0'}}>Newsletter subscription</h2>
            <Card>
              <p style={{marginBottom: '40px'}}>Please click on the link below to subscribe to our newsletter, and you will be redirected to the subscription page.</p>
              <div className="buttonBoxCenter">
                <div className="button"><a href="https://www.gemseki.com/en/newsletter/" rel="noreferrer" target="_blank">Subscribe</a></div>
              </div>
            </Card>
          </div>
        </div>
      </main>
    </div>
  );
};
