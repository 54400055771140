import PropTypes from 'prop-types';
import './debugMenu.css';
import {useHistory} from 'react-router';

let beforeFocus = false;

export const DebugMenu = ({links}) => {
  const history = useHistory();
  return (
    <div className={'debugMenu'} tabIndex={-1}
         onPointerDown={(e) => {
           beforeFocus = document.activeElement === e.target;
         }}
         onClick={(e) => {
           /** @type {HTMLElement} */
           const node = e.target;
           if (beforeFocus) node.blur();
         }}
    >
      <div className={'debugMenuSub'}>
        {links.map((link, i) => <span key={i} className={'debugLink'} onClick={(e) => {
          /** @type {HTMLElement} */
          const node = e.target;
          node.parentElement.parentElement.blur();
          history.push(link.to);
          e.preventDefault();
        }}>{`${i + 1}. ${link.name}`}</span>)}
        <span className={'debugLink'} onClick={(e) => {
          /** @type {HTMLElement} */
          const node = e.target;
          node.parentElement.parentElement.blur();
          history.goBack();
          e.preventDefault();
        }}>≪ Back</span>
      </div>
    </div>
  );
};

DebugMenu.propTypes = {
  links: PropTypes.arrayOf(PropTypes.object),
};

DebugMenu.defaultProps = {
  links: [],
};
