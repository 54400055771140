import { useCallback, useEffect, useMemo, useRef } from 'react';

export const useScroll = (eventHandler = () => {}) => {
  const [body, container] = useMemo(() => [document.getElementsByTagName('body')[0], document.getElementsByTagName('html')[0] || document.getElementsByTagName('body')[0]], []);
  const scroll = useRef({body, container, position: 0});

  const callback = useCallback(async () => {
    scroll.current.position = scroll.current.container.scrollTop;
    await eventHandler(scroll.current);
  }, [scroll, eventHandler]);

  useEffect(() => {
    document.addEventListener('scroll', callback);
    return () => {
      document.removeEventListener('scroll', callback);
    };
  }, [callback]);

  return scroll;
};
