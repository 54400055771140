import { Button } from './Button';

export const InvitationConfirmPanel = ({invitationData, onConfirm, onCancel}) => {
  return (
    <div id="container" className="layout-white contsCreateAccount contsCreateAccountConfirm">
      <main>
        <h1>Notice of corporate change</h1>
        <p>You are invited by other members of the following companies.<br/>If you are willing to change the company on your account, please press the OK button.</p>
        <h2>{invitationData.organizationName}</h2>
        <div className="buttonBoxCenter">
          <Button label="Cancel" type="gray" size="133px" onClick={() => onCancel()}/>
          <Button label="OK" size="107px" onClick={() => onConfirm(invitationData)}/>
        </div>
      </main>
    </div>
  );
};
