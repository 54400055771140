import React from 'react';
import PropTypes from 'prop-types';

import './memberList.css';
import { MemberAdd } from './MemberAdd';
import { differentValueGroup } from './refs';

export class MemberAddUl extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      entries: props.values.map((v, k) => [v, k, React.createRef()]) || [],
    };
  }

  get name() {
    return this.props.name;
  }

  get values() {
    return this.state.entries.map(([, , r]) => r.current.value);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (differentValueGroup(this.props.values, prevProps.values, v => v?._id)) {
      this.setState({
        entries: this.props.values.map((v, k) => [v, k, React.createRef()]),
      });
    }
  }

  addRow() {
    const entries = this.state.entries;
    this.setState({
      ...this.state,
      entries: [...entries, [{}, Date.now(), React.createRef()]],
    });
  }

  _processRemove(key) {
    this.setState({
      ...this.state,
      entries: this.state.entries.filter(([, k]) => k !== key),
    });
  }

  render() {
    return (
      <ul className="MemberAddUl">
        {this.state.entries.map(([v, k, r], i) => <MemberAdd key={`MemberAdd:${k}`} parentName={`${this.props.name}${i}`} value={v} ref={r} onRemove={() => this._processRemove(k)}/>)}
      </ul>
    );
  };
}

MemberAddUl.propTypes = {
  /**
   * 編集モードかどうか
   */
  editMode: PropTypes.string,
  /**
   * 要素の名前　重複注意
   */
  name: PropTypes.string.isRequired,
  /**
   * メンバー配列
   */
  values: PropTypes.array,
};

MemberAddUl.defaultProps = {
  editMode: 'false',
  values: [
    {},
  ],
};
