import React from 'react';
import './checkBoxGroup.css';
import PropTypes from 'prop-types';
import { differentValueGroup } from './refs';

export class CheckBoxGroup extends React.Component {
  constructor(props) {
    super(props);
    this.checks = this.props.valueGroup.map(group => ({...group, ref: React.createRef()}));
  }

  get name() {
    return this.props.name;
  }

  get values() {
    return this.checks.filter(check => check.ref?.current?.checked).map(check => check.value);
  }

  set values(values) {
    const vs = values || [];
    this.checks.forEach(group => group.ref.current.checked = vs.includes(group.value));
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (differentValueGroup(this.props.valueGroup, prevProps.valueGroup)) {
      this.checks = this.props.valueGroup.map(group => ({...group, ref: React.createRef()}));
      this.forceUpdate();
    }
  }

  render() {
    const {name} = this.props;
    const values = this.props.values;
    return (
      <div className="checkBoxGroup">
        {this.checks.map((check, index) => (
          <div key={`checkBoxGroup:${name}:${index}`} className="checkBoxGroupBox">
            <label className={`input-${name}`}>
              <input
                type="checkbox"
                id={`checkBoxGroup-ID--${name}${index}`}
                value={check.value}
                defaultChecked={values ? values.includes(check.value) : null}
                ref={check.ref}
                onChange={() => this.props.onChange(this.values)}
              />
              <span>{check.label}</span>
            </label>
          </div>
        ))}
      </div>
    );
  }
}

CheckBoxGroup.propTypes = {
  /**
   * 要素の名前　重複注意
   */
  name: PropTypes.string.isRequired,
  /**
   * バリデーションエラー時に要素判別用として利用する名前（省略時は name）
   */
  validationName: PropTypes.string,
  /**
   * チェックリストの配列
   */
  valueGroup: PropTypes.array,
  /**
   * チェックリストの値配列
   */
  values: PropTypes.arrayOf(PropTypes.string),
  /**
   * 更新イベント
   */
  onChange: PropTypes.func,
};

CheckBoxGroup.defaultProps = {
  name: 'name',
  valueGroup: [
    {label: 'label1', value: 'value1', checked: false},
    {label: 'label2', value: 'value2', checked: false},
    {label: 'label3', value: 'value3', checked: false},
  ],
  onChange: () => {},
};
