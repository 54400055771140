import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import './card.css';
import { fdt } from './refs';
import { resolveListContributor } from './MessageBody';

export const ListMessage = ({room, user, header, unread, children}) => {
  return (
    <li className={`listMessage listMessageHeader--${header}`}>
      {header
        ? <div className="listMessageHeaderBody">
            <ul className="listRequestHeader">
                <li className="listRequestDate">{unread && <div className='unread'/>}{fdt(room?.updatedAt)}</li>
                <li><Link to={`/gemseki/asset/${room?.asset?._id}`}>{room?.asset?.assetNumber}</Link></li>
                <li><span>Licensee</span><Link className={`isValid--${room?.licenseeOrgStatus}`} to={`/gemseki/licensee/${room?.licenseeOrgId}`}>{room?.licenseeOrgName}</Link></li>
                <li><span>Originator</span><Link className={`isValid--${room?.originatorOrgStatus}`} to={`/gemseki/originator/${room?.originatorOrgId}`}>{room?.originatorOrgName}</Link></li>
            </ul>
            <Link to={`/${user?.organizationType}/room/${room?._id}`}>
              <div className="listMessageBody">
                <label>{resolveListContributor(room?.latestMessage)}</label>
                <p>{children}</p>
              </div>
            </Link>
          </div>
        : <Link to={`/${user?.organizationType}/room/${room?._id}`}>
            <div className="listMessageId">{unread && <div className='unread'/>}{room?.asset?.assetNumber}</div>
            <div className="listMessageBody">
              <label>{resolveListContributor(room?.latestMessage)}<span>{fdt(room?.latestMessage?.updatedAt)}</span></label>
              <p>{children}</p>
            </div>
          </Link>
      }
    </li>
  );
};

ListMessage.propTypes = {
  /**
   * ルーム情報
   */
  room: PropTypes.object,
  /**
   * ログインユーザー情報
   */
  user: PropTypes.object,
  /**
   * 未読かどうか
   */
  unread: PropTypes.bool,
  /**
   * 新ヘッダーかどうか
   */
   header: PropTypes.bool,
};

ListMessage.defaultProps = {
  room: {
    asset: {
      assetNumber: 'GEM001',
    },
    latestMessage: {
      updatedAt: '01/01/22 01:00:00',
      firstName: 'Sample',
      lastName: 'Name',
    },
  },
  user: {
    organizationType: 'licensee',
  },
  unread: false,
  header: false,
};
