import { Button } from './Button';
import { Input1Line } from './Input1Line';
import { useRef } from 'react';

export const InvitationLoginPanel = ({invitationData, onLogin, onCancel}) => {
  const passwordRef = useRef(null);
  return (
    <div id="container" className="layout-white contsCreateAccount">
      <main>
        <h1 class="fs44">Notice of corporate change</h1>
        <p>You are invited by other members of the following companies.<br />
        If you are willing to change the company on your account, please sign in below.</p>
        <h3>{invitationData.organizationName} </h3>

        <div className="inputBox">
          <Input1Line label="Email address" name="email" readOnly={true} defaultValue={invitationData.email} autoComplete="username" placeholder="Type here…"/>
        </div>
        <div className="inputBox2">
          <Input1Line label="Password" name="password" ref={passwordRef} type="password" autoComplete="current-password" placeholder="Type here…"/>
        </div>

        <div className="buttonBoxCenter">
          <Button label="Cancel" size="150px" type="gray" onClick={() => onCancel()}/>
          <Button label="Sign in"  size="150px" type="white" onClick={() => onLogin({password: passwordRef.current.value, ...invitationData})}/>
        </div>
      </main>
    </div>
  );
};
