import React from 'react';
import { Link } from 'react-router-dom';

import PropTypes from 'prop-types';
import './chatTitle.css';
import { ButtonBack } from './ButtonBack';

export const RequestTitle = ({request, title, children}) => {
  return (
    <div className="chatTitle">
      <ButtonBack/>
      <h3>{title}</h3>
      <h3>{request?.assetNumber}</h3>
      <table className="listRequestHeader">
        <tbody>
        <tr>
          <th>Originator</th>
          <td><Link to={`/gemseki/originator/${request?.originatorOrgId}`}>{request?.originatorOrgName}</Link></td>
        </tr>
        </tbody>
      </table>
      {children}
      <Link to={`/gemseki/asset/${request?.assetId}`}>Asset Detail &gt;</Link>
    </div>
  );
};

RequestTitle.propTypes = {
  /**
   * NCDA/CDAリクエスト情報
   */
  request: PropTypes.object,
  /**
   * タイトル文字列
   */
  title: PropTypes.string,
};

RequestTitle.defaultProps = {
};
