import React, { useEffect, useState } from 'react';

import '../base.css';
import { ButtonBack } from '../../parts/ButtonBack';
import { Input1Line } from '../../parts/Input1Line';
import { Button } from '../../parts/Button';
import { MemberList } from '../../parts/MemberList';
import { Card } from '../../parts/Card';

import { useHistory, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { deleteMembers, getLicensee, getMembers, inviteMember } from '../../actions/core';
import { ref } from '../../parts/refs';
import { Popup } from '../../parts/Popup';
import { useTitle } from '../../parts/useTitle';

export const LicenseeMembers = () => {
  useTitle('Edit members');

  const {licenseeId} = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const [targets, setTargets] = useState([]);

  useEffect(() => {
    dispatch(getLicensee(licenseeId));
    dispatch(getMembers(licenseeId));
  }, [dispatch, licenseeId]);

  const [licensee, members] = useSelector(s => [s.core.licensee, s.core.members || []]);

  /**
   * @type {object}
   * @property {Popup} popup
   */
  const forms = {};

  const resolveData = () => {
    return {
      email: forms.email.value,
    };
  };

  return (
    <div id="container" className="layout-login contsMemberInvite">
      <main>
        <h1><ButtonBack/>Edit members</h1>
        <div id='containerMain'>

        <div className="accountBody">
          <h2>New member invitation</h2>
          <Card>
            <p>Enter the email address of the person you want to invite below, and click the Send button. An email will be sent to the email address you entered.<br />Please click on the URL in the email to complete the procedure.</p>
            <div className="memberInviteSend">
              <Input1Line type="email" name="email" ref={ref(forms)} size="400px" label="Email address" placeholder="Type here…" iconType="none"/>
              <Button label="Send" size="150px" onClick={async () => {
                if (await dispatch(inviteMember(licenseeId, resolveData()))) {
                  forms.email.value = '';
                }
              }}/>
            </div>
          </Card>
        </div>
        <div className="accountBody">
            <h2>Member list</h2>
              <Card>
        <MemberList
          name="members"
          ref={ref(forms)}
          editMode={true}
          organization={licensee}
          valueGroup={members}
          onChanged={() => {
            setTargets(forms.members.values.map(id => {
              const member = members.find(m => m._id === id);
              return {_id: id, name: `${member?.firstName} ${member?.lastName}`};
            }));
          }}
        />
        <div className="buttonBoxCenter">
          <Button label="Cancel" size="150px" type="gray" onClick={() => history.goBack()}/>
          <Button label="Delete" size="150px" disabled={!targets.length} onClick={() => forms.popup.show()}/>
        </div>
        </Card>
          </div>
        </div>
      </main>

        <Popup name="popup" ref={ref(forms)} title="Delete the following user accounts." submitButton="OK" onSubmit={async () => {
          await dispatch(deleteMembers(licenseeId, targets.map(t => t._id)));
          forms.popup.hide();
        }}>
          <p>
            {targets.map(t => <span style={{display: 'block'}} key={`deleteTarget:${t._id}`}>{t.name}</span>)}
          </p>
          <p>Please note that not only will you be removed from company, but also your entire user account.</p>
        </Popup>
    </div>
  );
};
