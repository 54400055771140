import React, { useEffect, useState } from 'react';

import '../base.css';
import { ButtonBack } from '../../parts/ButtonBack';
import { Input1Line } from '../../parts/Input1Line';
import { Button } from '../../parts/Button';
import { MemberList } from '../../parts/MemberList';
import { Card } from '../../parts/Card';

import { useHistory, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { createOriginatorMember, deleteMembers, getMembers, getOriginator } from '../../actions/core';
import { ref } from '../../parts/refs';
import { useTitle } from '../../parts/useTitle';

export const OriginatorMembers = () => {
  useTitle('Edit members');

  const {originatorId} = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const [targets, setTargets] = useState([]);

  useEffect(() => {
    dispatch(getOriginator(originatorId));
    dispatch(getMembers(originatorId));
  }, [dispatch, originatorId]);

  const [originator, members] = useSelector(s => [s.core.originator, s.core.members || []]);

  const forms = {};

  const resolveData = () => {
    return {
      email: forms.email.value,
      firstName: forms.firstName.value,
      lastName: forms.lastName.value,
      jobTitle: forms.jobTitle.value,
    };
  };

  return (
    <div id="container" className="layout-login contsMemberInvite">
      <main>
        <h1><ButtonBack/>Edit members</h1>
        <div id="containerMain">

          <div className="accountBody">
            <h2>Add member</h2>
            <Card>
              <div className="memberOriginator">
                <div className="memberOriginatorName">
                  <label>User Name</label>
                  <div className="buttonBoxL">
                    <Input1Line type="text" name="firstName" ref={ref(forms)} size="133px" placeholder="First Name"/>
                    <Input1Line type="text" name="lastName" ref={ref(forms)} size="133px" placeholder="Last Name"/>
                  </div>
                </div>
                <Input1Line type="text" name="jobTitle" label="Job Title" ref={ref(forms)} size="209px"/>
                <Input1Line type="text" name="email" label="Email address" ref={ref(forms)} size="100%" maxLength={80}/>
              </div>
              <div className="memberOriginatorBtn">
                <Button label="Add" size="150px" onClick={async () => {
                  if (await dispatch(createOriginatorMember(originatorId, resolveData()))) {
                    forms.email.value = '';
                    forms.firstName.value = '';
                    forms.lastName.value = '';
                    forms.jobTitle.value = '';
                  }
                }}/>
              </div>
            </Card>
          </div>
          <div className="accountBody">
            <h2>Member list</h2>
            <Card>
              <MemberList
                name="members"
                ref={ref(forms)}
                editMode={true}
                organization={originator}
                valueGroup={members}
                onChanged={() => {
                  setTargets(forms.members.values.map(id => {
                    const member = members.find(m => m._id === id);
                    return {_id: id, name: `${member?.firstName} ${member?.lastName}`};
                  }));
                }}
              />
              <div className="buttonBoxCenter">
                <Button label="Cancel" size="150px" type="gray" onClick={() => history.goBack()}/>
                <Button label="Delete" size="150px" disabled={!targets.length} onClick={async () => {
                  await dispatch(deleteMembers(originatorId, targets.map(t => t._id)));
                }}/>
              </div>

            </Card>
          </div>
        </div>
      </main>
    </div>
  );
};
