import { api, copyQuery } from './api.js';
import { clearError, isError } from './core';

export const REQUEST_BOOKMARK = 'REQUEST_BOOKMARK';
export const RECEIVE_BOOKMARK = 'RECEIVE_BOOKMARK';

export const toggleBookmark = (assetId, bookmarkId) => async (dispatch) => {
  dispatch(clearError());

  const res = bookmarkId
    ? await api.delete(dispatch, `bookmark/${bookmarkId}`)
    : await api.post(dispatch, `bookmark`, {assetId});
  isError(res, dispatch);
};

export const getBookmarks = (query) => async (dispatch) => {
  const q = copyQuery(query, 'page');
  q.set('limit', '10');

  const res = await api.get(dispatch, `bookmark/find_by_licensee?${q}`);
  if (isError(res, dispatch)) return;

  const totalCount = res.body.total;
  const total = Math.ceil(totalCount / 10);
  const bookmarks = res.body.data;

  dispatch({
    type: RECEIVE_BOOKMARK,
    totalCount,
    total,
    bookmarks,
  });
};
