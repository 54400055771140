import { Route, Switch } from 'react-router-dom';
import { MenuPlot } from '../parts/Menu';
import { Dashboard } from './licensee/Dashboard';
import { MessageList } from './licensee/MessageList';
import { AssetSearch } from './licensee/AssetSearch';
import { AssetBag } from './licensee/AssetBag';
import { NcdRequest } from './licensee/NcdRequest';
import { CdaRequest } from './licensee/CdaRequest';
import { ProfileSettings } from './licensee/ProfileSettings';
import { AssetBookmark } from './licensee/AssetBookmark';
import { ProfileEdit } from './licensee/ProfileEdit';
import { OrganizationEdit } from './licensee/OrganizationEdit';
import { MemberInvite } from './licensee/MemberInvite';
import { MemberEntry } from './licensee/MemberEntry';
import { MessageDetail } from './licensee/MessageDetail';
import { AssetDetail } from './licensee/AssetDetail';
import { ChangePassword } from './ChangePassword';
import { AsideLicensee } from '../parts/AsideLicensee';
import { ErrorMessages, useClearMessages } from '../parts/ErrorMessages';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { useEffect } from 'react';
import { loadLicenseeCounts } from '../actions/dashboard';
import { redirectToLogin } from '../actions/core';

export const RoutesLicensee = () => {
  const [loginError, user] = useSelector(s => [s.core.loginError, s.core.user]);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (loginError) {
      dispatch(redirectToLogin(history, location));
    } else if (user?.organizationType === 'gemseki') {
      history.replace('/gemseki');
    } else if (user?._id) {
      dispatch(loadLicenseeCounts());
    }
  }, [dispatch, history, location, loginError, user]);

  useClearMessages();

  return !loginError && (
    <div>
      <Switch>
        <Route path="/licensee/rooms">
          <MenuPlot menuId="102"/><MessageList/>
        </Route>
        <Route path="/licensee/room/:roomId">
          <MenuPlot menuId="102"/><MessageDetail/>
        </Route>
        <Route path="/licensee/assets">
          <MenuPlot menuId="103"/><AssetSearch/>
        </Route>
        <Route path="/licensee/assetbag">
          <MenuPlot menuId="111"/><AssetBag/>
        </Route>
        <Route path="/licensee/asset/:assetId">
          <MenuPlot menuId="103"/><AssetDetail/>
        </Route>
        <Route path="/licensee/bookmarks">
          <MenuPlot menuId="104"/><AssetBookmark/>
        </Route>
        <Route path="/licensee/ncd_requests">
          <MenuPlot menuId="105"/><NcdRequest/>
        </Route>
        <Route path="/licensee/cda_requests">
          <MenuPlot menuId="106"/><CdaRequest/>
        </Route>
        <Route path="/licensee/profile">
          <MenuPlot menuId="110"/><ProfileSettings/>
        </Route>
        <Route path="/licensee/profile_edit">
          <MenuPlot menuId="110"/><ProfileEdit/>
        </Route>
        <Route path="/licensee/organization_edit">
          <MenuPlot menuId="110"/><OrganizationEdit/>
        </Route>
        <Route path="/licensee/member_edit">
          <MenuPlot menuId="110"/><MemberInvite/>
        </Route>
        <Route path="/licensee/member_entry">
          <MenuPlot menuId="110"/><MemberEntry/>
        </Route>
        <Route path="/licensee/change_password">
          <MenuPlot menuId="214"/><ChangePassword/>
        </Route>
        <Route path="/licensee">
          <MenuPlot menuId="101"/><Dashboard/>
        </Route>
      </Switch>
      <AsideLicensee kind="Licensee"/>
      <ErrorMessages/>
    </div>
  );
};
