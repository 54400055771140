import React from 'react';
import {useListOrder} from "./useListOrder";
import PropTypes from 'prop-types';
import './sortBy.css';

export const SortBy = React.forwardRef(({selected}, ref) => {
  const [
    updatedAtOrder,
    assetNumberOrder
  ] = useListOrder(
    selected,
    'updatedAt',
    'assetNumber'
  );

  return (
    <div className="sortBy" ref={ref}>
      <h6>Sort By</h6>
      <ul>
        <li className={updatedAtOrder.className} onClick={() => updatedAtOrder.onSwitch()}>Date</li>
        <li className={assetNumberOrder.className} onClick={() => assetNumberOrder.onSwitch()}>Asset No.</li>
      </ul>
    </div>
  );
});

SortBy.propTypes = {
  /**
   * 選択されている項目
   */
  selected: PropTypes.number,
};

SortBy.defaultProps = {
  selected: 1,
};
