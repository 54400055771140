import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import './memberList.css';
import { differentValueGroup } from './refs';

export class MemberList extends React.Component {
  constructor(props) {
    super(props);
    this.checks = this.props.valueGroup?.map(group => ({...group, ref: React.createRef()})) || [];
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (differentValueGroup(this.props.valueGroup, prevProps.valueGroup, (t) => t?._id)) {
      this.checks = this.props.valueGroup?.map(group => ({...group, ref: React.createRef()})) || [];
      this.forceUpdate();
    }
  }

  get name() {
    return this.props.name;
  }

  get values() {
    return this.checks.filter(g => g.ref.current?.checked).map(g => g._id);
  }

  render() {
    const {editMode, organization, isLinkToMember, gemsekiId} = this.props;
    return (
      <div className={`memberList memberList--${editMode}`}>
          {this.checks.length ? (
            <ul>
              {this.checks.map((g) => (
                <li key={`memberListRow:${g._id}`}>
                  {editMode && <input
                    type="checkbox"
                    id={`member:${g._id}`}
                    ref={g.ref}
                    value={g._id}
                    disabled={g._id === gemsekiId}
                    onChange={this.props.onChanged}
                  />}
                  {isLinkToMember
                    ? <Link className="memberListName" to={`/gemseki/${organization?.organizationType}/${organization?._id}/member/${g._id}`}>{g.firstName} {g.lastName}</Link>
                    : <label className="memberListName" htmlFor={`member:${g._id}`}>{g.firstName} {g.lastName}</label>
                  }
                  <div className='memberListJobTitle'>{g.jobTitle}</div>
                  <a className="memberListMail" href={`mailto:${g.email}`} target="mail">{g.email}</a>
                </li>
              ))}
            </ul>
          ) : (
            <div className="cardCenterMember">No members.</div>
          )}
      </div>
    );
  }
}

MemberList.propTypes = {
  /**
   * フォーム名
   */
  name: PropTypes.string,
  /**
   * 編集モードかどうか
   */
  editMode: PropTypes.bool,
  /**
   * 組織情報
   */
  organization: PropTypes.object,
  /**
   * メンバー一覧へのリンク表示
   */
  isLinkToMember: PropTypes.bool,
  /**
   * メンバー配列
   */
  valueGroup: PropTypes.array,
  /**
   * Gemseki編集時のログインID
   */
  gemsekiId: PropTypes.string,
  /**
   * 変更コールバック
   */
  onChanged: PropTypes.func,
};

MemberList.defaultProps = {
  editMode: false,
  isLinkToMember: false,
  organization: {},
  valueGroup: [],
};
