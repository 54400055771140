import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import './base.css';
import './page.css';
import './createAccount.css';
import { useQuery } from '../parts/refs';
import { InvitationCreatePanel } from '../parts/InvitationCreatePanel';
import { InvitationConfirmPanel } from '../parts/InvitationConfirmPanel';
import { InvitationLoginPanel } from '../parts/InvitationLoginPanel';
import { applyInvitation, applyInvitationWithLogin, createAccount, fetchUser } from '../actions/core';
import { useTitle } from '../parts/useTitle';
import { externalApi } from '../actions/api';
import { useRecaptcha } from '../parts/useRecaptcha';

export const InvitationAccount = () => {
  useTitle('Invitation');
  const {executeRecaptcha} = useRecaptcha();

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(fetchUser());
  }, [dispatch]);

  const [user, loginError, loading] = useSelector(s => [s.core.user, s.core.loginError, s.core.loading]);

  const query = useQuery();
  const invitationData = {
    email: query.get('email'),
    requestKey: query.get('requestKey'),
    organizationId: query.get('organizationId'),
    organizationName: query.get('organizationName'),
    organizationType: query.get('organizationType'),
    status: query.get('status'),
  };

  const panel = useMemo(() => {
    if (loading) return 'loading';
    if (invitationData.status === 'update') {
      if (loginError || !user || user.email !== invitationData.email) {
        return 'useLogin';
      } else {
        return 'useConfirm';
      }
    } else {
      return 'useCreate';
    }
  }, [loading, loginError, user, invitationData.status, invitationData.email]);

  switch (panel) {
    case 'loading':
      return '';
    case 'useLogin':
      return (
        <InvitationLoginPanel
          invitationData={invitationData}
          onLogin={async data => {
            const recaptchaToken = await externalApi(dispatch, async () => await executeRecaptcha('InvitationAccount'));
            dispatch(applyInvitationWithLogin(data, recaptchaToken, history));
          }}
          onCancel={() => history.replace(`/login`)}
        />
      );
    case 'useConfirm':
      return (
        <InvitationConfirmPanel
          invitationData={invitationData}
          onConfirm={data => dispatch(applyInvitation(data, history))}
          onCancel={() => history.replace(`/${user.organizationType}`)}
        />
      );
    default :
      return (
        <InvitationCreatePanel
          invitationData={invitationData}
          onSignUp={async data => {
            const recaptchaToken = await externalApi(dispatch, async () => await executeRecaptcha('InvitationAccount'));
            dispatch(createAccount(data, recaptchaToken, history, invitationData.organizationType, true));
          }}
        />
      );
  }
};

InvitationAccount.propTypes = {};
