import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo } from 'react';
import { clearValidation } from '../actions/validator';
import { useLocation } from 'react-router';
import { clearError } from '../actions/core';

import './errorMessages.css';
import IconCloseError from './assets/icon-close-error.svg';

export const useClearMessages = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(clearValidation());
    dispatch(clearError());
  }, [dispatch, location]);
};

export const ErrorMessages = () => {
  const [hasValidation, validations, hasError, errors] = useSelector(s => [s.core.hasValidationError, s.core.validationErrors, s.core.hasError, s.core.errors]);
  const [validationKey, validationMessages, validationCss] = useMemo(() => {
    if (!hasValidation) return ['none', [], ''];
    const bgRed = [];
    const fgRed = [];
    const types = new Set();
    for (const v of validations || []) {
      bgRed.push(`.input1Line.input-${v.name}`);
      bgRed.push(`.inputSelect.input-${v.name}>div`);
      bgRed.push(`.ncdFiles.name-${v.name}>.ncdFilesFooter`);
      fgRed.push(`label.input-${v.name}`);
      types.add(v.type);
    }
    const messages = Array.from(types).map(type => {
      switch (type) {
        case 'required':
          return 'Required fields have not been filled in.';
        case 'minPassword':
          return 'Password must be at least 8 digits.';
        case 'patternPassword':
          return 'Password must be one-byte alphanumeric characters and symbols.';
        case 'matchesConfirm':
          return 'Confirmation password does not match.';
        case 'maxFileSize':
          return 'Files larger than 10 MB cannot be uploaded.';
        case 'patternEmail':
          return 'Please enter your e-mail address in the format of an e-mail address.';
        default:
          return 'Invalid input.';
      }
    });
    return [Date.now(), messages, `${bgRed.join(',')}{background-color: #FDD !important;} ${fgRed.join(',')}{color: red !important;} `];
  }, [hasValidation, validations]);
  const [errorKey, errorMessages, errorCss] = useMemo(() => {
    if (!hasError) return ['none', [], ''];
    const bgRed = [];
    const fgRed = [];
    const messages = [];

    for (const e of errors || []) {
      const type = e.errorType;
      switch (type) {
        case 'notConnect':
          messages.push({type, msg: 'Communication failed.'});
          break;
        case 'conflict':
          for (const name of Object.getOwnPropertyNames(e.conflict || {})) {
            messages.push({type, msg: `${e.conflict[name]} already exists.`});
            bgRed.push(`.input1Line.input-${name}`);
            bgRed.push(`.inputSelect.input-${name}>div`);
            bgRed.push(`.ncdFiles.name-${name}>.ncdFilesFooter`);
            fgRed.push(`label.input-${name}`);
          }
          break;
        case 'notFound':
          messages.push({type, msg: `Data not found.`});
          break;
        case 'forbidden':
          messages.push({type, msg: `Access to data is restricted for the account you are logged in to.`});
          break;
        case 'unauthorized':
          messages.push({type, msg: `You must be logged in to access the data.`});
          break;
        case 'clientError':
          messages.push({type, msg: `Unauthorized access detected.`});
          break;
        default:
          messages.push({type, msg: `A server failure has occurred. Please contact your system administrator.`});
          break;
      }
    }
    const css = [
      bgRed.length ? `${bgRed.join(',')}{background-color: #FDD !important;}` : '',
      fgRed.length ? `${fgRed.join(',')}{color: red !important;}` : '',
    ].join(' ');

    return [Date.now(), messages, css];
  }, [hasError, errors]);

  const dismissMessage = function(e) {
    const target = e.currentTarget;
    target.classList.add('dismiss');
    setTimeout(() => target.classList.add('hide'), 200);
  };

  return (
    <div className="appErrorMessages">
      <style>{validationCss}{errorCss}</style>
      <div className="errorMessageList">
        {hasValidation && (
          <div className="errorMessage" key={`validationErrorMessage:${validationKey}`} onClick={dismissMessage}>
            <h3>Input Errors<img src={IconCloseError} alt="close"/></h3>
            <p>
              <span>Please check the following errors.</span>
              <ul>
                {validationMessages.map((m, i) => <li key={`validationMessage:${i}`}>{m}</li>)}
              </ul>
            </p>
          </div>
        )}
        {hasError && (
          <div className="errorMessage" key={`errorMessage:${errorKey}`} onClick={dismissMessage}>
            <h3>System Errors<img src={IconCloseError} alt="close"/></h3>
            <p>
              <span>Please check the following errors.</span>
              <ul>
                {errorMessages.map(e => <li key={`message:${e.type}`}>{e.msg}</li>)}
              </ul>
            </p>
          </div>
        )}
      </div>
    </div>
  );
};
