import { useEffect } from 'react';

const hasGtm = !!process.env.REACT_APP_GTM_ID;

const updateTitle = (title) => {
  if (title) {
    document.title = `${title} | Drug Candidate Marketplace`;
  } else {
    document.title = `Drug Candidate Marketplace`;
  }
  if (hasGtm) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({'event': 'delay_title'});
  }
};

export const useTitle = (title, deps = []) => {
  useEffect(() => {
    if (typeof title === 'function') {
      Promise.resolve(title.call()).then(value => {
        if (!value) return;
        updateTitle(value);
      });
    } else {
      updateTitle(title);
    }
    // eslint-disable-next-line
  }, deps);
};
