import React, { useEffect } from 'react';
import { useHistory } from 'react-router';

import { Button } from '../../parts/Button';
import { Card } from '../../parts/Card';

import '../base.css';
import { useDispatch, useSelector } from 'react-redux';
import { loadProfiles } from '../../actions/core';
import { useTitle } from '../../parts/useTitle';

export const MemberEntry = () => {
  useTitle('Notice of Corporate Name Change');

  const dispatch = useDispatch();
  const history = useHistory();
  const organization = useSelector(s => s.core.organization);
  useEffect(() => {
    dispatch(loadProfiles());
  }, [dispatch]);
  return (
    <div id="container" className="layout-login contsMemberEntry">
      <main>
        <h1>Notice of corporate change</h1>
        <div id='containerMain'>
          <Card>
            <p>You have changed your company.</p>
            <div className="companyName">{organization?.organizationName}</div>
            <p>For other account information, please see below.</p>
            <div className="buttonBoxL">
              <Button label="Account settings &gt;" size="250px" onClick={() => history.push('/licensee/profile')}/>
            </div>
          </Card>
        </div>

      </main>
    </div>
  );
};
