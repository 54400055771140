import {
  REQUEST_ASSET,
  RECEIVE_ASSET,
} from '../actions/asset';
import {
  RECEIVE_NAMES,
} from '../actions/core';

export default function asset(state = {}, action) {
  const {type, ...data} = action;
  switch (type) {
    case REQUEST_ASSET:
    case RECEIVE_ASSET:
      return {
        ...state,
        ...data,
      };
    case RECEIVE_NAMES:
      return {
        ...state,
        assetNumbers: data.assetNumbers,
      };
    default:
      return state;
  }
};
