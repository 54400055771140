import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router';

import { Search } from '../../parts/Search';
import { Button } from '../../parts/Button';
import { PageNavi } from '../../parts/PageNavi';
import { Card } from '../../parts/Card';
import {InputSelect} from '../../parts/InputSelect';
import ScrollContainer from 'react-indiana-drag-scroll'

import '../base.css';
import { useDispatch, useSelector } from 'react-redux';
import { fd, useQuery, ref } from '../../parts/refs';
import { findLicensees } from '../../actions/core';
import { useTitle } from '../../parts/useTitle';

export const LicenseeSearch = () => {
  useTitle('Licensee search');

  const history = useHistory();
  const dispatch = useDispatch();
  const query = useQuery();
  const words = query.get('words') || '';
  const status = query.get('status') || '';
  const page = parseInt(query.get('page') || '0');

  useEffect(() => {
    dispatch(findLicensees(words, status, page));
  }, [dispatch, words, status, page]);

  const [licensees, total] = useSelector(s => [s.core.licensees || [], s.core.total || 1]);

  const pForms = {};
  const resolveParams = () => {
    const params = {};
    params.status = pForms.status.value;
    return params;
  };

  return (
    <div id="container" className="layout-login contsAssetSearch">
      <main>
        <h1>Licensee search</h1>
        <div id='containerMain'>
          <Card>
        <div className="searchOptionBox">
          <Search selectType="valid" resolveParams={resolveParams}>
            <InputSelect name="status" label="Valid / Invalid" ref={ref(pForms)} value={query.get('status')} placeholder="..." valueGroup={[{label: 'Valid', value: 'valid'},{label: 'Invalid', value: 'invalid'}]}/>
          </Search>
          <Button label="Add" size="170px" onClick={() => history.push(`/gemseki/licensee_add`)}/>
        </div>
        <div className="searchListboxIn">
        <ScrollContainer className="scroll-container" hideScrollbars="false" vertical="false">
        <table className="tableGray tableGrayThHorizontal">
          <thead>
          <tr>
            <th>Date<br/>(Company Registered)</th>
            <th>Company ID</th>
            <th>Company</th>
            <th>Type of Company</th>
            <th>Licensing Criteria<br/>(Disease Area)</th>
            <th>Licensing Criteria<br/>(Route)</th>
            <th>Licensing Criteria<br/>(Modality)</th>
            <th>Licensing Criteria<br/>(Development Stage)</th>
            <th>Licensing Criteria<br/>(Territory)</th>
            <th>Valid / Invalid</th>
          </tr>
          </thead>
          <tbody>
          {licensees.map(l => {
            return (
              <tr key={`licenseeRow:${l._id}`}>
                <td>{fd(l.createdAt)}</td>
                <td>{l.displayId}</td>
                <td><Link to={`/gemseki/licensee/${l._id}`}><strong>{l.organizationName}</strong></Link></td>
                <td>{l.typeOfCompany?.map(o => <span key={o}>{o}</span>)}</td>
                <td>{l.diseaseArea?.map(o => <span key={o}>{o}</span>)}</td>
                <td>{l.route?.map(o => <span key={o}>{o}</span>)}</td>
                <td>{l.modality?.map(o => <span key={o}>{o}</span>)}</td>
                <td>{l.developmentStage?.map(o => <span key={o}>{o}</span>)}</td>
                <td>{l.territoryForInLicensing?.map(o => <span key={o}>{o}</span>)}</td>
                <td>{l.status}</td>
              </tr>
            );
          })}
          {!licensees?.length && (
            <tr>
              <td colSpan={9}>
                <div className="tableCenter">No results.</div>
              </td>
            </tr>
          )}
          </tbody>
        </table>
        </ScrollContainer>
        </div>
        <PageNavi selected={page} total={total}/>
        </Card>
        </div>
      </main>
    </div>
  );
};
