import React, { useEffect, useState } from 'react';

import { SearchTab } from '../../parts/SearchTab';
import { Input1Line } from '../../parts/Input1Line';
import { CheckBoxGroup } from '../../parts/CheckBoxGroup';
import { InputSelect } from '../../parts/InputSelect';
import { NcdFiles } from '../../parts/NcdFiles';
import { Button } from '../../parts/Button';
import { Card } from '../../parts/Card';

import '../base.css';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { getOriginatorNames } from '../../actions/core';
import { DataDevelopmentStage, DataDiseaseArea, DataModality, DataRoute, DataTerritoryForInLicensing, DataTypeOfPreferredPartnering } from '../../reducers/datas';
import { createAsset, downloadNc, uploadNc } from '../../actions/asset';
import { ref } from '../../parts/refs';
import { useTitle } from '../../parts/useTitle';

export const AssetAdd = () => {
  useTitle('Asset add');

  const history = useHistory();
  const dispatch = useDispatch();

  const names = useSelector(s => s.core.originatorNames || []);
  const temporaryId = useSelector(s => s.asset.temporaryId);

  const [assetType, setAssetType] = useState('seeds');

  const isSeeds = assetType === 'seeds';
  const isTech = assetType === 'technologies';

  useEffect(() => {
    dispatch(getOriginatorNames());
  }, [dispatch]);

  const forms = {};

  const resolveData = () => {
    return {
      assetType,
      assetNumber: forms.assetNumber.value,
      organizationId: forms.organizationId.value,
      mechanismOfAction: isSeeds ? forms.mechanismOfAction.value : undefined,
      indication: isSeeds ? forms.indication.value : undefined,
      route: isSeeds ? forms.route.values : undefined,
      modality: isSeeds ? forms.modality.values : undefined,
      developmentStage: isSeeds ? forms.developmentStage.values : undefined,
      note: isSeeds ? forms.note.value : undefined,
      diseaseArea: isSeeds ? forms.diseaseArea.values : undefined,
      technology: isTech ? forms.technology.value : undefined,
      summary: isTech ? forms.summary.value : undefined,
      status: 'public',
      typeOfPreferredPartnering: forms.typeOfPreferredPartnering.value,
      territoryForLicensing: forms.territoryForLicensing.value,
      territoryNotAvailableForLicensing: forms.territoryNotAvailableForLicensing.value,
      originalCompoundName: forms.originalCompoundName.value,
      gemsekisNotes: forms.gemsekisNotes.value,
      ncs: forms.ncs.values,
      temporaryId,
    };
  };

  return (
    <div id="container" className={`layout-login contsAssetAdd contsAssetAdd${assetType}`}>
      <main>
        <h1>Asset add</h1>
        <SearchTab size="long" selected={assetType}
                                   valueGroup={[
                                     {label: 'Drug Candidates', value: 'seeds'},
                                     {label: 'Technologies', value: 'technologies'},
                                   ]}
                                   onSelected={(at) => setAssetType(at)}
        />
            <div className="tabcontent-area">
              <div className="tabcontent">
                <Card>
        <table className="tableGray tableGrayThVertical">
          <tbody>
          <tr>
            <th><label className="tableGrayLabel">Date (Asset Registered)</label></th>
            <td><p>-</p></td>
            <th><label className="tableGrayLabel">Date (Asset Updated)</label></th>
            <td><p>-</p></td>
          </tr>
          <tr>
            <th style={{paddingTop:"26px"}}><label className="tableGrayLabel">Asset No.</label></th>
            <td><Input1Line name="assetNumber" ref={ref(forms)} size="177px" placeholder="Type here…"/></td>
            <th style={{paddingTop:"26px"}} className="gicon"><label className="tableGrayLabel">Originator</label></th>
            <td>
              <div className="tableGraySearch"><InputSelect name="organizationId" ref={ref(forms)} placeholder="Select" size="400px" valueGroup={names}/></div>
            </td>
          </tr>
          {
            isSeeds &&
            <tr>
              <th style={{paddingTop:"26px"}}><label className="tableGrayLabel">Mode of Action</label></th>
              <td colSpan={3}><Input1Line name="mechanismOfAction" ref={ref(forms)} size="100%" placeholder="Type here…"/></td>
            </tr>
          }
          {
            isSeeds &&
            <tr>
              <th style={{paddingTop:"26px"}}><label className="tableGrayLabel">Indication</label></th>
              <td colSpan={3}>
                <div className="input1LineBox"><textarea name="indication" ref={ref(forms)} placeholder="Type here…"/></div>
              </td>
            </tr>
          }
          {
            isSeeds &&
            <tr>
              <th><label className="tableGrayLabel">Route</label></th>
              <td colSpan={3}><CheckBoxGroup name="route" ref={ref(forms)} valueGroup={DataRoute}/></td>
            </tr>
          }
          {
            isSeeds &&
            <tr>
              <th><label className="tableGrayLabel">Modality</label></th>
              <td colSpan={3}><CheckBoxGroup name="modality" ref={ref(forms)} valueGroup={DataModality}/></td>
            </tr>
          }
          {
            isSeeds &&
            <tr>
              <th><label className="tableGrayLabel">Development Stage</label></th>
              <td colSpan={3}><CheckBoxGroup name="developmentStage" ref={ref(forms)} valueGroup={DataDevelopmentStage}/></td>
            </tr>
          }
          {
            isSeeds &&
            <tr>
              <th style={{paddingTop:"26px"}}><label className="tableGrayLabel">Note</label></th>
              <td colSpan={3}>
                <div className="input1LineBox"><textarea name="note" ref={ref(forms)} rows="5" placeholder="Type here…"/></div>
              </td>
            </tr>
          }
          {
            isSeeds &&
            <tr>
              <th><label className="tableGrayLabel">Disease Area</label></th>
              <td colSpan={3}><CheckBoxGroup name="diseaseArea" ref={ref(forms)} valueGroup={DataDiseaseArea}/></td>
            </tr>
          }
          {
            isTech &&
            <tr>
              <th style={{paddingTop:"26px"}}><label className="tableGrayLabel">Technology</label></th>
              <td colSpan={3}><Input1Line name="technology" ref={ref(forms)} size="100%" placeholder="Type here…"/></td>
            </tr>
          }
          {
            isTech &&
            <tr>
              <th><label className="tableGrayLabel">Summary</label></th>
              <td colSpan={3}>
                <div className="input1LineBox"><textarea name="summary" ref={ref(forms)} rows="5" placeholder="Type here…"/></div>
              </td>
            </tr>
          }
          <tr>
            <th style={{paddingTop:"26px"}} className="gicon"><label className="tableGrayLabel">Type of Preferred Partnering</label></th>
            <td colSpan={3}>
              <div className="input1LineBox"><InputSelect name="typeOfPreferredPartnering" ref={ref(forms)} placeholder="Select" size="436px" valueGroup={DataTypeOfPreferredPartnering}/></div>
            </td>
          </tr>
          <tr>
            <th style={{paddingTop:"26px"}} className="gicon"><label className="tableGrayLabel">Licensing Available Area</label></th>
            <td colSpan={3}>
              <div className="input1LineBox"><InputSelect name="territoryForLicensing" ref={ref(forms)} placeholder="Select" size="436px" valueGroup={DataTerritoryForInLicensing}/></div>
            </td>
          </tr>
          <tr>
            <th style={{paddingTop:"26px"}} className="gicon"><label className="tableGrayLabel">Licensing Unavailable Area</label></th>
            <td colSpan={3}><Input1Line name="territoryNotAvailableForLicensing" ref={ref(forms)} size="100%" placeholder="Type here…"/></td>
          </tr>
          <tr>
            <th style={{paddingTop:"26px"}} className="gicon"><label className="tableGrayLabel">Original Name of Compound</label></th>
            <td colSpan={3}><Input1Line name="originalCompoundName" ref={ref(forms)} size="100%" placeholder="Type here…"/></td>
          </tr>
          <tr>
            <th style={{paddingTop:"26px"}} className="gicon"><label className="tableGrayLabel">Gemseki’s Memo</label></th>
            <td colSpan={3}>
              <div className="input1LineBox"><textarea name="gemsekisNotes" ref={ref(forms)} rows="5" placeholder="Type here…"/></div>
            </td>
          </tr>
          </tbody>
        </table>
        <NcdFiles
          name="ncs"
          ref={ref(forms)}
          editMode={true}
          onUpload={async (inputFile) => {
            return await dispatch(uploadNc(temporaryId, inputFile));
          }}
          onDownload={async (fileItem) => {
            dispatch(downloadNc(fileItem, '', temporaryId));
          }}/>
        <div className="buttonBoxCenter">
          <Button label="Cancel" size="150px" type="gray" onClick={() => window.location.reload()}/>
          <Button label="Save" size="150px" onClick={() => dispatch(createAsset(resolveData(), history))}/>
        </div>
        </Card>
        </div>
        </div>
      </main>
    </div>
  );
};
