import React, { useCallback, useEffect } from 'react';

import '../base.css';

import { CardNew } from '../../parts/CardNew';
import { ListMessage } from '../../parts/ListMessage';
import { ListRequest } from '../../parts/ListRequest';

import { useDispatch, useSelector } from 'react-redux';
import { loadGemsekiDashboard } from '../../actions/dashboard';
import { ShortMessageBody } from '../../parts/MessageBody';
import { RequestPopups, useCdaControl, useNcdControl } from '../../parts/RequestPopups';
import { requestNcd, updateNcd } from '../../actions/nc_request';
import { requestCda, updateCda } from '../../actions/cda_request';
import { Card } from '../../parts/Card';
import { useTitle } from '../../parts/useTitle';
import { Link } from "react-router-dom";

export const Dashboard = () => {
  useTitle('Dashboard');

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadGemsekiDashboard());
  }, [dispatch]);

  const [
    messageCount,
    cdaRequestCount,
    ncRequestCount,
    messages,
    cdaRequests,
    ncRequests,
    user,
  ] = useSelector(s => [
    s.dashboard.messageCount || 0,
    s.dashboard.cdaRequestCount || 0,
    s.dashboard.ncRequestCount || 0,
    s.dashboard.messages || [],
    s.dashboard.cdaRequests || [],
    s.dashboard.ncRequests || [],
    s.core.user,
  ]);

  const reload = () => {
    dispatch(loadGemsekiDashboard());
  };

  const onNewNcd = useCallback(async (assetId, licenseeOrgId, comment) => {
    return await dispatch(requestNcd(assetId, licenseeOrgId, comment));
  }, [dispatch]);

  const onUpdateNcd = useCallback(async (ncId, newStatus, comment, reasons) => {
    return await dispatch(updateNcd(ncId, newStatus, comment, reasons));
  }, [dispatch]);

  const [showNcdChange, ncdControl] = useNcdControl(user.organizationType, onNewNcd, onUpdateNcd, reload);

  const onNewCda = useCallback(async (assetId, licenseeOrgId, comment) => {
    return await dispatch(requestCda(assetId, licenseeOrgId, comment));
  }, [dispatch]);

  const onUpdateCda = useCallback(async (cdaId, newStatus, comment, reasons) => {
    return await dispatch(updateCda(cdaId, newStatus, comment, reasons));
  }, [dispatch]);

  const [showCdaChange, cdaControl] = useCdaControl(user.organizationType, onNewCda, onUpdateCda, reload);

  return (
    <div id="container" className="layout-login contsDashboard">
      <main>
        <h1>Dashboard</h1>
        <div id='containerMain'>
          <div className='contsDashboardHeader'>
            <h2 className='h2message'>New NCD request{ncRequestCount !== 0 && <span className='badge'>{ncRequestCount}</span>}</h2>
            <Link to={`/gemseki/ncd_requests`}>NCD request&nbsp;&nbsp;&gt;</Link>
          </div>
          {ncRequests?.length ? (
            <CardNew mode="NCD" user="Gemseki">
              {ncRequests.map(ncd => (
                <ListRequest
                  key={`dashboard-list-request:NCD:${ncd?._id}`}
                  user="Gemseki"
                  mode="NCD"
                  requestData={ncd}
                  onChangeStatus={(newStatus) => showNcdChange(ncd?.licenseeOrgId, ncd?.assetId, ncd?._id, newStatus)}
                  unread={true}
                >
                  {ncd.comment}
                </ListRequest>
              ))}
            </CardNew>
          ) : (
            <Card>
              <div className="cardCenter">No new requests.</div>
            </Card>
          )}

          <div className='contsDashboardHeader'>
            <h2 className='h2message'>New CDA request{cdaRequestCount !== 0 && <span className='badge'>{cdaRequestCount}</span>}</h2>
            <Link to={`/gemseki/cda_requests`}>CDA request&nbsp;&nbsp;&gt;</Link>
          </div>
          {cdaRequests?.length ? (
            <CardNew mode="CDA" user="Gemseki">
              {cdaRequests.map(cda => (
                <ListRequest
                  key={`dashboard-list-request:CDA:${cda?._id}`}
                  user="Gemseki"
                  mode="CDA"
                  requestData={cda}
                  onChangeStatus={(newStatus) => showCdaChange(cda?.licenseeOrgId, cda?.assetId, cda?._id, newStatus)}
                  unread={true}
                >
                  {cda.comment}
                </ListRequest>
              ))}
            </CardNew>
          ) : (
            <Card>
              <div className="cardCenter">No new requests.</div>
            </Card>
          )}

          <div className='contsDashboardHeader'>
            <h2 className='h2mail'>New message{messageCount !== 0 && <span className='badge'>{messageCount}</span>}</h2>
            <Link to={`/gemseki/rooms`} className='contsDashboardHeaderMail'>Message list&nbsp;&nbsp;&gt;</Link>
          </div>
          {messages?.length ? (
            <CardNew mode="Message" user="Gemseki">
              {messages.map(room => (
                <ListMessage room={room} user={user} key={`dashboard-room:${room?._id}`} unread={true} header={true}>
                  <ShortMessageBody room={room} message={room.latestMessage} isGemseki={true}/>
                </ListMessage>
              ))}
            </CardNew>
          ) : (
            <Card>
              <div className="cardCenter">No new messages.</div>
            </Card>
          )}

          <RequestPopups {...ncdControl}/>
          <RequestPopups {...cdaControl}/>
        </div>
        </main>
    </div>
  );
};
