import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { areas, countries } from '../reducers/countries';

export const useCountries = (defaultArea) => {
  const [area, setArea] = useState(defaultArea);
  const countryRef = useRef({});

  const areaValues = useMemo(() => {
    return areas.map(a => ({label: a, value: a}));
  }, []);
  const countryValues = useMemo(() => {
    return areas.includes(area) ? countries[area].map(c => ({label: c, value: c})) : [];
  }, [area]);

  const areaCallback = useCallback((item) => {
    setArea(item?.value);
    countryRef.current.value = null;
  }, [countryRef]);

  useEffect(() => {
    setArea(defaultArea);
  }, [defaultArea]);

  return [areaValues, countryValues, areaCallback, countryRef];
};
