import React from 'react';
import PropTypes from 'prop-types';
import './chatTalk.css';

export const ChatTalk = ({ lr, contributor, postDate, postTime, children }) => {
  return (
    <article className={['chatTalk', `chatTalk--${lr}`].join(' ')}>
      <div className="chatTalkBody" style={{ whiteSpace: 'pre-wrap' }}>
        <h4 className="chatTalkHead">
          <div className="chatTalkContributor">{contributor}</div>
          <div className="chatTalkPostDate">{postDate}</div>
          <div className="chatTalkPostTime">{postTime}</div>
        </h4>
        <div className="chatTalkMain">{children}</div>
      </div>
    </article>
  );
};

ChatTalk.propTypes = {
  /**
   * 右か左か
   */
  lr: PropTypes.oneOf(['l', 'r']),
  /**
   * 投稿者
   */
  contributor: PropTypes.string,
  /**
   * 投稿時
   */
  postDate: PropTypes.string,
  /**
   * 投稿時間
   */
  postTime: PropTypes.string,
};

ChatTalk.defaultProps = {
  lr: 'l',
  contributor: 'Gemseki',
  postDate: '04/28/22',
  postTime: '10:15',
};
