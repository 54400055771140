import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { fetchUser } from '../actions/core';

export const Home = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [user, loginError] = useSelector(s => [s.core.user, s.core.loginError]);

  useEffect(() => {
    dispatch(fetchUser());
  }, [dispatch]);

  useEffect(() => {
    if (loginError) {
      history.replace(`/login`);
    } else if (user && user.organizationType) {
      history.replace(`/${user.organizationType}`);
    }
  }, [history, user, loginError]);

  return (
    <article>
    </article>
  );
};
