import React, { useCallback, useRef, useState } from 'react';
import { Popup } from './Popup';
import { CheckBoxGroup } from './CheckBoxGroup';
import { DataDeclinedGemsekiReasons, DataDeclinedLicenseeReasons, DataPendingReasons, DataDeclinedByOriginatorReasons } from '../reducers/datas';
import { Link } from 'react-router-dom';

const TYPE_NCD = 'NCD';
const TYPE_CDA = 'CDA';

export const resolveReasons = (data) => {
  switch (data?.status) {
    case 'declinedByOriginator':
    case 'pending':
    case 'declinedByLicensee':
      return data?.reasons?.join(' / ');
    default :
      return false;
  }
};

function getReasons(organizationType, requestType, status) {
  if (requestType === TYPE_NCD) {
    switch (status) {
      case 'pending':
        return DataPendingReasons;
      case 'declinedByLicensee':
        if (organizationType === 'gemseki') {
          return DataDeclinedGemsekiReasons;
        } else {
          return DataDeclinedLicenseeReasons;
        }
      case 'declinedByOriginator':
        return DataDeclinedByOriginatorReasons;
      default:
        return [];
    }
  } else {
    switch (status) {
      default:
        return [];
    }
  }
}

const usePopupControl = (organizationType, requestType, newCallback, updateCallback, reloadCallback, isFirst) => {
  const [messages, setMessages] = useState({});
  const [selected, setSelected] = useState({assetId: '', targetId: '', licenseeOrgId: '', item: {value: ''}, reasons: []});

  const popupNewRef = useRef(null);
  const popupUpdateRef = useRef(null);
  const popupDeclinedRef = useRef(null);
  const popupSuccessRef = useRef(null);
  const newCommentRef = useRef(null);
  const updateReasonsRef = useRef(null);
  const declinedReasonsRef = useRef(null);

  const showStatusChange = useCallback((licenseeOrgId, assetId, targetId, item) => {
    setMessages({});
    const reasons = getReasons(organizationType, requestType, item.value);
    setSelected({licenseeOrgId, assetId, targetId, item, reasons});
    if (item.value === 'newRequest') {
      if (newCommentRef.current) {
        newCommentRef.current.value = '';
      }
      popupNewRef.current.show();
    } else if (item.value === 'declinedByLicensee' && requestType === 'NCD') {
      if (reasons.length && declinedReasonsRef.current) {
        declinedReasonsRef.current.values = [];
      }
      popupDeclinedRef.current.show();
    } else {
      if (reasons.length && updateReasonsRef.current) {
        updateReasonsRef.current.values = [];
      }
      popupUpdateRef.current.show();
    }
  }, [organizationType, requestType]);

  const onRequest = useCallback(async () => {
    const {licenseeOrgId, assetId, targetId, item} = selected;
    setMessages({});
    if (item.value === 'newRequest') {
      const resultNew = await newCallback.call(this, assetId, licenseeOrgId, newCommentRef.current?.value || '');
      if (resultNew?.ok) {
        popupNewRef.current.hide();
        popupSuccessRef.current.show();
        reloadCallback.call(this);
      } else {
        setMessages({new: resultNew.message});
      }
    } else if (item.value === 'declinedByLicensee' && requestType === 'NCD') {
      const resultDeclined = await updateCallback.call(this, targetId, item.value, '', declinedReasonsRef.current?.values || []);
      if (resultDeclined?.ok) {
        popupDeclinedRef.current.hide();
        popupSuccessRef.current.show();
        reloadCallback.call(this);
      } else {
        setMessages({declined: resultDeclined.message});
      }
    } else {
      const resultUpdate = await updateCallback.call(this, targetId, item.value, '', updateReasonsRef.current?.values || []);
      if (resultUpdate?.ok) {
        popupUpdateRef.current.hide();
        popupSuccessRef.current.show();
        reloadCallback.call(this);
      } else {
        setMessages({update: resultUpdate.message});
      }
    }
  }, [selected, newCallback, updateCallback, requestType, reloadCallback]);

  return [
    showStatusChange,
    {
      requestType,
      selected,
      popupNewRef,
      popupUpdateRef,
      popupDeclinedRef,
      popupSuccessRef,
      newCommentRef,
      updateReasonsRef,
      declinedReasonsRef,
      messages,
      onRequest,
      organizationType,
      isFirst,
    },
  ];
};

export const useNcdControl = (organizationType, newCallback, updateCallback, reloadCallback, isFirst = false) => {
  return usePopupControl(organizationType, TYPE_NCD, newCallback, updateCallback, reloadCallback, isFirst);
};

export const useCdaControl = (organizationType, newCallback, updateCallback, reloadCallback, isFirst = false) => {
  return usePopupControl(organizationType, TYPE_CDA, newCallback, updateCallback, reloadCallback, isFirst);
};

export const RequestPopups = ({requestType, selected, popupNewRef, popupUpdateRef, popupDeclinedRef, popupSuccessRef, newCommentRef, updateReasonsRef, declinedReasonsRef, messages, onRequest, organizationType, isFirst}) => {
  const isNcd = requestType === 'NCD';
  const isLicensee = organizationType === 'licensee';
  const checkSettings = isFirst && isLicensee;
  const titleText = checkSettings ? "Send this " : "Change the status of " ;
  return (
    <div>
      <Popup ref={popupNewRef} onSubmit={onRequest} title={`${titleText}${requestType} requests`} submitButton="OK">
        {checkSettings && <p>You can enter the "License Criteria" in the Account Settings to make it easier to get approved.</p>}
        {checkSettings && <Link className="toAccountSettings" to={`/licensee/profile`}>Account settings &gt;</Link>}
        {!checkSettings && <p>Are you sure you want to change the {requestType} status to the following?</p>}
        {!checkSettings && <div className={['popupStatus', selected.item.value].join(' ')}>New Request</div>}
        {isNcd && <p>Please send us a message to let us know what kind of development you are looking for.</p>}
        {messages.new}
        {isNcd && <textarea ref={newCommentRef} rows="3" placeholder="Type here…"/>}
      </Popup>
      <Popup ref={popupDeclinedRef} onSubmit={onRequest} title={`Change the status of ${requestType} requests`} submitButton="OK">
        {!!selected.reasons?.length
          ? <p>If you choose Declined, you will no longer be able to view {requestType}s, is that okay?<br/>Please select a reason from the checkboxes.Please provide a detailed reason in the message box.</p>
          : <p>If you choose Declined, you will no longer be able to view {requestType}s, is that okay?<br/>Please provide a detailed reason in the message box.</p>
        }
        <div className={['popupStatus', selected.item.value].join(' ')}>{selected.item.label}</div>
        {messages.declined}
        {!!selected.reasons?.length &&
          <div className="popupDecline">
            <CheckBoxGroup name={`reason-declined:${requestType}`} ref={declinedReasonsRef} valueGroup={selected.reasons}/>
          </div>
        }
      </Popup>
      <Popup ref={popupUpdateRef} onSubmit={onRequest} title={`Change the status of ${requestType} requests`} submitButton="OK">
        {!!selected.reasons?.length
          ? <p>Are you sure you want to change the {requestType} status to the following?<br/>Please select a reason from the checkboxes.</p>
          : <p>Are you sure you want to change the {requestType} status to the following?</p>
        }
        <div className={['popupStatus', selected.item.value].join(' ')}>{selected.item.label}</div>
        {messages.update}
        {!!selected.reasons?.length &&
          <div className="popupDecline">
            <CheckBoxGroup name={`reason-update:${requestType}`} ref={updateReasonsRef} valueGroup={selected.reasons}/>
          </div>
        }
      </Popup>
      <Popup ref={popupSuccessRef} title="Request Success." submitButton="" cancelButton="Close"/>
    </div>
  );
};
