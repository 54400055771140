import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector as hookState } from 'react-redux';
import { useHistory } from 'react-router';
import { Button } from '../parts/Button';
import PropTypes from 'prop-types';
import './base.css';
import './page.css';
import './login.css';
import { clearError, login } from '../actions/core';
import { Link } from 'react-router-dom';
import { Input1Line } from '../parts/Input1Line';
import { useTitle } from '../parts/useTitle';
import { externalApi } from '../actions/api';
import { useRecaptcha } from '../parts/useRecaptcha';

export const Login = ({isLoggingIn = hookState(s => !!s.core.loading)}) => {
  useTitle('Sign in');
  const {executeRecaptcha} = useRecaptcha();

  const dispatch = useDispatch();
  const history = useHistory();
  const idRef = useRef(null);
  const passwordRef = useRef(null);
  useEffect(() => {
    dispatch(clearError());
  }, [dispatch]);
  return (
    <div id="container" className="layout-white contsLogin">
      <main>
        <h1>Sign in</h1>

        <div className="inputBox">
          <Input1Line name="id" label="Email address" disabled={isLoggingIn} ref={idRef} autoComplete="username" placeholder="Type here…"/>
        </div>
        <div className="inputBox2">
          <Input1Line type="password" name="password" label="Password" disabled={isLoggingIn} ref={passwordRef} autoComplete="current-password" placeholder="Type here…"/>
        </div>

        <Button disabled={isLoggingIn} label="Sign in" type="white" onClick={async () => {
          const recaptchaToken = await externalApi(dispatch, async () => await executeRecaptcha('SignIn'));
          dispatch(login(idRef.current.value, passwordRef.current.value, recaptchaToken, history))
        }}/>
        <Link to="/forgot_password" className="forgotLink">Forgot Your Password?</Link>

        <hr className="loginHr"/>
        <Button disabled={isLoggingIn} label="Sign up" onClick={() => history.push('/signup')}/>
      </main>
    </div>
  );
};

Login.propTypes = {
  isLoggingIn: PropTypes.bool,
};
