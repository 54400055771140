import {
  REQUEST_BOOKMARK,
  RECEIVE_BOOKMARK,
} from '../actions/bookmark';

export default function bookmark(state = {}, action) {
  const {type, ...data} = action;
  switch (type) {
    case REQUEST_BOOKMARK:
    case RECEIVE_BOOKMARK:
      return {
        ...state,
        ...data,
      };
    default:
      return state;
  }
};
