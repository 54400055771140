import React, {useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { AsideList } from './AsideList';
import { Button } from './Button';
import './aside.css';
import LogoImg from './assets/logo.svg';
import LogoNofontImg from './assets/logo_nofont.svg';
import { logout } from '../actions/core';

export const AsideGemseki = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [showSideMenu, setShowSideMenu] = useState(true);
  const location = useLocation();

  const [
    messageCount,
    cdaRequestCount,
    ncRequestCount,
  ] = useSelector(s => [
    s.dashboard.messageCount || 0,
    s.dashboard.cdaRequestCount || 0,
    s.dashboard.ncRequestCount || 0,
  ]);

  useEffect(() => {
    if(showSideMenu) {
      document.getElementsByTagName('main')[0].classList.remove('closeMain');
    } else {
      document.getElementsByTagName('main')[0].classList.add('closeMain');
    }
  }, [showSideMenu, location]);

  return (
    <aside className={`asideMain ${showSideMenu ? "openSideMenu" : "closeSideMenu"}`}>
      <div className={`asideMainHeader ${showSideMenu ? "" : "closeArrow"}`}>
        <div>
          <span class={`arrow ${showSideMenu ? "left" : "right"}`} onClick={() => setShowSideMenu(!showSideMenu)} >
            <span class="inner"></span>
          </span>
        </div>
        <a href="/en/" target="_self"><img src={showSideMenu ? LogoImg : LogoNofontImg} alt="Drug Candidate Marketplace"/></a>
        <h2 className={`${showSideMenu ? "" : "visibilityNone"}`}>Drug Candidate<br/>Marketplace</h2>
        <h3 className={`${showSideMenu ? "" : "visibilityNone"}`}>for Gemseki</h3>
      </div>
      <ul className={`asideLiGemseki ${showSideMenu ? "" : "closeSideMenu"}`}>
        <AsideList menuId="201" label="Dashboard" to="/gemseki" open={showSideMenu} />
        <AsideList menuId="202" label="Message list" to="/gemseki/rooms" count={messageCount} open={showSideMenu} />
        <AsideList menuId="204" label="NCD request" to="/gemseki/ncd_requests" count={ncRequestCount} open={showSideMenu} />
        <AsideList menuId="203" label="CDA request" to="/gemseki/cda_requests" count={cdaRequestCount} open={showSideMenu} />
        <AsideList menuId="206" label="Asset search" to="/gemseki/assets" open={showSideMenu} />
        <AsideList menuId="207" label="Asset add" to="/gemseki/asset_add" open={showSideMenu} />
        <AsideList menuId="208" label="Licensee" to="/gemseki/licensees" open={showSideMenu} />
        <AsideList menuId="209" label="Originator" to="/gemseki/originators" open={showSideMenu} />
      </ul>
      <ul className={`asideLiAdmin ${showSideMenu ? "" : "closeSideMenu"}`}>
      <li className={`asideList asideList--false asideListIndent--false ${showSideMenu ? "" : "closeSideMenuChild"}`}>
          <a href="/login_28814" target="wordpress">{showSideMenu ? 'Wordpress' : ''}</a>
        </li>
        <li className={`asideList asideList--false asideListIndent--false ${showSideMenu ? "" : "closeSideMenuChild"}`}>
          <a href="https://gemseki.my.salesforce.com/" target="saleceforce">{showSideMenu ? 'Salesforce' : ''}</a>
        </li>
        <li className={`asideList asideList--false asideListIndent--false ${showSideMenu ? "" : "closeSideMenuChild"}`}>
          <a href="https://analytics.google.com/analytics/web/?hl=ja#/report-home/a107381195w160351291p161566253" target="Google Analytics">{showSideMenu ? 'Google Analytics' : ''}</a>
        </li>
      </ul>
      <ul className='asideLiProfile'>
        <AsideList menuId="213" label="Account settings" to="/gemseki/profile" open={showSideMenu}/>
        <li className={`asideList asideList--false asideListIndent--false ${showSideMenu ? "" : "closeSideMenuChild"}`}>
          <Button label={showSideMenu ? 'Log out' : ''} onClick={() => dispatch(logout('/login', history))}/>
        </li>
      </ul>
      <div className="asideMainFooter">
      </div>
    </aside>
  );
};
