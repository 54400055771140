import React from 'react';
import PropTypes from 'prop-types';
import './buttonStar.css';

/**
 * Primary UI component for user interaction
 */
export const ButtonStar = ({
    selected,
    id,
    onClick,
  }) => {
  return (
    <div className="buttonStarBox">
      <button
        type="button" id={id}
        className={['buttonStar', `buttonStarSelected--${selected}`].join(' ')}
        onClick={onClick}
      >
      </button>
    </div>
  );
};

ButtonStar.propTypes = {
  /**
   * アクティブかどうか
   */
   selected: PropTypes.bool,
  /**
   * 固有のID
   */
   id: PropTypes.string,
   /**
    * ボタン押下コールバック
    */
   onClick: PropTypes.func,
};

ButtonStar.defaultProps = {
  selected: false,
  id: "",
};
