import React, { useRef } from 'react';

import './base.css';
import './page.css';
import './forgotPassword.css';
import { Input1Line } from '../parts/Input1Line';
import { Button } from '../parts/Button';
import { useHistory, useLocation } from 'react-router';
import { useDispatch } from 'react-redux';
import { forgotPassword } from '../actions/core';
import { useTitle } from '../parts/useTitle';
import { externalApi } from '../actions/api';
import { useRecaptcha } from '../parts/useRecaptcha';

export const ForgotPassword = () => {
  const location = useLocation();
  const hash = location.hash || '';

  useTitle(() => hash === '#send' ? 'Forgot Your Password completed' : 'Forgot Your Password?', [hash]);
  const {executeRecaptcha} = useRecaptcha();

  const history = useHistory();
  const dispatch = useDispatch();
  const emailRef = useRef(null);
  if (hash === '#send') {
    return (
      <div id="container" className="layout-white contentsForgot">
        <main>
          <h1>Forgot Your Password?</h1>

          <p>
            Please check your email.
          </p>

          <a href="/en/" className="backToHome">Back to Home</a>
        </main>
      </div>
    );
  } else {
    return (
      <div id="container" className="layout-white contentsForgot">
        <main>
          <h1>Forgot Your Password?</h1>

          <p>
            Please entry the email you use for Drug Candidate Marketplace.<br/>We will send you an instruction to reset your password.
          </p>

          <div className="inputBox">
            <Input1Line name="email" ref={emailRef} placeholder="Type here…" label="Email address"/>
          </div>

          <Button label="Send" type="white" onClick={async () => {
            const recaptchaToken = await externalApi(dispatch, async () => await executeRecaptcha('ForgotPassword'));
            if (await dispatch(forgotPassword(emailRef.current.value, recaptchaToken))) {
              history.push('/forgot_password#send');
            }
          }}/>
        </main>
      </div>
    );
  }
};
