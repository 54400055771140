import {useMemo} from "react";
import {useQuery} from "./refs";
import {useHistory, useLocation} from "react-router";

export class OrderControl {
  #isRequired;
  #isDefault;
  #defaultDir;
  #key;
  #query;
  #history;
  #hash;
  #selected;
  #dir;
  #classes;
  className;

  constructor(isRequired, isDefault, key, query, history, hash) {
    this.#isRequired = isRequired;
    this.#isDefault = isDefault;

    const keys = key.split(':');
    this.#defaultDir = keys.length > 1 && keys[1] === 'asc' ? 1 : -1;

    this.#key = keys[0];
    this.#query = query;
    this.#history = history;
    this.#hash = hash || '';
    this.#classes = ['listOrder'];
    this.#dir = query.has('dir') ? (parseInt(query.get('dir')) || this.#defaultDir) : this.#defaultDir;

    if (query.has('order')) {
      if (query.get('order') === this.#key) {
        this.#selected = true;
        this.#classes.push('selected');
      }
    } else if (this.#isDefault) {
      this.#selected = true;
      this.#classes.push('selected');
    }

    if (this.#selected) {
      if (this.#dir === -1) {
        this.#classes.push('desc');
      } else {
        this.#classes.push('asc');
      }
    }
  }

  get classes() {
    return this.#classes;
  }

  get className() {
    return this.classes.join(' ');
  }

  onSwitch() {
    const query = new URLSearchParams(this.#query);
    if (!this.#selected) {
      if (this.#isDefault) {
        query.delete('order');
        query.delete('dir');
      } else {
        query.set('order', this.#key);
        query.set('dir', this.#defaultDir.toString());
      }
    } else if (this.#isRequired || this.#defaultDir === this.#dir) {
      query.set('order', this.#key);
      query.set('dir', this.#dir === -1 ? '1' : '-1');
    } else {
      query.delete('order');
      query.delete('dir');
    }
    this.#history.push(`?${query}${this.#hash}`);
  }
}

export const useListOrder = (defaultIndex, ...keys) => {
  const {hash} = useLocation();
  const history = useHistory();
  const query = useQuery();
  return useMemo(() => {
    const isRequired = defaultIndex >= 0 && defaultIndex < keys.length;
    return keys.map((key, i) => new OrderControl(isRequired, i === defaultIndex, key, query, history, hash));
    // eslint-disable-next-line
  }, [history, query, hash, defaultIndex, ...keys]);
};
