import React, {useState, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from "react-router";
import { AsideList } from './AsideList';
import { Button } from './Button';
import './aside.css';
import LogoImg from './assets/logo.svg';
import LogoNofontImg from './assets/logo_nofont.svg';
import { logout } from "../actions/core";

export const AsideLicensee = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [showSideMenu, setShowSideMenu] = useState(true);
  const location = useLocation();

  const [
    messageCount,
    bagCount,
  ] = useSelector(s => [
    s.dashboard.messageCount || 0,
    s.asset.bag?.length || 0,
  ]);
  
  useEffect(() => {
    if(showSideMenu) {
      document.getElementsByTagName('main')[0].classList.remove('closeMain');
    } else {
      document.getElementsByTagName('main')[0].classList.add('closeMain');
    }
  }, [showSideMenu, location]);

  return (
    <aside className={`asideMain ${showSideMenu ? "openSideMenu" : "closeSideMenu"}`}>
      <div className={`asideMainHeader ${showSideMenu ? "" : "closeArrow"}`}>
        <div>
          <span class={`arrow ${showSideMenu ? "left" : "right"}`} onClick={() => setShowSideMenu(!showSideMenu)} >
            <span class="inner"></span>
          </span>
        </div>
        <a href="/en/" target="_self"><img src={showSideMenu ? LogoImg : LogoNofontImg} alt="Drug Candidate Marketplace"/></a>
        <h2 className={`${showSideMenu ? "" : "visibilityNone"}`}>Drug Candidate<br/>Marketplace</h2>
        <h3 className={`${showSideMenu ? "" : "visibilityNone"}`}>for Licensee</h3>
      </div>
      <ul className={`asideLiLicensee ${showSideMenu ? "" : "closeSideMenu"}`}>
        <AsideList menuId="101" label="Dashboard" to="/licensee" open={showSideMenu} />
        <AsideList menuId="102" label="Message list" to="/licensee/rooms" count={messageCount} open={showSideMenu} />
        <AsideList menuId="103" label="Asset search" to="/licensee/assets" open={showSideMenu} />
        <AsideList menuId="111" label="Asset bag" to="/licensee/assetbag" count={bagCount} open={showSideMenu} />
        <AsideList menuId="104" label="Asset bookmark" to="/licensee/bookmarks" open={showSideMenu} />
        <AsideList menuId="105" label="NCD request " to="/licensee/ncd_requests" open={showSideMenu} />
        <AsideList menuId="106" label="CDA request" to="/licensee/cda_requests" open={showSideMenu} />
      </ul>
      <ul className='asideLiProfile'>
        <AsideList menuId="110" label="Account settings" to="/licensee/profile" open={showSideMenu} />
        <li className={`asideList asideList--false asideListIndent--false ${showSideMenu ? "" : "closeSideMenuChild"}`}>
          <Button label={showSideMenu ? 'Log out' : ''} onClick={() => dispatch(logout('/login', history))}/>
        </li>
      </ul>
      <div className="asideMainFooter">
      </div>
    </aside>
  );
};
