import React, { useRef } from 'react';
import { Button } from './Button';

import './chatSend.css';
import PropTypes from 'prop-types';

export const ChatSend = ({onSend}) => {
  const textRef = useRef(null);
  const send = async () => {
    const value = textRef.current.value;
    if (await onSend(value) === true) {
      textRef.current.value = '';
    }
  };
  return (
    <div className="chatSend">
      <textarea rows="3" placeholder="Type here…" ref={textRef} onKeyUp={async (e) => {
        if (e.altKey && e.key === 'Enter') {
          await send();
        }
      }}/>
      <Button label="Send" primary={true} onClick={send}/>
    </div>
  );
};

ChatSend.propTypes = {
  onSend: PropTypes.func,
};

ChatSend.defaultProps = {
  onSend: () => true,
};
