import {
  REQUEST_ASSET_ROOM,
  RECEIVE_ASSET_ROOM,
} from '../actions/asset_room';

export default function asset_room(state = {}, action) {
  const {type, ...data} = action;
  switch (type) {
    case REQUEST_ASSET_ROOM:
    case RECEIVE_ASSET_ROOM:
      return {
        ...state,
        ...data,
      };
    default:
      return state;
  }
};
