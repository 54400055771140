import React, { useCallback, useEffect, useState } from 'react';

import { ButtonH1Side } from '../../parts/ButtonH1Side';
import { Card } from '../../parts/Card';
import { ListRequest } from '../../parts/ListRequest';
import { PageNavi } from '../../parts/PageNavi';

import { useDispatch, useSelector } from 'react-redux';
import { findNcRequestByLicensee, requestNcd, updateNcd } from '../../actions/nc_request';
import { loadLicenseeCounts } from '../../actions/dashboard';
import { ref, useQuery } from '../../parts/refs';
import {Search} from '../../parts/Search';
import {InputSelect} from '../../parts/InputSelect';
import {DataDevelopmentStage, DataDiseaseArea, DataModality, DataRoute} from '../../reducers/datas';
import { SortBy } from '../../parts/SortBy';

import '../base.css';
import { RequestPopups, useCdaControl, useNcdControl } from '../../parts/RequestPopups';
import { requestCda } from '../../actions/cda_request';
import { useTitle } from '../../parts/useTitle';
import { Link } from "react-router-dom";
import TutorialImg from '../../parts/assets/tutorial_ncd.png';

export const NcdRequest = () => {
  useTitle('NCD request');

  const dispatch = useDispatch();
  const query = useQuery();
  const page = parseInt(query.get('page') || '0');

  const boolAll = query.get('status') ? false : true;
  const status = query.get('status') || 'all';

  useEffect(() => {
    dispatch(findNcRequestByLicensee(query));
  }, [dispatch, query]);

  const [ncRequestsByLicensee, totalCount, total, user] = useSelector(s => [s.nc_request.ncRequestsByLicensee || [], s.nc_request.totalCount || 0, s.nc_request.total || 1, s.core.user]);

  const newCallback = useCallback(async (assetId, licenseeOrgId, comment) => {
    return await dispatch(requestNcd(assetId, licenseeOrgId, comment));
  }, [dispatch]);

  const updateCallback = useCallback(async (ncId, newStatus, comment, reasons) => {
    return await dispatch(updateNcd(ncId, newStatus, comment, reasons));
  }, [dispatch]);

  const reloadCallback = useCallback(() => {
    dispatch(findNcRequestByLicensee(query));
    dispatch(loadLicenseeCounts());
  }, [dispatch, query]);

  const [showStatusChange, popupControl] = useNcdControl(
    user.organizationType,
    newCallback,
    updateCallback,
    reloadCallback,
  );

  const onNewCda = useCallback(async (assetId, licenseeOrgId, comment) => {
    return await dispatch(requestCda(assetId, licenseeOrgId, comment));
  }, [dispatch]);

  const [showCdaChange, cdaControl] = useCdaControl(user.organizationType, onNewCda, () => {}, reloadCallback);

  const pForms = {};
  const resolveParams = () => {
    const params = {};

    params.route = pForms.route.value;
    params.modality = pForms.modality.value;
    params.developmentStage = pForms.developmentStage.value;
    params.diseaseArea = pForms.diseaseArea.value;

    return params;
  };

  /* 開閉状態保持バージョン
  var isOpen = useSelector(s => !!s.core.isSelectorOpen);
  const onOpenClose = () => {
    dispatch(setSelectorOpen(!isOpen));
  };*/

  var [isOpen, setIsOpen] = useState(false);
  const onOpenClose = () => {
    setIsOpen(!isOpen);
  };
  
  var [tutorialBoolOpen, setTutorialBoolOpen] = useState(true);
  const tutorialOpenClose = (b) => {
    setTutorialBoolOpen(tutorialBoolOpen = b);
  };

  return (
    <div id="container" className={`layout-login contsRequest totalCount_${totalCount}`}>

      <main>
        <h1>NCD request<span className="h1attn">NCD: Non-confidential Document</span></h1>
        <div id='containerMain'>
          {(!totalCount && tutorialBoolOpen) && 
            <div className='tutorial'>
              <img src={TutorialImg} alt='tutorial'></img>
              <div className='tutorialMain'>
                <p>You can see all your NCD request history here.<br/>Requests are categorized by their current status.</p>
                <Link to={`/licensee/assets`} className='tutorialLink'>Asset search&nbsp;&nbsp;&gt;</Link>
              </div>
              <button className='tutorialClose' onClick={() => tutorialOpenClose(false)}></button>
            </div>
          }
        <ButtonH1Side selected={status} boolAll={boolAll} organizationType='licensee'
                                     valueGroup={[
                                       {label: 'All', value: 'all', url: '/licensee/ncd_requests'},
                                       {label: 'New Request', value: 'newRequest', url: '/licensee/ncd_requests?status=newRequest', tooltip: 'New NCD request'},
                                       {label: 'Confirming', value: 'confirming', url: '/licensee/ncd_requests?status=confirming', tooltip: 'NCD availability confirming by Gemseki'},
                                       {label: 'Declined by Originator', value: 'declinedByOriginator', url: '/licensee/ncd_requests?status=declinedByOriginator', tooltip: 'Requests declined by the originator'},
                                       {label: 'Approved by Originator', value: 'approvedByOriginator', url: '/licensee/ncd_requests?status=approvedByOriginator', tooltip: 'NCD reviewing by applicant (you)'},
                                       {label: 'Pending', value: 'pending', url: '/licensee/ncd_requests?status=pending', tooltip: 'Requests put on hold by applicant (you)'},
                                       {label: 'Declined by Licensee', value: 'declinedByLicensee', url: '/licensee/ncd_requests?status=declinedByLicensee', tooltip: 'Requests declined by applicant (you)'},
                                     ]}/>
        <div className={['searchSortBox', `searchSortOpen--${isOpen}`].join(' ')}>
          <h2 onClick={() => onOpenClose()}>Search / Sort</h2>
          <div className="searchSortBody">
            <Search selectType="request" resolveParams={resolveParams}>
              <InputSelect name="route" label="Route" ref={ref(pForms)} value={query.get('route')} placeholder="..." valueGroup={DataRoute}/>
              <InputSelect name="modality" label="Modality" ref={ref(pForms)} value={query.get('modality')} placeholder="..." valueGroup={DataModality}/>
              <InputSelect name="developmentStage" label="Development Stage" ref={ref(pForms)} value={query.get('developmentStage')} placeholder="..." valueGroup={DataDevelopmentStage}/>
              <InputSelect name="diseaseArea" label="Disease Area" ref={ref(pForms)} value={query.get('diseaseArea')} placeholder="..." valueGroup={DataDiseaseArea}/>
            </Search>
            <SortBy selected={0}/>
          </div>
        </div>
        <div className="cardBox">
          {ncRequestsByLicensee.map(o => {
            return (
              <Card size="request" key={`cardRow:${o._id}`}>
                <ListRequest
                  user="Licensee"
                  mode="NCD"
                  requestData={o}
                  onChangeStatus={(newStatus) => showStatusChange(user.organizationId, o?.assetId, o?._id, newStatus)}
                  onClickNewCda={() => showCdaChange(user?.organizationId, o?.assetId, '', {value: 'newRequest'})}
                >
                  {o?.note ? o?.note : o?.summary}
                </ListRequest>
              </Card>
            );
          })}
          {!ncRequestsByLicensee?.length && (
            <Card>
              <div className="cardCenter">No requests.</div>
            </Card>
          )}
        </div>
        <PageNavi selected={page} total={total}/>
        </div>
      </main>
      <RequestPopups {...popupControl}/>
      <RequestPopups {...cdaControl}/>
    </div>
  );
};
