import React, { useEffect , useRef } from 'react';
import { useHistory, useParams } from 'react-router';

import { ButtonBack } from '../../parts/ButtonBack';
import { Button } from '../../parts/Button';
import { MemberList } from '../../parts/MemberList';
import { InputSelect } from '../../parts/InputSelect';
import { Popup } from '../../parts/Popup';

import '../base.css';
import { useDispatch, useSelector } from 'react-redux';
import { getLicensee, getMembers, updateOrganizationStatus } from '../../actions/core';
import { fd } from '../../parts/refs';
import { useTitle } from '../../parts/useTitle';
import { Card } from '../../parts/Card';

export const LicenseeDetail = () => {
  useTitle('Licensee details');

  const {licenseeId} = useParams();
  const dispatch = useDispatch();

  const statusRef = useRef(null);
  const popupValid = useRef(null);
  const popupInvalid = useRef(null);

  useEffect(() => {
    dispatch(getLicensee(licenseeId));
    dispatch(getMembers(licenseeId));
  }, [dispatch, licenseeId]);

  const [licensee, members] = useSelector(s => [s.core.licensee, s.core.members || []]);
  const status = licensee?.status;

  const history = useHistory();

  const onValid = async () => {
    await dispatch(updateOrganizationStatus(licenseeId, 'valid'));
    popupValid.current.hide();
  };

  const onInvalid = async () => {
    await dispatch(updateOrganizationStatus(licenseeId, 'invalid'));
    popupInvalid.current.hide();
  };

  const onCancel = () => {
    statusRef.current.value = status;
  };

  return (
    <div id="container" className="layout-login contsLicenseeDetail">
      <main>
        <h1><ButtonBack/>Licensee details</h1>
        <div id='containerMain'>
        <div className="accountBody">
        <InputSelect
          name="licenseeOrgStatus"
          size="185px"
          placeholder="..."
          ref={statusRef}
          isClearable={false}
          value={status}
          valueGroup={[{label: 'Valid', value: 'valid'},{label: 'Invalid', value: 'invalid'}]}
          onChange={(item) => {
            if (item?.value === status) return;
            if (item?.value === 'invalid') {
              popupInvalid.current.show();
            } else {
              popupValid.current.show();
            }
          }}/>
        <Card>
          <table className="tableGray tableGrayThVertical">
            <tbody>
            <tr>
              <th><label className="tableGrayLabel">Date (Company Registered)</label></th>
              <td><p>{fd(licensee?.createdAt)}</p></td>
              <th><label className="tableGrayLabel">Date (Company Updated)</label></th>
              <td><p>{fd(licensee?.updatedAt)}</p></td>
            </tr>
            <tr>
              <th><label className="tableGrayLabel">Company ID</label></th>
              <td><p>{licensee?.displayId}</p></td>
              <th><label className="tableGrayLabel">Company</label></th>
              <td><p>{licensee?.organizationName}</p></td>
            </tr>
            <tr>
              <th><label className="tableGrayLabel">Area</label></th>
              <td><p>{licensee?.area}</p></td>
              <th><label className="tableGrayLabel">Country</label></th>
              <td><p>{licensee?.country}</p></td>
            </tr>
            <tr>
              <th><label className="tableGrayLabel">Type of Company</label></th>
              <td colSpan="3"><p>{licensee?.typeOfCompany?.join('　　')}</p></td>
            </tr>
            <tr>
              <th><label className="tableGrayLabel">Licensing Criteria (Disease Area)</label></th>
              <td colSpan="3"><p>{licensee?.diseaseArea?.join('　　')}</p></td>
            </tr>
            <tr>
              <th><label className="tableGrayLabel">Licensing Criteria (Route)</label></th>
              <td colSpan="3"><p>{licensee?.route?.join('　　')}</p></td>
            </tr>
            <tr>
              <th><label className="tableGrayLabel">Licensing Criteria (Modality)</label></th>
              <td colSpan="3"><p>{licensee?.modality?.join('　　')}</p></td>
            </tr>
            <tr>
              <th><label className="tableGrayLabel">Licensing Criteria<br/>(Development Stage)</label></th>
              <td colSpan="3"><p>{licensee?.developmentStage?.join('　　')}</p></td>
            </tr>
            <tr>
              <th><label className="tableGrayLabel">Licensing Criteria (Territory)</label></th>
              <td colSpan="3"><p>{licensee?.territoryForInLicensing?.join('　　')}</p></td>
            </tr>
            <tr>
              <th><label className="tableGrayLabel">Specific Requirements</label></th>
              <td colSpan="3"><p>{licensee?.licensingCriteria}</p></td>
            </tr>
            <tr>
              <th><label className="tableGrayLabel">Website</label></th>
              <td colSpan="3"><p><a href={licensee?.url} target="organization_url">{licensee?.url}</a></p></td>
            </tr>
            </tbody>
          </table>
          </Card>
          <div className="buttonBoxL">
            <Button label="Edit" size="111px" onClick={() => history.push(`/gemseki/licensee/${licensee?._id}/edit`)}/>
          </div>
        </div>
        <div className="accountBody">
          <h2>Member list</h2>
          <Card>
          <MemberList editMode={false} isLinkToMember={true} organization={licensee} valueGroup={members}/>
          </Card>
          <div className="buttonBoxL">
            <Button label="Edit" size="111px" onClick={() => history.push(`/gemseki/licensee/${licenseeId}/member`)}/>
          </div>
        </div>
        </div>
      </main>
      <Popup onSubmit={onValid} onCancel={onCancel} title="Are you sure you would like to make this Licensee valid?" submitButton="OK" ref={popupValid} titleAlign="center">
        <p>Once become valid, Licensee can sign in.</p>
      </Popup>
      <Popup onSubmit={onInvalid} onCancel={onCancel} title="Are you sure you would like to make this Licensee invalid?" submitButton="OK" ref={popupInvalid} titleAlign="center">
        <p>Once become invalid, Licensee will not be able to sign in. </p>
      </Popup>
    </div>
  );
};
