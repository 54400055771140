import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import './buttonH1Side.css';

export const ButtonH1Side = React.forwardRef(({
                                                selected,
                                                valueGroup,
                                                onSelected,
                                                boolAll,
                                                organizationType,
                                              }, ref) => {

  var [tipsOpenNum, setTipsOpenNum] = useState(0);
  const tipsOpenClose = (n) => {
    setTipsOpenNum(tipsOpenNum = n);
  };

  return (
    <ul
      className={`buttonH1Side boolAll--${boolAll} TipsOpen--${tipsOpenNum}`}
      ref={ref}
    >
      {valueGroup?.map((values, i) => (
        <li key={`ButtonH1Side:${values.value}`}>
          {
            (organizationType === 'licensee' && values.tooltip)
              ? 
                onSelected
                  ? <div className={`buttonH1Side--${values.value} buttonH1SideLinkActive--${(values.value === selected)}`} onClick={() => onSelected(values.value)} onMouseOver={() => tipsOpenClose(i)} onMouseOut={() => tipsOpenClose(0)}>{values.label}</div>
                  : <Link to={values.url} className={`buttonH1Side--${values.value} buttonH1SideLinkActive--${(values.value === selected)}`} onMouseOver={() => tipsOpenClose(i)} onMouseOut={() => tipsOpenClose(0)}>{values.label}</Link>
              :
                onSelected
                  ? <div className={`buttonH1Side--${values.value} buttonH1SideLinkActive--${(values.value === selected)}`} onClick={() => onSelected(values.value)}>{values.label}</div>
                  : <Link to={values.url} className={`buttonH1Side--${values.value} buttonH1SideLinkActive--${(values.value === selected)}`}>{values.label}</Link>
          }
          {
            (organizationType === 'licensee' && values.tooltip)
              && <div className='H1SideTips'>{values.tooltip}</div>
          }
        </li>
      ))}
    </ul>
  );
});

ButtonH1Side.propTypes = {
  /**
   * 選択されている項目
   */
  selected: PropTypes.string,
  /**
   * チェックリストの配列
   */
  valueGroup: PropTypes.array,
  /**
   * 選択コールバック
   */
  onSelected: PropTypes.func,
  /**
   * Allかどうか
   */
   boolAll: PropTypes.bool,
  /**
   * gemseki or licensee
   */
   organizationType: PropTypes.oneOf(['gemseki', 'licensee']),
};

ButtonH1Side.defaultProps = {
  selected: 'value1',
  boolAll: false,
  valueGroup: [
    {label: 'label1', value: 'value1', url: '/'},
    {label: 'label2', value: 'value1', url: '/'},
    {label: 'label3', value: 'value1', url: '/'},
  ],
  organizationType: 'gemseki',
};
