import { Route, Switch } from 'react-router-dom';
import { Dashboard } from './gemseki/Dashboard';
import { MessageList } from './gemseki/MessageList';
import { CdaRequest } from './gemseki/CdaRequest';
import { NcdRequest } from './gemseki/NcdRequest';
import { ActivePromotion } from './gemseki/ActivePromotion';
import { AssetSearch } from './gemseki/AssetSearch';
import { AssetAdd } from './gemseki/AssetAdd';
import { LicenseeSearch } from './gemseki/LicenseeSearch';
import { OriginatorSearch } from './gemseki/OriginatorSearch';
import { Analytics } from './gemseki/Analytics';
import { ProfileSettings } from './gemseki/ProfileSettings';
import { MessageDetail } from './gemseki/MessageDetail';
import { AssetDetail } from './gemseki/AssetDetail';
import { LicenseeDetail } from './gemseki/LicenseeDetail';
import { LicenseeAdd } from './gemseki/LicenseeAdd';
import { OriginatorDetail } from './gemseki/OriginatorDetail';
import { OriginatorAdd } from './gemseki/OriginatorAdd';
import { AssetEdit } from './gemseki/AssetEdit';
import { AssetDetailCda } from './gemseki/AssetDetailCda';
import { AssetDetailNcd } from './gemseki/AssetDetailNcd';
import { LicenseeEdit } from './gemseki/LicenseeEdit';
import { OriginatorEdit } from './gemseki/OriginatorEdit';
import { LicenseeProfile } from './gemseki/LicenseeProfile';
import { LicenseeProfileEdit } from './gemseki/LicenseeProfileEdit';
import { OrganizationEdit } from './gemseki/OrganizationEdit';
import { Members } from './gemseki/Members';
import { MemberEntry } from './gemseki/MemberEntry';
import { ProfileEdit } from './gemseki/ProfileEdit';
import { ChangePassword } from './ChangePassword';
import { MenuPlot } from '../parts/Menu';
import { AsideGemseki } from '../parts/AsideGemseki';
import { ErrorMessages, useClearMessages } from '../parts/ErrorMessages';
import { OriginatorMembers } from './gemseki/OriginatorMembers';
import { LicenseeMembers } from './gemseki/LicenseeMembers';
import { OriginatorProfile } from './gemseki/OriginatorProfile';
import { OriginatorProfileEdit } from './gemseki/OriginatorProfileEdit';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { useEffect } from 'react';
import { loadGemsekiCounts } from '../actions/dashboard';
import { redirectToLogin } from '../actions/core';

export const RoutesGemseki = () => {
  const [loginError, user] = useSelector(s => [s.core.loginError, s.core.user]);
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    if (loginError) {
      dispatch(redirectToLogin(history, location));
    } else if (user?.organizationType === 'licensee') {
      history.replace('/licensee');
    } else if (user?._id) {
      dispatch(loadGemsekiCounts());
    }
  }, [dispatch, history, location, loginError, user]);

  useClearMessages();

  return !loginError && (
    <div>
      <Switch>
        <Route path="/gemseki/rooms">
          <MenuPlot menuId="202"/><MessageList/>
        </Route>
        <Route path="/gemseki/room/:roomId">
          <MenuPlot menuId="202"/><MessageDetail/>
        </Route>
        <Route path="/gemseki/cda_requests">
          <MenuPlot menuId="203"/><CdaRequest/>
        </Route>
        <Route path="/gemseki/ncd_requests">
          <MenuPlot menuId="204"/><NcdRequest/>
        </Route>
        <Route path="/gemseki/active_promotions">
          <MenuPlot menuId="205"/><ActivePromotion/>
        </Route>
        <Route path="/gemseki/assets">
          <MenuPlot menuId="206"/><AssetSearch/>
        </Route>
        <Route path="/gemseki/asset/:assetId/edit">
          <MenuPlot menuId="206"/><AssetEdit/>
        </Route>
        <Route path="/gemseki/asset/:assetId/cda">
          <MenuPlot menuId="206"/><AssetDetailCda/>
        </Route>
        <Route path="/gemseki/asset/:assetId/ncd">
          <MenuPlot menuId="206"/><AssetDetailNcd/>
        </Route>
        <Route path="/gemseki/asset/:assetId">
          <MenuPlot menuId="206"/><AssetDetail/>
        </Route>
        <Route path="/gemseki/asset_add">
          <MenuPlot menuId="207"/><AssetAdd/>
        </Route>
        <Route path="/gemseki/licensees">
          <MenuPlot menuId="208"/><LicenseeSearch/>
        </Route>
        <Route path="/gemseki/licensee/:licenseeId/edit">
          <MenuPlot menuId="208"/><LicenseeEdit/>
        </Route>
        <Route path="/gemseki/licensee/:licenseeId/member/:memberId/edit">
          <MenuPlot menuId="208"/><LicenseeProfileEdit/>
        </Route>
        <Route path="/gemseki/licensee/:licenseeId/member/:memberId">
          <MenuPlot menuId="208"/><LicenseeProfile/>
        </Route>
        <Route path="/gemseki/licensee/:licenseeId/member">
          <MenuPlot menuId="208"/><LicenseeMembers/>
        </Route>
        <Route path="/gemseki/licensee/:licenseeId">
          <MenuPlot menuId="208"/><LicenseeDetail/>
        </Route>
        <Route path="/gemseki/licensee_add">
          <MenuPlot menuId="208"/><LicenseeAdd/>
        </Route>
        <Route path="/gemseki/originators">
          <MenuPlot menuId="209"/><OriginatorSearch/>
        </Route>
        <Route path="/gemseki/originator/:originatorId/edit">
          <MenuPlot menuId="209"/><OriginatorEdit/>
        </Route>
        <Route path="/gemseki/originator/:originatorId/member/:memberId/edit">
          <MenuPlot menuId="209"/><OriginatorProfileEdit/>
        </Route>
        <Route path="/gemseki/originator/:originatorId/member/:memberId">
          <MenuPlot menuId="209"/><OriginatorProfile/>
        </Route>
        <Route path="/gemseki/originator/:originatorId/member">
          <MenuPlot menuId="209"/><OriginatorMembers/>
        </Route>
        <Route path="/gemseki/originator/:originatorId">
          <MenuPlot menuId="209"/><OriginatorDetail/>
        </Route>
        <Route path="/gemseki/originator_add">
          <MenuPlot menuId="209"/><OriginatorAdd/>
        </Route>
        <Route path="/gemseki/statistics">
          <MenuPlot menuId="210"/><Analytics/>
        </Route>
        <Route path="/gemseki/profile/edit">
          <MenuPlot menuId="213"/><ProfileEdit/>
        </Route>
        <Route path="/gemseki/profile">
          <MenuPlot menuId="213"/><ProfileSettings/>
        </Route>
        <Route path="/gemseki/organization_edit">
          <MenuPlot menuId="213"/><OrganizationEdit/>
        </Route>
        <Route path="/gemseki/member">
          <MenuPlot menuId="213"/><Members/>
        </Route>
        <Route path="/gemseki/member_entry">
          <MenuPlot menuId="213"/><MemberEntry/>
        </Route>
        <Route path="/gemseki/change_password">
          <MenuPlot menuId="213"/><ChangePassword/>
        </Route>
        <Route path="/gemseki">
          <MenuPlot menuId="201"/><Dashboard/>
        </Route>
      </Switch>
      <AsideGemseki kind="Gemseki"/>
      <ErrorMessages/>
    </div>
  );
};
