import React, { useEffect } from 'react';
import { useHistory } from 'react-router';

import { MemberList } from '../../parts/MemberList';
import { Button } from '../../parts/Button';
import { Card } from '../../parts/Card';

import '../base.css';
import { useDispatch, useSelector } from 'react-redux';
import { fd } from '../../parts/refs';
import { loadProfiles } from '../../actions/core';
import { useTitle } from '../../parts/useTitle';

export const ProfileSettings = () => {
  useTitle('Account settings');

  const dispatch = useDispatch();

  const [user, organization, members] = useSelector(s => [s.core.user, s.core.organization, s.core.members]);

  useEffect(() => {
    dispatch(loadProfiles());
  }, [dispatch]);

  const history = useHistory();
  return (
    <div id="container" className="layout-login contsProfileSettings">

      <main>
        <h1>Account settings</h1>
        <div id="containerMain">
        <div className="accountBody">
          <h2>User Account</h2>
          <Card>
          <table className="tableGray tableGrayThVertical">
            <tbody>
            <tr>
              <th><label className="tableGrayLabel">Registration Date</label></th>
              <td><p>{fd(user?.createdAt)}</p></td>
              <th><label className="tableGrayLabel">Update Date</label></th>
              <td><p>{fd(user?.updatedAt)}</p></td>
            </tr>
            <tr>
              <th><label className="tableGrayLabel">User Name</label></th>
              <td><p>{user?.firstName} {user?.lastName}</p></td>
              <th><label className="tableGrayLabel">Email address</label></th>
              <td><p><a href={`mailto:${user?.email}`} target="mail">{user?.email}</a></p></td>
            </tr>
            </tbody>
          </table>
          <div className="buttonBoxL">
            <Button label="Edit" size="111px" onClick={() => history.push('/gemseki/profile/edit')}/>
            <Button label="Change password" size="221px" onClick={() => history.push('/gemseki/change_password')}/>
          </div>
          </Card>
        </div>
        <div className="accountBody">
          <h2>Corporate Account</h2>
          <Card>
          <table className="tableGray tableGrayThVertical">
            <tbody>
            <tr>
              <th><label className="tableGrayLabel">Date (Company Registered)</label></th>
              <td><p>{fd(organization?.createdAt)}</p></td>
              <th><label className="tableGrayLabel">Date (Company Updated)</label></th>
              <td><p>{fd(organization?.updatedAt)}</p></td>
            </tr>
            <tr>
              <th><label className="tableGrayLabel">Company ID</label></th>
              <td><p>{organization?.displayId}</p></td>
              <th><label className="tableGrayLabel">Company</label></th>
              <td><p>{organization?.organizationName}</p></td>
            </tr>
            </tbody>
          </table>
          <div className="buttonBoxL">
            <Button label="Edit" size="111px" onClick={() => history.push('/gemseki/organization_edit')}/>
          </div>
          </Card>
        </div>
        <div className="accountBody">
          <h2>Member list</h2>
          <Card>
            <MemberList editMode={false} organization={organization} valueGroup={members}/>
            <div className="buttonBoxL">
              <Button label="Edit &amp; invite members" size="257px" onClick={() => history.push('/gemseki/member')}/>
            </div>
          </Card>
        </div>
        </div>
      </main>
    </div>
  );
};
