import React from 'react';
import PropTypes from 'prop-types';
import {useHistory} from 'react-router';
import './button.css';

/**
 * Primary UI component for user interaction
 */
export const Button = ({
  primary,
  backgroundColor,
  type,
  size,
  label,
  to,
  disabled,
  onClick,
  ...props
}) => {
  const mode = type==="" ? 'button--primary' : `button--${type}`;
  const history = useHistory();
  return (
    <div className={['button', mode , `buttonSize--${size}`].join(' ')} style={{width:size}}>
      <button
        type="button"
        disabled={disabled}
        onClick={(e) => to ? history.push(to) : onClick ? onClick(e) : false}
        {...props}
      >
        {label}
      </button>
    </div>
  );
};

Button.propTypes = {
  /**
   * Is this the principal call to action on the page?
   */
  primary: PropTypes.bool,
  /**
   * What background color to use
   */
  backgroundColor: PropTypes.string,
  /**
   * Type?
   */
  type: PropTypes.string,
  /**
   * How large should the button be?
   */
  size: PropTypes.string,
  /**
   * Button contents
   */
  label: PropTypes.string.isRequired,
  /**
   * Optional link path
   */
  to: PropTypes.string,
  /**
   * Optional click handler
   */
  disabled: PropTypes.bool,
  /**
   * Optional click handler
   */
  onClick: PropTypes.func,
};

Button.defaultProps = {
  backgroundColor: null,
  primary: false,
  size: 'medium',
  disabled: false,
  onClick: () => {},
};
