import React, { useEffect, useMemo } from 'react';

import { SearchTab } from '../../parts/SearchTab';
import { Search } from '../../parts/Search';
import { Button } from '../../parts/Button';
import { PageNavi } from '../../parts/PageNavi';
import { InputSelect } from '../../parts/InputSelect';
import { InputMultiSelect } from '../../parts/InputMultiSelect';
import ScrollContainer from 'react-indiana-drag-scroll'

import '../base.css';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fd, ref, useQuery } from '../../parts/refs';
import { downloadFileByGemseki, findAssetsByGemseki } from '../../actions/asset';
import { useTitle } from '../../parts/useTitle';
import { DataDevelopmentStage, DataDiseaseArea, DataModality, DataRoute } from '../../reducers/datas';
import { useListOrder } from "../../parts/useListOrder";
import { getGemsekiUsers } from "../../actions/core";
import { AssetMarks } from "../../parts/AssetMarks";

export const AssetSearch = () => {
  const location = useLocation();
  const hash = location.hash || '';

  useTitle(() => hash === '#tech' ? 'Asset search Technologies' : 'Asset search Drug Candidates', [hash, location.search]);

  const [
    updatedAtOrder,
    assetNumberOrder,
    routeOrder,
    modalityOrder,
    developmentStageOrder,
    diseaseAreaOrder,
    gemsekiPicOrder,
    statusOrder,
  ] = useListOrder(
    -1,
    'updatedAt',
    'assetNumber',
    'route',
    'modality',
    'developmentStage',
    'diseaseArea',
    'gemsekiPic',
    'status'
  );

  const dispatch = useDispatch();
  const assetType = hash === '#tech' ? 'technologies' : 'seeds';
  const query = useQuery();
  const page = parseInt(query.get('page') || '0');

  useEffect(() => {
    dispatch(getGemsekiUsers());
    dispatch(findAssetsByGemseki(query, assetType));
  }, [dispatch, query, assetType]);

  const [gemsekiUsers, assetsTotal, assets, total] = useSelector(s => [s.core.gemsekiUsers || [], s.asset.assetsTotal || 0, s.asset.assets || [], s.asset.total || 1]);
  const gemsekiValueGroup = useMemo(() => {
    const valueGroup = gemsekiUsers.map(g => ({label: `${g.firstName} ${g.lastName}`, value: g._id}));
    valueGroup.sort((l, r) => l.label.localeCompare(r.label));
    return valueGroup;
  }, [gemsekiUsers]);
  const gemsekiName = (id) => {
    const user = gemsekiUsers.find(u => u._id === id);
    return user ? `${user.firstName} ${user.lastName}` : '';
  };

  if (hash === '#tech') {
    const pForms = {};
    const resolveParams = () => {
      const params = {};

      params.gemsekiPic = pForms.gemsekiPic.value;
      params.status = pForms.status.value;

      return params;
    };
    return (
      <div id="container" className="layout-login contsAssetSearch">
        <main>
          <h1>Asset search</h1>
          <div className="tabbox">
            <SearchTab selected="Tech"
                       valueGroup={[
                         {label: 'Drug Candidates', value: 'Seeds', url: '/gemseki/assets'},
                         {label: 'Technologies', value: 'Tech', url: '/gemseki/assets#tech'},
                       ]}/>
            <div className="tabcontent-area">
              <div className="tabcontent">
                <Search selectType="gemseki" resolveParams={resolveParams}>
                  <InputSelect name="gemsekiPic" label="PM" ref={ref(pForms)} value={query.get('gemsekiPic')} placeholder="..." valueGroup={gemsekiValueGroup}/>
                  <InputSelect name="status" label="Public/Private" ref={ref(pForms)} value={query.get('status')} placeholder="..." valueGroup={[{label: 'Public', value: 'public'}, {label: 'Private', value: 'private'}]}/>
                </Search>
                <div className="searchListbox">
                  <div className="csvDownload">
                    <Button onClick={() => dispatch(downloadFileByGemseki(assetType, new URLSearchParams(location.search)))} label="Excel download" size="170px"/>
                    <div className="csvDownloadPages">Total {assetsTotal} Assets</div>
                  </div>
                  <div className="searchListboxInFix">
                    <ScrollContainer className="scroll-container" hideScrollbars="false" vertical="false">
                      <table className="tableGray tableGrayGemseki tableGrayThHorizontal">
                        <thead>
                        <tr>
                          <th className={updatedAtOrder.className} onClick={() => updatedAtOrder.onSwitch()}>Date <span style={{'display': 'block', whiteSpace: 'nowrap'}}>(Updated)</span></th>
                          <th className={statusOrder.className} onClick={() => statusOrder.onSwitch()}>Public/Private</th>
                          <th className={assetNumberOrder.className} onClick={() => assetNumberOrder.onSwitch()}>Asset No.</th>
                          <th style={{maxWidth: "180px", minWidth: "180px"}} className={gemsekiPicOrder.className} onClick={() => gemsekiPicOrder.onSwitch()}>PM</th>
                          <th>Technology</th>
                          <th>Summary</th>
                        </tr>
                        </thead>
                        <tbody>
                        {assets.map(o => {
                          return (
                            <tr key={`techRow:${o._id}`}>
                              <td>{fd(o.updatedAt)}</td>
                              <td>{o.status === 'public' ? 'Public' : 'Private'}</td>
                              <td><AssetMarks marks={o.marks}/><Link to={`/gemseki/asset/${o._id}`} className="tableID">{o.assetNumber} &gt;</Link></td>
                              <td style={{maxWidth: "180px", minWidth: "180px"}}>{o.gemsekiPic?.map(gemsekiName)?.map(l => <span key={l}>{l}</span>)}</td>
                              <td>
                                <div className='tableBlockSS'>{o.technology}</div>
                              </td>
                              <td>
                                <div className='tableBlockM'>{o.summary?.length > 140 ? o.summary?.substring(0, 140) + '...' : o.summary}</div>
                              </td>
                            </tr>
                          );
                        })}
                        {!assets?.length && (
                          <tr>
                            <td colSpan={5}>
                              <div className="tableCenter">No results.</div>
                            </td>
                          </tr>
                        )}
                        </tbody>
                      </table>
                    </ScrollContainer>
                  </div>
                  <PageNavi selected={page} total={total}/>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    );
  } else {
    const pForms = {};
    const resolveParams = () => {
      const params = {};

      params.gemsekiPic = pForms.gemsekiPic.value;
      params.status = pForms.status.value;
      params.diseaseArea = pForms.diseaseArea.values.length !== 0 ? pForms.diseaseArea.values : undefined;
      params.route = pForms.route.values.length !== 0 ? pForms.route.values : undefined;
      params.modality = pForms.modality.values.length !== 0 ? pForms.modality.values : undefined;
      params.developmentStage = pForms.developmentStage.values.length !== 0 ? pForms.developmentStage.values : undefined;

      return params;
    };
    return (
      <div id="container" className="layout-login contsAssetSearch">
        <main>
          <h1>Asset search</h1>
          <div className="tabbox">
            <SearchTab selected="Seeds"
                       valueGroup={[
                         {label: 'Drug Candidates', value: 'Seeds', url: '/gemseki/assets'},
                         {label: 'Technologies', value: 'Tech', url: '/gemseki/assets#tech'},
                       ]}/>
            <div className="tabcontent-area">
              <div className="tabcontent">
                <Search selectType="gemseki-multi" resolveParams={resolveParams}>
                  <InputMultiSelect name="route" label="Route" ref={ref(pForms)} value={query.get('route')} placeholder="..." valueGroup={DataRoute} selectOnly={true}/>
                  <InputMultiSelect name="modality" label="Modality" ref={ref(pForms)} value={query.get('modality')} placeholder="..." valueGroup={DataModality} selectOnly={true}/>
                  <InputMultiSelect name="developmentStage" label="Development Stage" ref={ref(pForms)} value={query.get('developmentStage')} placeholder="..." valueGroup={DataDevelopmentStage} selectOnly={true}/>
                  <InputMultiSelect name="diseaseArea" label="Disease Area" ref={ref(pForms)} value={query.get('diseaseArea')} placeholder="..." valueGroup={DataDiseaseArea} selectOnly={true}/>
                  <InputSelect name="gemsekiPic" label="PM" ref={ref(pForms)} value={query.get('gemsekiPic')} placeholder="..." valueGroup={gemsekiValueGroup}/>
                  <InputSelect name="status" label="Public/Private" ref={ref(pForms)} value={query.get('status')} placeholder="..." valueGroup={[{label: 'Public', value: 'public'}, {label: 'Private', value: 'private'}]}/>
                </Search>
                <div className="searchListbox">
                  <div className="csvDownload">
                    <Button onClick={() => dispatch(downloadFileByGemseki(assetType, new URLSearchParams(location.search)))} label="Excel download" size="170px"/>
                    <div className="csvDownloadPages">Total {assetsTotal} Assets</div>
                  </div>
                  <div className="searchListboxIn">
                    <ScrollContainer className="scroll-container" hideScrollbars="false" vertical="false">
                      <table className="tableGray tableGrayGemseki tableGrayThHorizontal">
                        <thead>
                        <tr>
                          <th className={updatedAtOrder.className} onClick={() => updatedAtOrder.onSwitch()}>Date <span style={{'display': 'inline-block', whiteSpace: 'nowrap'}}>(Updated)</span></th>
                          <th className={statusOrder.className} onClick={() => statusOrder.onSwitch()}>Public/Private</th>
                          <th className={assetNumberOrder.className} onClick={() => assetNumberOrder.onSwitch()}>Asset No.</th>
                          <th style={{maxWidth: "180px", minWidth: "180px"}} className={gemsekiPicOrder.className} onClick={() => gemsekiPicOrder.onSwitch()}>PM</th>
                          <th>Mode of Action</th>
                          <th>Indication</th>
                          <th className={developmentStageOrder.className} onClick={() => developmentStageOrder.onSwitch()}>Development Stage</th>
                          <th className={routeOrder.className} onClick={() => routeOrder.onSwitch()}>Route</th>
                          <th className={modalityOrder.className} onClick={() => modalityOrder.onSwitch()}>Modality</th>
                          <th>Note</th>
                          <th style={{maxWidth: "180px", minWidth: "180px"}} className={diseaseAreaOrder.className} onClick={() => diseaseAreaOrder.onSwitch()}>Disease Area</th>
                        </tr>
                        </thead>
                        <tbody>

                        {assets.map(o => {
                          return (
                            <tr key={`techRow:${o._id}`}>
                              <td>{fd(o.updatedAt)}</td>
                              <td>{o.status === 'public' ? 'Public' : 'Private'}</td>
                              <td><AssetMarks marks={o.marks}/><Link to={`/gemseki/asset/${o._id}`} className="tableID">{o.assetNumber} &gt;</Link></td>
                              <td style={{maxWidth: "180px", minWidth: "180px"}}>{o.gemsekiPic?.map(gemsekiName)?.map(l => <span key={l}>{l}</span>)}</td>
                              <td>
                                <div className='tableBlockM'>{o.mechanismOfAction}</div>
                              </td>
                              <td>
                                <div className='tableBlockM' style={{minWidth: "180px"}}>{o.indication?.length > 140 ? o.indication?.substring(0, 140) + '...' : o.indication}</div>
                              </td>
                              <td>{o.developmentStage?.map(l => <span key={l}>{l}</span>)}</td>
                              <td>{o.route?.map(l => <span key={l}>{l}</span>)}</td>
                              <td>{o.modality?.map(l => <span key={l}>{l}</span>)}</td>
                              <td>
                                <div className='tableBlockM'>{o.note?.length > 140 ? o.note?.substring(0, 140) + '...' : o.note}</div>
                              </td>
                              <td style={{maxWidth: "180px", minWidth: "180px"}}>{o.diseaseArea?.map(l => <span key={l}>{l}</span>)}</td>
                            </tr>
                          );
                        })}
                        {!assets?.length && (
                          <tr>
                            <td colSpan={10}>
                              <div className="tableCenter">No results.</div>
                            </td>
                          </tr>
                        )}
                        </tbody>
                      </table>
                    </ScrollContainer>
                  </div>
                  <PageNavi selected={page} total={total}/>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    );
  }
};
