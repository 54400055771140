import { api } from './api.js';
import { clearError, defaultErrorHandler, isError } from './core';

export const REQUEST_CDA_REQUEST = 'REQUEST_CDA_REQUEST';
export const RECEIVE_CDA_REQUEST = 'RECEIVE_CDA_REQUEST';

const copyQuery = (original, ...keys) => {
  const query = new URLSearchParams();
  for (const key of keys) {
    if (original.has(key)) {
      query.set(key, original.get(key));
    }
  }
  return query;
};

export const findCdaRequestByLicensee = (query) => async (dispatch) => {
  const q = copyQuery(query, 'status', 'page', 'words', 'route', 'modality', 'developmentStage', 'diseaseArea', 'order', 'dir');

  q.set('limit', '4');

  const res = await api.get(dispatch, `cda_request/find_by_licensee?${q}`);
  if (isError(res, dispatch)) return;

  const totalCount = res.body.total;
  const total = Math.ceil(totalCount / 4);
  const cdaRequestsByLicensee = res.body.data;

  dispatch({
    type: RECEIVE_CDA_REQUEST,
    totalCount,
    total,
    cdaRequestsByLicensee,
  });
};

export const findCdaRequestByGemseki = (query) => async (dispatch) => {
  const q = copyQuery(query, 'status', 'page', 'words', 'developmentStage', 'diseaseArea', 'licenseeOrgId', 'originatorOrgId', 'order', 'dir');

  q.set('limit', '4');

  const res = await api.get(dispatch, `cda_request/find_by_gemseki?${q}`);
  if (isError(res, dispatch)) return;

  const total = Math.ceil(res.body.total / 4);
  const cdaRequestsByGemseki = res.body.data;

  dispatch({
    type: RECEIVE_CDA_REQUEST,
    total,
    cdaRequestsByGemseki,
  });
};

export const findCdaRequestByAssets = (query, assetId) => async (dispatch) => {
  const q = copyQuery(query, 'page');

  q.set('limit', '8');
  q.set('assetId', assetId);

  const res = await api.get(dispatch, `cda_request/find_by_asset?${q}`);
  if (isError(res, dispatch)) return;

  const total = Math.ceil(res.body.total / 8);
  const requestSummary = res.body.requestSummary;
  const cdaRequestsByAssets = res.body.data;

  dispatch({
    type: RECEIVE_CDA_REQUEST,
    total,
    requestSummary,
    cdaRequestsByAssets,
  });
};

const statusErrorHandler = (res, dispatch) => {
  if (res.status === 409 && res.body.conflict?.status) {
    return {ok: false, message: 'Update failed because the same NCD request already exists or status change is not allowed.'};
  } else {
    defaultErrorHandler(res, dispatch);
    return {ok: false, message: 'Failed to update status.'};
  }
};

export const requestCda = (assetId, licenseeOrgId, comment = '') => async (dispatch) => {
  dispatch(clearError());

  const res = await api.post(dispatch, `cda_request`, {assetId, licenseeOrgId, comment});
  return isError(res, dispatch, statusErrorHandler, () => ({ok: true}));
};

export const updateCda = (cdaId, status, comment = '', reasons = []) => async (dispatch) => {
  dispatch(clearError());

  const res = await api.put(dispatch, `cda_request/${cdaId}/status`, {status, reasons, comment});
  return isError(res, dispatch, statusErrorHandler, () => ({ok: true}));
};
