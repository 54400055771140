import './base.css';
import './changePassword.css';
import { ButtonBack } from '../parts/ButtonBack';
import { Button } from '../parts/Button';
import { Input1Line } from '../parts/Input1Line';
import { ref } from '../parts/refs';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { changePassword } from '../actions/core';
import { useTitle } from '../parts/useTitle';
import { Card } from '../parts/Card';

export const ChangePassword = () => {
  useTitle('Change password');

  const history = useHistory();
  const dispatch = useDispatch();

  const user = useSelector(s => s.core.user);
  const forms = {};
  const resolveData = () => {
    return {
      _id: user?._id,
      password: forms.password.value,
      newPassword: forms.newPassword.value,
      confirmPassword: forms.confirmPassword.value,
    };
  };
  return (
    <div id="container" className="layout-login contsChangePassword">
      <main>
        <h1><ButtonBack/>Change password</h1>
        <div id='containerMain'>
          <Card>
            <div className="inputBoxes">

              <label className="inputBoxLabel" htmlFor="input1Line-ID--password">Current password</label>
              <div className="inputBoxRight" style={{paddingBottom:"10px"}}><Input1Line placeholder="Type here…" name="password" ref={ref(forms)} type={'password'} size="460px" autoComplete="current-password"/></div>

              <label className="inputBoxLabel" htmlFor="input1Line-ID--newPassword">New password</label>
              <div className="inputBoxRight">
                <Input1Line placeholder="Type here…" name="newPassword" ref={ref(forms)} type={'password'} size="460px" autoComplete="new-password"/>
                <div className='inputBoxAttn'>※ 8 characters or more</div>
              </div>

              <label className="inputBoxLabel" htmlFor="input1Line-ID--confirmPassword">Confirm password</label>
              <div className="inputBoxRight"><Input1Line placeholder="Type here…" name="confirmPassword" ref={ref(forms)} type={'password'} size="460px"/></div>

            </div>

            <div className="buttonBoxCenter">
              <Button label="Cancel" size="150px" type="gray" onClick={() => history.goBack()}/>
              <Button label="Save" size="150px" onClick={() => dispatch(changePassword(resolveData(), history))}/>
            </div>
          </Card>
        </div>
      </main>
    </div>
  );
};
