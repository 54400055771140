export const areas = [
  'North America',
  'Central America',
  'South America',
  'Asia',
  'Europe',
  'Middle East',
  'Oceania',
  'Africa',
  'Global',
];

export const countries = {
  'North America': [
    'Bermuda',
    'Canada',
    'Greenland',
    'Saint Pierre and Miquelon',
    'US',
  ],
  'Central America': [
    'Belize',
    'Costa Rica',
    'El Salvador',
    'Guatemala',
    'Honduras',
    'Mexico',
    'Nicaragua',
    'Panama',
  ],
  'South America': [
    'Argentina',
    'Bolivia',
    'Brazil',
    'Chile',
    'Colombia',
    'Ecuador',
    'Falkland Islands (Malvinas)',
    'French Guiana',
    'Guyana',
    'Paraguay',
    'Peru',
    'Suriname',
    'Uruguay',
    'Venezuela',
  ],
  'Asia': [
    'Afghanistan',
    'Armenia',
    'Azerbaijan',
    'Bangladesh',
    'Bhutan',
    'Brunei Darussalam',
    'Cambodia',
    'China',
    'Georgia',
    'Hong Kong',
    'India',
    'Indonesia',
    'Japan',
    'Kazakhstan',
    'North Korea',
    'Korea',
    'Kyrgyzstan',
    'Laos',
    'Macao',
    'Malaysia',
    'Maldives',
    'Mongolia',
    'Myanmar (ex-Burma)',
    'Nepal',
    'Pakistan',
    'Philippines',
    'Singapore',
    'Sri Lanka (ex-Ceilan)',
    'Taiwan',
    'Tajikistan',
    'Thailand',
    'Timor Leste (West)',
    'Turkmenistan',
    'Uzbekistan',
    'Vietnam',
  ],
  'Europe': [
    'Albania',
    'Andorra',
    'Belarus',
    'Bosnia',
    'Croatia',
    'Faroe Islands',
    'Gibraltar',
    'Guernsey and Alderney',
    'Iceland',
    'Jersey',
    'Kosovo',
    'Liechtenstein',
    'Man, Island of',
    'Moldova',
    'Monaco',
    'Montenegro',
    'North Macedonia',
    'Norway',
    'Russia',
    'San Marino',
    'Serbia',
    'Svalbard and Jan Mayen Islands',
    'Switzerland',
    'Turkey',
    'Ukraine',
    'UK',
    'Vatican City State (Holy See)',
    'Austria',
    'Belgium',
    'Bulgaria',
    'Cyprus',
    'Czech Republic',
    'Denmark',
    'Estonia',
    'Finland',
    'France',
    'Germany',
    'Greece',
    'Hungary',
    'Ireland',
    'Italy',
    'Latvia',
    'Lithuania',
    'Luxembourg',
    'Malta',
    'Netherlands',
    'Poland',
    'Portugal',
    'Romania',
    'Slovakia',
    'Slovenia',
    'Spain',
    'Sweden',
    'Isle of Man',
  ],
  'Middle East': [
    'Bahrain',
    'Iraq',
    'Iran',
    'Israel',
    'Jordan',
    'Kuwait',
    'Lebanon',
    'Oman',
    'Palestine',
    'Qatar',
    'Saudi Arabia',
    'Syria',
    'UAE',
    'Yemen',
  ],
  'Oceania': [
    'Australia',
    'Fiji',
    'French Polynesia',
    'Guam',
    'Kiribati',
    'Marshall Islands',
    'Micronesia',
    'New Caledonia',
    'New Zealand',
    'Papua New Guinea',
    'Samoa',
    'Samoa, American',
    'Solomon, Islands',
    'Tonga',
    'Vanuatu',
  ],
  'Africa': [
    'Burundi',
    'Comoros',
    'Djibouti',
    'Eritrea',
    'Ethiopia',
    'Kenya',
    'Madagascar',
    'Malawi',
    'Mauritius',
    'Mayotte',
    'Mozambique',
    'Reunion',
    'Rwanda',
    'Seychelles',
    'Somalia',
    'Tanzania, United Republic of',
    'Uganda',
    'Zambia',
    'Zimbabwe',
    'Angola',
    'Cameroon',
    'Central African Republic',
    'Chad',
    'Congo (Brazzaville)',
    'Congo, Democratic Republic of the',
    'Equatorial Guinea',
    'Gabon',
    'Sao Tome and Principe',
    'Algeria',
    'Egypt',
    'Libyan Arab Jamahiriya',
    'Morocco',
    'South Sudan',
    'Sudan',
    'Tunisia',
    'Western Sahara',
    'Botswana',
    'Eswatini (ex-Swaziland)',
    'Lesotho',
    'Namibia',
    'South Africa',
    'Benin',
    'Burkina Faso',
    'Cape Verde',
    'Cote d\'Ivoire (Ivory Coast)',
    'Gambia',
    'Ghana',
    'Guinea',
    'Guinea-Bissau',
    'Liberia',
    'Mali',
    'Mauritania',
    'Niger',
    'Nigeria',
    'Saint Helena',
    'Senegal',
    'Sierra Leone',
    'Togo',
  ],
  'Global': [
    'Multiple',
  ],
};
