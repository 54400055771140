import React, { useEffect, useMemo } from 'react';

import { ButtonBack } from '../../parts/ButtonBack';
import { Input1Line } from '../../parts/Input1Line';
import { CheckBoxGroup } from '../../parts/CheckBoxGroup';
import { InputSelect } from '../../parts/InputSelect';
import { Button } from '../../parts/Button';
import { MemberAddUl } from '../../parts/MemberAddUl';
import { ref } from '../../parts/refs';
import { Card } from '../../parts/Card';

import '../base.css';
import { useDispatch, useSelector } from 'react-redux';
import { DataTypeOfCompanyOriginator } from '../../reducers/datas';
import { createOriginator, getGemsekiUsers, getIntroducers } from '../../actions/core';
import { useHistory } from 'react-router';
import { InputMultiSelect } from '../../parts/InputMultiSelect';
import { useCountries } from '../../parts/useCountries';
import { useTitle } from '../../parts/useTitle';

export const OriginatorAdd = () => {
  useTitle('Originator add');

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(getGemsekiUsers());
    dispatch(getIntroducers());
  }, [dispatch]);

  const [ge, ir] = useSelector(s => [s.core.gemsekiUsers || [], s.core.introducers || []]);
  const users = useMemo(() => ge.map(u => ({ label: `${u.firstName} ${u.lastName}`, value: u._id })), [ge]);
  const introducers = useMemo(() => ir.map(i => ({ label: i, value: i })), [ir]);

  const forms = {};
  const [areas, countries, areaCallback, countryRef] = useCountries();

  const resolveData = () => {
    return {
      organizationName: forms.organizationName.value,
      area: forms.area.value,
      country: countryRef.current.value,
      organizationType: 'originator',
      typeOfCompany: forms.typeOfCompany.values,
      msaInfo: forms.msaInfo.value,
      noteForOriginator: forms.noteForOriginator.value,
      url: forms.url.value,
      gemsekiPic: forms.gemsekiPic.values,
      introducer: forms.introducer.values || [],
      members: forms.members.values || [],
    };
  };

  return (
    <div id="container" className="layout-login contsOriginatorAdd">
      <main>
        <h1><ButtonBack/>Originator add</h1>
        <div id='containerMain'>
        <Card>

        <table className="tableGray tableGrayThVertical">
          <tbody>
          <tr>
            <th><label className="tableGrayLabel">Date (Company Registered)</label></th>
            <td><p>-</p></td>
            <th><label className="tableGrayLabel">Date (Company Updated)</label></th>
            <td><p>-</p></td>
          </tr>
          <tr>
            <th style={{paddingTop:"26px"}}><label className="tableGrayLabel">Company ID</label></th>
            <td style={{paddingTop:"26px"}}><p>-</p></td>
            <th style={{paddingTop:"26px"}}><label className="tableGrayLabel" htmlFor="input1Line-ID--organizationName">Company</label></th>
            <td><Input1Line name="organizationName" ref={ref(forms)} size="100%" placeholder="Type here…"/></td>
          </tr>
          <tr>
            <th style={{paddingTop:"26px"}}><label className="tableGrayLabel">Area, Country</label></th>
            <td colSpan="3">
              <div className="buttonBoxL">
                <InputSelect name="area" ref={ref(forms)} size="185px" placeholder="Area" valueGroup={areas} onChange={areaCallback}/>
                <InputSelect name="country" ref={countryRef} size="290px" placeholder="Country" valueGroup={countries}/>
              </div>
            </td>
          </tr>
          <tr>
            <th><label className="tableGrayLabel">Type of Company</label></th>
            <td colSpan="3">
              <CheckBoxGroup name="typeOfCompany" ref={ref(forms)} valueGroup={DataTypeOfCompanyOriginator}/>
            </td>
          </tr>
          <tr>
            <th style={{paddingTop:"26px"}}><label className="inputBoxLabel" htmlFor="input1Line-ID--msaInfo">MSA Info</label></th>
            <td colSpan="3"><textarea name="msaInfo" ref={ref(forms)} rows="5" placeholder="Type here…"/></td>
          </tr>
          <tr>
            <th style={{paddingTop:"26px"}}><label className="inputBoxLabel" htmlFor="input1Line-ID--noteForOriginator">Note for originator</label></th>
            <td colSpan="3"><textarea name="noteForOriginator" ref={ref(forms)} rows="5" placeholder="Type here…"/></td>
          </tr>
          <tr>
            <th style={{paddingTop:"26px"}}><label className="inputBoxLabel" htmlFor="input1Line-ID--url">Website</label></th>
            <td colSpan="3"><Input1Line name="url" ref={ref(forms)} size="500px" placeholder="Type here…"/></td>
          </tr>
          <tr>
            <th><label className="tableGrayLabel">PM</label></th>
            <td colSpan="3">
              <CheckBoxGroup name="gemsekiPic" ref={ref(forms)} valueGroup={users}/>
            </td>
          </tr>
          <tr>
            <th style={{paddingTop:"26px"}}><label className="inputBoxLabel" htmlFor="input1Line-ID--introducer">Introduced by</label></th>
            <td colSpan="3"><InputMultiSelect name="introducer" ref={ref(forms)} size="500px" placeholder="Type here…" valueGroup={introducers}/></td>
          </tr>
          </tbody>
        </table>
        <div className="accountBody originatorAddMembers">
          <h2>Member <Button label="＋" onClick={() => forms.members.addRow()}/></h2>
          <MemberAddUl name="members" ref={(c) => forms.members = c}/>
          <div className="buttonBoxCenter">
            <Button label="Cancel" size="150px" type="gray" onClick={() => history.goBack()}/>
            <Button label="Save" size="150px" onClick={() => dispatch(createOriginator(resolveData(), history))}/>
          </div>
        </div>
        </Card>
        </div>
      </main>
    </div>
  );
};
