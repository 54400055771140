import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router';

import { Button } from '../../parts/Button';
import { SearchTab } from '../../parts/SearchTab';
import { Search } from '../../parts/Search';
import { PageNavi } from '../../parts/PageNavi';
import { InputMultiSelect } from '../../parts/InputMultiSelect';
import ScrollContainer from 'react-indiana-drag-scroll'

import { useDispatch, useSelector } from 'react-redux';
import { findAssets, downloadFile, addAssetBag } from '../../actions/asset';
import { ref, useQuery } from '../../parts/refs';
import { DataDevelopmentStage, DataModality, DataDiseaseArea, DataRoute } from '../../reducers/datas';

import '../base.css';
import { useTitle } from '../../parts/useTitle';
import { useListOrder } from "../../parts/useListOrder";

import BagTipsImg from '../../parts/assets/icon_bag_tips.svg';
import { AssetMarks } from "../../parts/AssetMarks";

export const AssetSearch = () => {
  const location = useLocation();
  const hash = location.hash || '';

  useTitle(() => hash === '#tech' ? 'Asset search Technologies' : 'Asset search Drug Candidates', [hash, location.search]);

  const dispatch = useDispatch();
  const assetType = hash === '#tech' ? 'technologies' : 'seeds';
  const query = useQuery();
  const page = parseInt(query.get('page') || '0');

  const [
    assetNumberOrder,
    routeOrder,
    modalityOrder,
    developmentStageOrder,
    diseaseAreaOrder,
  ] = useListOrder(
    -1,
    'assetNumber',
    'route',
    'modality',
    'developmentStage',
    'diseaseArea',
  );

  useEffect(() => {
    dispatch(findAssets(query, assetType));
  }, [dispatch, query, assetType]);

  const [assets, assetsTotal, total, bagIds] = useSelector(s => [s.asset.assets || [], s.asset.assetsTotal || 0, s.asset.total || 1, s.asset.bag?.map(a => a._id) || []]);

  var [tipsBoolOpen, setTipsBoolOpen] = useState(false);
  const tipsOpenClose = (b) => {
    setTipsBoolOpen(tipsBoolOpen = b);
  };

  const bagPlusClick = (_id, assetNumber, assetType) => {
    dispatch(addAssetBag({_id, assetNumber, assetType}));
  };

  const isBagDisabled = (asset) => {
    return bagIds.includes(asset?._id) || (!!asset?.ncStatus && asset?.ncStatus !== 'declinedByLicensee');
  };

  if (hash === '#tech') {
    return (
      <div id="container" className="layout-login contsAssetSearch">

        <main>
          <h1>Asset search</h1>
          <div className="tabbox">
            <SearchTab selected="Tech"
                       valueGroup={[
                         {label: 'Drug Candidates', value: 'Seeds', url: '/licensee/assets'},
                         {label: 'Technologies', value: 'Tech', url: '/licensee/assets#tech'},
                       ]}/>
            <div className="tabcontent-area">
              <div className="tabcontent">
                <Search selectType="licensee"/>
                <div className="searchListbox">
                  <div className="csvDownload">
                    <Button onClick={() => dispatch(downloadFile(assetType, new URLSearchParams(location.search)))} label="Excel download" size="170px"/>
                    <div className="csvDownloadPages">Total {assetsTotal} Assets</div>
                  </div>
                  <div className="searchTipsBox">
                    <div className="searchListboxInFix">
                      <ScrollContainer className="scroll-container" hideScrollbars="false" vertical="false">
                        <table className="tableGray tableGrayThHorizontal">
                          <thead>
                          <tr>
                            <th className='bagTh' onMouseOver={() => tipsOpenClose(true)} onMouseOut={() => tipsOpenClose(false)}>Asset<br/>bag
                              <div className='bagTipsOn'><img src={BagTipsImg} alt="?"/></div>
                            </th>
                            <th className={assetNumberOrder.className} onClick={() => assetNumberOrder.onSwitch()}>Asset No.</th>
                            <th>Technology</th>
                            <th>Summary</th>
                          </tr>
                          </thead>
                          <tbody>
                          {assets.map(o => {
                            return (
                              <tr key={`techRow:${o._id}`}>
                                <td className='bagTd'><button className='bagPlus' onClick={() => bagPlusClick(o._id, o.assetNumber, 'tech')} disabled={isBagDisabled(o)}></button></td>
                                <td><AssetMarks marks={o.marks}/><Link to={`/licensee/asset/${o._id}`} className="tableID">{o.assetNumber} &gt;</Link></td>
                                <td>
                                  <div className='tableBlockS'>{o.technology}</div>
                                </td>
                                <td>{o.summary?.length > 140 ? o.summary?.substring(0, 140) + '...' : o.summary}</td>
                              </tr>
                            );
                          })}
                          {!assets?.length && (
                            <tr>
                              <td colSpan={3}>
                                <div className="tableCenter">No results.</div>
                              </td>
                            </tr>
                          )}
                          </tbody>
                        </table>
                      </ScrollContainer>
                    </div>
                    <PageNavi selected={page} total={total}/>
                    <div className={['bagTips', `bagTipsOpen--${tipsBoolOpen}`].join(' ')}>Add assets to the Asset Bag in order to<br/>send the NCD requests all at once.</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    );
  } else {
    const pForms = {};
    const resolveParams = () => {
      const params = {};

      params.diseaseArea = pForms.diseaseArea.values.length !== 0 ? pForms.diseaseArea.values : undefined;
      params.route = pForms.route.values.length !== 0 ? pForms.route.values : undefined;
      params.modality = pForms.modality.values.length !== 0 ? pForms.modality.values : undefined;
      params.developmentStage = pForms.developmentStage.values.length !== 0 ? pForms.developmentStage.values : undefined;

      return params;
    };
    return (
      <div id="container" className="layout-login contsAssetSearch">

        <main>
          <h1>Asset search</h1>
          <div className="tabbox">
            <SearchTab selected="Seeds"
                       valueGroup={[
                         {label: 'Drug Candidates', value: 'Seeds', url: '/licensee/assets'},
                         {label: 'Technologies', value: 'Tech', url: '/licensee/assets#tech'},
                       ]}/>
            <div className="tabcontent-area">
              <div className="tabcontent">
                <Search selectType="licensee-multi" resolveParams={resolveParams}>
                  <InputMultiSelect name="route" label="Route" ref={ref(pForms)} value={query.get('route')} placeholder="..." valueGroup={DataRoute} selectOnly={true}/>
                  <InputMultiSelect name="modality" label="Modality" ref={ref(pForms)} value={query.get('modality')} placeholder="..." valueGroup={DataModality} selectOnly={true}/>
                  <InputMultiSelect name="developmentStage" label="Development Stage" ref={ref(pForms)} value={query.get('developmentStage')} placeholder="..." valueGroup={DataDevelopmentStage} selectOnly={true}/>
                  <InputMultiSelect name="diseaseArea" label="Disease Area" ref={ref(pForms)} value={query.get('diseaseArea')} placeholder="..." valueGroup={DataDiseaseArea} selectOnly={true}/>
                </Search>
                <div className="searchListbox">
                  <div className="csvDownload">
                    <Button onClick={() => dispatch(downloadFile(assetType, new URLSearchParams(location.search)))} label="Excel download" size="170px"/>
                    <div className="csvDownloadPages">Total {assetsTotal} Assets</div>
                  </div>
                  <div className="searchTipsBox">
                    <div className="searchListboxIn">
                      <ScrollContainer className="scroll-container" hideScrollbars="false" vertical="false">
                        <table className="tableGray tableGrayThHorizontal">
                          <thead>
                          <tr>
                            <th className='bagTh' onMouseOver={() => tipsOpenClose(true)} onMouseOut={() => tipsOpenClose(false)}>Asset<br/>bag
                              <div className='bagTipsOn'><img src={BagTipsImg} alt="?"/></div>
                            </th>
                            <th className={assetNumberOrder.className} onClick={() => assetNumberOrder.onSwitch()}>Asset No.</th>
                            <th>Mode of Action</th>
                            <th>Indication</th>
                            <th className={routeOrder.className} onClick={() => routeOrder.onSwitch()}>Route</th>
                            <th className={modalityOrder.className} onClick={() => modalityOrder.onSwitch()}>Modality</th>
                            <th className={developmentStageOrder.className} onClick={() => developmentStageOrder.onSwitch()}>Development Stage</th>
                            <th>Note</th>
                            <th className={diseaseAreaOrder.className} style={{maxWidth: "180px", minWidth: "180px"}} onClick={() => diseaseAreaOrder.onSwitch()}>Disease Area</th>
                          </tr>
                          </thead>
                          <tbody>
                          {assets.map(o => {
                            return (
                              <tr key={`techRow:${o._id}`}>
                                <td className='bagTd'><button className='bagPlus' onClick={() => bagPlusClick(o._id, o.assetNumber, 'seeds')} disabled={isBagDisabled(o)}></button></td>
                                <td><AssetMarks marks={o.marks}/><Link to={`/licensee/asset/${o._id}`} className="tableID">{o.assetNumber} &gt;</Link></td>
                                <td>
                                  <div className='tableBlockM'>{o.mechanismOfAction}</div>
                                </td>
                                <td>
                                  <div className='tableBlockM'>{o.indication?.length > 140 ? o.indication?.substring(0, 140) + '...' : o.indication}</div>
                                </td>
                                <td>{o.route?.map(l => <span key={l}>{l}</span>)}</td>
                                <td>{o.modality?.map(l => <span key={l}>{l}</span>)}</td>
                                <td>{o.developmentStage?.map(l => <span key={l}>{l}</span>)}</td>
                                <td>
                                  <div className='tableBlockM'>{o.note?.length > 140 ? o.note?.substring(0, 140) + '...' : o.note}</div>
                                </td>
                                <td style={{maxWidth: "180px", minWidth: "180px"}}>{o.diseaseArea?.map(l => <span key={l}>{l}</span>)}</td>
                              </tr>
                            );
                          })}
                          {!assets?.length && (
                            <tr>
                              <td colSpan={8}>
                                <div className="tableCenter">No results.</div>
                              </td>
                            </tr>
                          )}
                          </tbody>
                        </table>
                      </ScrollContainer>
                    </div>
                    <PageNavi selected={page} total={total}/>
                    <div className={['bagTips', `bagTipsOpen--${tipsBoolOpen}`].join(' ')}>Add assets to the Asset Bag in order to<br/>send the NCD requests all at once.</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    );
  }
};
