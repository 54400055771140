import React from 'react';
import PropTypes from 'prop-types';
import './card.css';

export const Card = ({ children,badge,unread,size }) => {
  return (
    <div className={['card', `cardUnread--${unread}`, `cardSize--${size}`].join(' ')}>
      {children}
      {badge > 0 && <div className="cardBudge">{badge}</div>}
    </div>
  );
};

Card.propTypes = {
  /**
   * 未読かどうか
   */
  unread: PropTypes.bool,
  /**
   * 右肩のバッジの数字
   */
  badge: PropTypes.number,
  /**
   * Input のタイプ
   */
  size: PropTypes.oneOf(['full', 'half', 'dashboard', 'messageList', 'request']),
};

Card.defaultProps = {
  unread: false,
  badge: 0,
  size: 'full',
};
