import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { Card } from '../../parts/Card';
import { Button } from '../../parts/Button';
import { useDispatch, useSelector } from 'react-redux';
import { Popup } from '../../parts/Popup';

import '../base.css';
import { useTitle } from '../../parts/useTitle';
import { removeAssetBag } from '../../actions/asset';
import { requestNcds } from '../../actions/nc_request';

export const AssetBag = () => {
  useTitle('Asset bag');
  const dispatch = useDispatch();
  const commentRef = useRef(null);
  const popupSuccessRef = useRef(null);
  const [errorMessage, setErrorMessage] = useState('');

  const [user, bag] = useSelector(s => [s.core.user, s.asset.bag || []]);
  const licenseeOrgId = user?.organizationId;
  const seeds = bag.filter(a => a.assetType === 'seeds');
  const tech = bag.filter(a => a.assetType === 'tech');

  const onSend = async () => {
    if (!bag?.length) return;
    setErrorMessage('');
    const result = await dispatch(requestNcds(bag, licenseeOrgId, commentRef.current.value));
    if (result.ok) {
      popupSuccessRef.current.show();
      commentRef.current.value = '';
    } else {
      setErrorMessage(result.message);
    }
  };

  return (
    <div id="container" className="layout-login contsAssetBag">
      <main>
        <h1>Asset bag</h1>
        <div id="containerMain">
          <Card>
            <h2>Do you want to request the NCDs of the following assets?</h2>
            <div className='contsAssetBagMain'>
              <p>You’re going to send the NCD requests for the following assets. To remove the assets, please click the “-“ button.</p>
              <div className='attn'>*Please note that the Asset Bag will be cleared if you exit the browser or reload the page.</div>
              <div className='contsAssetBagList'>
                <div className='contsAssetBagListL'>
                  <h3>Drug Candidates</h3>
                  <ul>
                    {seeds.map(a =>
                      <li><Link to={`/licensee/asset/${a._id}`}>{a.assetNumber} &gt;</Link><button className='bagMinus' onClick={() => dispatch(removeAssetBag(a))}></button></li>
                    )}
                  </ul>
                </div>
                <div className='contsAssetBagListR'>
                  <h3>Technologies</h3>
                  <ul>
                    {tech.map(a =>
                      <li><Link to={`/licensee/asset/${a._id}`}>{a.assetNumber} &gt;</Link><button className='bagMinus' onClick={() => dispatch(removeAssetBag(a))}></button></li>
                    )}
                  </ul>
                </div>
              </div>
              <p>Please send us a message to let us know what kind of development you are looking for.</p>
              <p style={{fontWeight: 'bold'}}>{errorMessage}</p>
              <textarea rows="3" ref={commentRef} placeholder="Type here…"/>
              <Button label="Send" size="popupSubmit" onClick={() => onSend()} disabled={!bag.length}/>
            </div>
          </Card>
        </div>
      </main>
      <Popup ref={popupSuccessRef} title="Request Success." submitButton="" cancelButton="Close"/>
    </div>
  );
};
