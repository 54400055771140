import { api, putLocalStorage } from './api.js';
import { isError } from './core';

export const REQUEST_DASHBOARD = 'REQUEST_DASHBOARD';
export const RECEIVE_DASHBOARD = 'RECEIVE_DASHBOARD';

let fetching = false;

export const loadGemsekiDashboard = () => async (dispatch) => {
  fetching = true;

  try {
    const res = await api.get(dispatch, `dashboard/gemseki`);
    if (isError(res, dispatch)) return;

    const messageCount = res.body.messageCount || 0;
    const cdaRequestCount = res.body.cdaRequestCount || 0;
    const ncRequestCount = res.body.ncRequestCount || 0;
    const messages = res.body.messages || [];
    const cdaRequests = res.body.cdaRequests || [];
    const ncRequests = res.body.ncRequests || [];

    dispatch({
      type: RECEIVE_DASHBOARD,
      messageCount,
      cdaRequestCount,
      ncRequestCount,
      messages,
      cdaRequests,
      ncRequests,
    });
  } finally {
    fetching = false;
  }
};

export const loadGemsekiCounts = () => async (dispatch) => {
  if (fetching) return;
  fetching = true;

  try {
    const res = await api.get(dispatch, `dashboard/gemseki/counts`);
    if (isError(res, dispatch)) return;

    const messageCount = res.body.messageCount || 0;
    const cdaRequestCount = res.body.cdaRequestCount || 0;
    const ncRequestCount = res.body.ncRequestCount || 0;

    dispatch({
      type: RECEIVE_DASHBOARD,
      messageCount,
      cdaRequestCount,
      ncRequestCount,
    });
  } finally {
    fetching = false;
  }
};

export const loadLicenseeDashboard = () => async (dispatch) => {
  fetching = true;

  try {
    const res = await api.get(dispatch, `dashboard/licensee`);
    if (isError(res, dispatch)) return;

    const messageCount = res.body.messageCount || 0;
    const messages = res.body.messages || [];
    if (messageCount > 0) {
      dispatch(closeDashboardTutorial());
    }

    dispatch({
      type: RECEIVE_DASHBOARD,
      messageCount,
      messages,
    });
  } finally {
    fetching = false;
  }
};

export const loadLicenseeCounts = () => async (dispatch) => {
  if (fetching) return;
  fetching = true;

  try {
    const res = await api.get(dispatch, `dashboard/licensee/counts`);
    if (isError(res, dispatch)) return;

    const messageCount = res.body.messageCount || 0;
    if (messageCount > 0) {
      dispatch(closeDashboardTutorial());
    }

    dispatch({
      type: RECEIVE_DASHBOARD,
      messageCount,
    });
  } finally {
    fetching = false;
  }
};

export const closeDashboardTutorial = () => async (dispatch) => {
  putLocalStorage('dashboard', 'closedTutorial', true);
  dispatch({
      type: RECEIVE_DASHBOARD,
      closedTutorial: true,
  });
};