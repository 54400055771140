import React from 'react';

import '../base.css';
import Template from '../../temp/Gemseki_analytics.png';
import { DebugMenu } from '../../parts/DebugMenu';
import { useTitle } from '../../parts/useTitle';

export const Analytics = () => {
  useTitle('Analytics');

  return (
    <div>
      <img src={Template} alt="template"/>
      <DebugMenu links={[
        {name: 'Dashboard', to: '/gemseki'},
      ]}/>
    </div>
  );
};
