import { Route, Switch } from 'react-router-dom';
import { MenuPlot } from '../parts/Menu';
import { Login } from './Login';
import { Signup } from './Signup';
import { Home } from './Home';
import { ForgotPassword } from './ForgotPassword';
import { CreateAccount } from './CreateAccount';
import { Header } from '../parts/Header';
import { Footer } from '../parts/Footer';
import React from 'react';
import { ErrorMessages, useClearMessages } from '../parts/ErrorMessages';
import { InvitationAccount } from './InvitationAccount';
import { AssetRedirection } from './AssetRedirection';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

const Common = () => {
  return (
    <div>
      <Header/>
      <Switch>
        <Route path="/login">
          <MenuPlot menuId="000"/><Login/>
        </Route>
        <Route path="/signup">
          <MenuPlot menuId="000"/><Signup/>
        </Route>
        <Route path="/forgot_password">
          <MenuPlot menuId="000"/><ForgotPassword/>
        </Route>
        <Route path="/create_account">
          <MenuPlot menuId="000"/><CreateAccount/>
        </Route>
        <Route path="/invitation_account">
          <MenuPlot menuId="000"/><InvitationAccount/>
        </Route>
        <Route path="/asset/:assetId">
          <MenuPlot menuId="206"/><AssetRedirection/>
        </Route>
        <Route path="/">
          <MenuPlot menuId="000"/><Home/>
        </Route>
      </Switch>
      <Footer/>
      <ErrorMessages/>
    </div>
  );
};

const RecaptchaKey = process.env.REACT_APP_RECAPTCHA_KEY;

export const RoutesCommon = () => {
  useClearMessages();
  return RecaptchaKey ? (
    <GoogleReCaptchaProvider reCaptchaKey={RecaptchaKey}>
      <Common/>
    </GoogleReCaptchaProvider>
  ) : (
    <Common/>
  );
};
