import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router';

import { Search } from '../../parts/Search';
import { Button } from '../../parts/Button';
import { PageNavi } from '../../parts/PageNavi';
import { Card } from '../../parts/Card';
import {InputSelect} from '../../parts/InputSelect';
import ScrollContainer from 'react-indiana-drag-scroll'

import '../base.css';
import { useDispatch, useSelector } from 'react-redux';
import { findOriginators, getGemsekiUsers } from '../../actions/core';
import { fd, useQuery, ref } from '../../parts/refs';
import { useTitle } from '../../parts/useTitle';

export const OriginatorSearch = () => {
  useTitle('Originator search');

  const history = useHistory();
  const dispatch = useDispatch();
  const query = useQuery();
  const words = query.get('words') || '';
  const status = query.get('status') || '';
  const page = parseInt(query.get('page') || '0');

  useEffect(() => {
    dispatch(getGemsekiUsers());
    dispatch(findOriginators(words, status, page));
  }, [dispatch, words, status, page]);

  const [gemsekiUsers, originators, total] = useSelector(s => [s.core.gemsekiUsers || [], s.core.originators || [], s.core.total || 1]);
  const gemsekiName = (id) => {
    const user = gemsekiUsers.find(u => u._id === id);
    return user ? `${user.firstName} ${user.lastName}` : '';
  };

  const pForms = {};
  const resolveParams = () => {
    const params = {};
    params.status = pForms.status.value;
    return params;
  };

  return (
    <div id="container" className="layout-login contsAssetSearch">

      <main>
        <h1>Originator search</h1>
        <div id='containerMain'>
          <Card>
        <div className="searchOptionBox">
          <Search selectType="valid" resolveParams={resolveParams}>
            <InputSelect name="status" label="Valid / Invalid" ref={ref(pForms)} value={query.get('status')} placeholder="..." valueGroup={[{label: 'Valid', value: 'valid'},{label: 'Invalid', value: 'invalid'}]}/>
          </Search>
          <Button label="Add" size="170px" onClick={() => history.push(`/gemseki/originator_add`)}/>
        </div>
        <div className="searchListboxInFix">
        <ScrollContainer className="scroll-container" hideScrollbars="false" vertical="false">
        <table className="tableGray tableGrayThHorizontal">
          <thead>
          <tr>
            <th>Date<br/>(Company Registered)</th>
            <th>Company</th>
            <th>PM</th>
            <th>Valid / Invalid</th>
          </tr>
          </thead>
          <tbody>
          {originators.map(o => {
            return (
              <tr key={`originatorRow:${o._id}`}>
                <td>{fd(o.createdAt)}</td>
                <td><Link to={`originator/${o._id}`}><strong>{o.organizationName}</strong></Link></td>
                <td>{o.gemsekiPic.map(gemsekiName)?.map(l => <span key={l}>{l}</span>)}</td>
                <td>{o.status}</td>
              </tr>);
          })}
          {!originators?.length && (
            <tr>
              <td colSpan={3}>
                <div className="tableCenter">No results.</div>
              </td>
            </tr>
          )}
          </tbody>
        </table>
        </ScrollContainer>
        </div>
        <PageNavi selected={page} total={total}/>
        </Card>
        </div>
      </main>
    </div>
  );
};
