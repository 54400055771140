import { api, apiUrl, copyQuery } from './api.js';
import { clearError, isError } from './core';
import { clearValidation, isValid, required, validate, VALIDATION_ERROR } from './validator';

export const REQUEST_ASSET = 'REQUEST_ASSET';
export const RECEIVE_ASSET = 'RECEIVE_ASSET';

export const createTemporaryId = () => async (dispatch) => {
  dispatch(clearError());

  const res = await api.post(dispatch, `asset/temporary`);
  if (isError(res, dispatch)) return;

  const temporaryId = res.body.temporaryId;

  dispatch({
    type: RECEIVE_ASSET,
    temporaryId,
  });

  return temporaryId;
};

export const uploadNc = (temporaryId, inputFile) => async (dispatch) => {
  if (inputFile.size > 1024 * 1024 * 10) {
    dispatch({
      type: VALIDATION_ERROR,
      errors: [
        {
          name: 'ncs',
          type: 'maxFileSize',
        }],
    });
    return;
  }
  dispatch(clearValidation());
  let assetId = temporaryId ? temporaryId : await dispatch(createTemporaryId());

  const form = new FormData();
  form.append('file', inputFile);

  const res = await api.fetch(dispatch, `/asset/${assetId}/nc`, {
    method: 'POST',
    body: form,
  });
  if (isError(res, dispatch)) return;

  return res.body;
};

export const downloadNc = (fileItem, assetId, temporaryId) => async (dispatch) => {
  const token = await dispatch(createDownloadToken());
  if (!token) return;

  const name = encodeURI(fileItem.fileName);
  window.open(apiUrl(fileItem.isTemporary ? `asset/${temporaryId}/nc/${name}/temporary?token=${token}` : `asset/${assetId}/nc/${name}?token=${token}`), '_downloadFile');
};

export const createAsset = (data, history) => async (dispatch) => {
  dispatch(clearError());

  const rules = [];

  rules.push(required('assetNumber'));
  rules.push(required('organizationId'));

  const validation = validate(data, ...rules);
  if (isValid(validation, dispatch)) return;

  const res = await api.post(dispatch, `asset`, data);
  if (isError(res, dispatch)) return;

  const asset = res.body;

  history.push(`asset/${asset._id}`);
};

export const getAsset = (assetId) => async (dispatch) => {
  const res = await api.get(dispatch, `asset/${assetId}`);
  if (isError(res, dispatch)) return;

  const assetDetail = res.body;

  dispatch({
    type: RECEIVE_ASSET,
    assetDetail,
  });
};

export const updateAsset = (assetId, data, history) => async (dispatch) => {
  dispatch(clearError());

  const validation = validate(data,
    required('assetNumber'),
    required('organizationId'),
  );
  if (isValid(validation, dispatch)) return;

  const res = await api.put(dispatch, `asset/${assetId}`, data);
  if (isError(res, dispatch)) return;

  history.goBack();
};

export const updateAssetStatus = (assetId, status) => async (dispatch) => {
  dispatch(clearError());

  const res = await api.put(dispatch, `asset/${assetId}/status`, {status});
  if (isError(res, dispatch)) return;

  const assetDetail = res.body;
  dispatch({
    type: RECEIVE_ASSET,
    assetDetail,
  });
};

export const findAssets = (query, assetType) => async (dispatch) => {
  const q = copyQuery(query, 'words', 'page', 'diseaseArea', 'route', 'modality', 'developmentStage', 'order', 'dir');

  q.set('assetType', assetType);
  q.set('limit', '10');

  const res = await api.get(dispatch, `asset/find?${q}`);
  if (isError(res, dispatch)) return;

  const assetsTotal = res.body.total;
  const total = Math.ceil(assetsTotal / 10);
  const assets = res.body.data;

  dispatch({
    type: RECEIVE_ASSET,
    assetsTotal,
    total,
    assets,
  });
};

export const assetDetail = (assetId) => async (dispatch) => {
  const res = await api.get(dispatch, `asset/${assetId}`);
  if (isError(res, dispatch)) return;

  const assetDetail = res.body;

  dispatch({
    type: RECEIVE_ASSET,
    assetDetail,
  });
};

export const createDownloadToken = () => async (dispatch) => {
  dispatch(clearError());

  const res = await api.post(dispatch, 'token_for_download');
  if (isError(res, dispatch)) return false;

  return res.body.token;
};

export const downloadFile = (assetType, query, fileType = 'excel') => async (dispatch) => {
  const token = await dispatch(createDownloadToken());
  if (!token) return;

  const q = copyQuery(query, 'words', 'diseaseArea', 'route', 'modality', 'developmentStage', 'order', 'dir');
  q.set('assetType', assetType);
  q.set('token', token);

  window.open(apiUrl(`asset/${fileType}?${q}`), '_downloadFile');
};

export const findAssetsByGuest = (query, assetType) => async (dispatch) => {
  const q = copyQuery(query, 'words', 'page', 'diseaseArea', 'developmentStage', 'order', 'dir');

  q.set('assetType', assetType);
  q.set('limit', '10');

  const res = await api.get(dispatch, `asset/find_by_guest?${q}`);
  if (isError(res, dispatch)) return;

  const total = Math.ceil(res.body.total / 10);
  const assets = res.body.data;

  dispatch({
    type: RECEIVE_ASSET,
    total,
    assets,
  });
};

export const findAssetsByGemseki = (query, assetType) => async (dispatch) => {
  const q = copyQuery(query, 'words', 'page', 'diseaseArea', 'route', 'modality', 'developmentStage', 'status', 'gemsekiPic', 'order', 'dir');

  q.set('assetType', assetType);
  q.set('limit', '10');

  const res = await api.get(dispatch, `asset/find_by_gemseki?${q}`);
  if (isError(res, dispatch)) return;

  const assetsTotal = res.body.total;
  const total = Math.ceil(assetsTotal / 10);
  const assets = res.body.data;

  dispatch({
    type: RECEIVE_ASSET,
    assetsTotal,
    total,
    assets,
  });
};

export const downloadFileByGemseki = (assetType, query, fileType = 'excel') => async (dispatch) => {
  const token = await dispatch(createDownloadToken());
  if (!token) return;

  const q = copyQuery(query, 'words', 'diseaseArea', 'route', 'modality', 'developmentStage', 'status', 'gemsekiPic', 'order', 'dir');
  q.set('assetType', assetType);
  q.set('token', token);

  window.open(apiUrl(`asset/${fileType}_by_gemseki?${q}`), '_downloadFile');
};

export const getAssetNumbers = () => async (dispatch) => {
  const res = await api.get(dispatch, `asset/numbers`);
  if (isError(res, dispatch)) return;

  const numbers = res.body || [];
  const assetNumbers = numbers.map(o => ({label: o.assetNumber, value: o._id}));

  dispatch({
    type: RECEIVE_ASSET,
    assetNumbers,
  });
};

export const addAssetBag = (asset) => async (dispatch, getState) => {
  const s = getState();
  const bag = s.asset.bag || [];

  dispatch({
    type: RECEIVE_ASSET,
    bag: bag.filter(a => a._id !== asset._id).concat([asset]).sort((l, r) => l.assetNumber.localeCompare(r.assetNumber)),
  });
};

export const removeAssetBag = (asset) => async (dispatch, getState) => {
  const s = getState();
  const bag = s.asset.bag || [];

  dispatch({
    type: RECEIVE_ASSET,
    bag: bag.filter(a => a._id !== asset._id),
  });
};
