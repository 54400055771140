import { isValid, matchesConfirm, minPassword, notEmpty, patternEmail, patternPassword, required, validate } from './validator';
import { api } from './api';

export const ERROR = 'ERROR';
export const ERROR_CLEAR = 'ERROR_CLEAR';
export const ERROR_CLEAR_ALL = 'ERROR_CLEAR_ALL';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const REQUEST_CORE = 'REQUEST_CORE';
export const RECEIVE_CORE = 'RECEIVE_CORE';
export const REDIRECT_SAVE = 'REDIRECT_SAVE';
export const RECEIVE_NAMES = 'RECEIVE_NAMES';

export const clearError = (errorType) => async (dispatch) => {
  if (errorType) {
    dispatch({type: ERROR_CLEAR, errorType});
  } else {
    dispatch({type: ERROR_CLEAR_ALL});
  }
};

export const defaultErrorHandler = (res, dispatch) => {
  if (res.status === -1) {
    dispatch({
      type: ERROR,
      errorType: 'notConnect',
    });
  } else if (res.status === 409) {
    dispatch({
      type: ERROR,
      errorType: 'conflict',
      conflict: res.body.conflict,
    });
  } else if (res.status === 404) {
    dispatch({
      type: ERROR,
      errorType: 'notFound',
      conflict: res.body.conflict,
    });
  } else if (res.status === 403) {
    dispatch({
      type: ERROR,
      errorType: 'forbidden',
      conflict: res.body.conflict,
    });
  } else if (res.status === 401) {
    dispatch({
      type: ERROR,
      errorType: 'unauthorized',
      conflict: res.body.conflict,
    });
  } else if (res.status < 500) {
    dispatch({
      type: ERROR,
      errorType: 'clientError',
    });
  } else {
    dispatch({
      type: ERROR,
      errorType: 'serverError',
    });
  }
  return true;
};

export const isError = (res, dispatch, errorHandler = defaultErrorHandler, successHandler = () => false) => {
  if (!res.ok) {
    return errorHandler(res, dispatch);
  }
  return successHandler(res, dispatch);
};

export const redirectToLogin = (history, location) => async (dispatch) => {
  dispatch({
    type: REDIRECT_SAVE,
    location,
  });
  history.replace('/login');
};

export const accountRequests = (email, recaptchaToken, history, endHash) => async (dispatch) => {
  dispatch(clearError());

  const validation = validate({email},
    required('email'),
    patternEmail('email'),
  );
  if (isValid(validation, dispatch)) return;

  const res = await api.post(dispatch, 'account_requests', {email, recaptchaToken});
  if (isError(res, dispatch)) return;

  dispatch(() => history.push(endHash));
};

export const createAccount = (account, recaptchaToken, history, organizationType = 'licensee', isInvitation = false) => async (dispatch) => {
  if (!account.requestKey) return;
  dispatch(clearError());

  const validation = isInvitation ? validate(account,
    required('email'),
    required('firstName'),
    required('lastName'),
    required('password'),
    patternPassword('password'),
    minPassword('password'),
    required('confirmPassword'),
    matchesConfirm('confirmPassword', 'password'),
  ) : validate(account,
    required('email'),
    required('organizationName'),
    required('area'),
    required('country'),
    notEmpty('typeOfCompany'),
    required('firstName'),
    required('lastName'),
    required('password'),
    patternPassword('password'),
    minPassword('password'),
    required('confirmPassword'),
    matchesConfirm('confirmPassword', 'password'),
  );
  if (isValid(validation, dispatch)) return;

  const res = await api.post(dispatch, organizationType, account);
  if (isError(res, dispatch)) return;

  dispatch(login(account.email, account.password, recaptchaToken, history));
};

export const applyInvitation = (profile, history) => async (dispatch) => {
  dispatch(clearError());

  const res = await api.post(dispatch, 'invitation_mail_apply', {requestKey: profile.requestKey});
  if (isError(res, dispatch)) return;

  const profiles = await dispatch(loadProfiles());
  if (!profiles) return;

  history.push(`/${profiles.user.organizationType}/member_entry`);
};

export const applyInvitationWithLogin = (profile, recaptchaToken, history) => async (dispatch) => {
  const validation = validate({email: profile.email, password: profile.password},
    required('email'),
    required('password'),
  );
  if (isValid(validation, dispatch)) return;

  const user = await dispatch(login(profile.email, profile.password, recaptchaToken));
  if (!user) return;
  dispatch(applyInvitation(profile, history));
};

let fetching = false;

export const fetchUser = () => async (dispatch) => {
  if (fetching) return;
  fetching = true;
  try {
    if (!api.hasToken) {
      dispatch({type: LOGIN_ERROR});
      return;
    }

    const res = await api.get(dispatch, 'fetch_user');

    if (!res.ok) {
      dispatch({type: LOGIN_ERROR});
      return;
    }

    const user = res.body;
    const res2 = await api.get(dispatch, `organization/${user.organizationId}`);

    if (isError(res2, dispatch)) {
      dispatch({type: LOGIN_ERROR});
      return;
    }

    dispatch({
      type: RECEIVE_CORE,
      user,
      organization: res2.body,
    });

  } finally {
    fetching = false;
  }
};

export const changePassword = (profile, history) => async (dispatch) => {
  dispatch(clearError());

  const validation = validate(profile,
    required('password'),
    required('newPassword'),
    patternPassword('newPassword'),
    minPassword('newPassword'),
    required('confirmPassword'),
    matchesConfirm('confirmPassword', 'newPassword'),
  );
  if (isValid(validation, dispatch)) return;

  const res = await api.put(dispatch, `user/${profile._id}/password`, profile);
  if (isError(res, dispatch)) return;

  history.goBack();
};

export const loadProfiles = () => async (dispatch) => {
  const res = await api.get(dispatch, 'fetch_user');
  if (isError(res, dispatch)) return;

  const user = res.body;
  const res2 = await api.get(dispatch, `organization/${user.organizationId}`);
  if (isError(res2, dispatch)) return;

  const organization = res2.body;
  const res3 = await api.get(dispatch, `organization/${organization._id}/member`);
  if (isError(res3, dispatch)) return;

  const members = res3.body;

  dispatch({
    type: RECEIVE_CORE,
    user,
    organization,
    members,
  });

  return {user, organization, members};
};

export const login = (id, password, recaptchaToken, history, loginTo) => async (dispatch, getState) => {
  const validation = validate({id, password},
    required('id'),
    required('password'),
  );
  if (isValid(validation, dispatch)) return;

  const res = await api.login(dispatch, id, password, recaptchaToken);

  if (isError(res, dispatch)) {
    dispatch({type: LOGIN_ERROR});
    return;
  }

  const user = res.body.user;
  const res2 = await api.get(dispatch, `organization/${user.organizationId}`);

  if (isError(res2, dispatch)) {
    dispatch({type: LOGIN_ERROR});
    return;
  }

  const {redirectTo} = getState().core;

  dispatch({
    type: RECEIVE_CORE,
    user,
    loginError: false,
    organization: res2.body,
    redirectTo: null,
  });

  if (!history) return user;
  if (loginTo) {
    history.push(loginTo);
  } else if (redirectTo) {
    history.push(redirectTo);
  } else if (user.organizationType === 'gemseki') {
    history.push('/gemseki');
  } else {
    history.push('/licensee');
  }
};

export const logout = (logoutTo, history) => async (dispatch) => {
  dispatch(clearError());

  await api.logout(dispatch);

  if (history) {
    history.push(logoutTo);
  }

  dispatch({
    type: RECEIVE_CORE,
    user: {},
    loginError: true,
    organization: null,
  });
};

export const forgotPassword = (email, recaptchaToken) => async (dispatch) => {
  dispatch(clearError());

  const profile = {email, recaptchaToken};
  const validation = validate({email},
    required('email'),
    patternEmail('email'),
  );
  if (isValid(validation, dispatch)) return false;

  const res = await api.post(dispatch, `reset_password`, profile);
  return !isError(res, dispatch);
};

export const updateUser = (profile, history) => async (dispatch, getState) => {
  dispatch(clearError());

  const validation = validate(profile,
    required('firstName'),
    required('lastName'),
  );
  if (isValid(validation, dispatch)) return;

  const s = getState();
  const oldUser = s.core.user;
  const res = await api.put(dispatch, `user/${oldUser._id}`, profile);
  if (isError(res, dispatch)) return;

  const user = res.body;

  dispatch({
    type: RECEIVE_CORE,
    user,
  });

  history.goBack();
};

export const updateOrganization = (profile, history, isGemseki = false) => async (dispatch, getState) => {
  dispatch(clearError());

  const validation = isGemseki ? validate(profile,
    required('organizationName'),
  ) : validate(profile,
    required('organizationName'),
    required('area'),
    required('country'),
    notEmpty('typeOfCompany'),
  );
  if (isValid(validation, dispatch)) return;

  const s = getState();
  const oldOrganization = s.core.organization;
  const res = await api.put(dispatch, `organization/${oldOrganization._id}`, profile);
  if (isError(res, dispatch)) return;

  const organization = res.body;

  dispatch({
    type: RECEIVE_CORE,
    organization,
  });

  history.goBack();
};

export const getGemsekiUsers = () => async (dispatch) => {
  const res = await api.get(dispatch, `gemseki_users`);
  if (isError(res, dispatch)) return false;

  const gemsekiUsers = res.body;

  dispatch({
    type: RECEIVE_CORE,
    gemsekiUsers,
  });

  return gemsekiUsers;
};

export const getIntroducers = () => async (dispatch) => {
  const res = await api.get(dispatch, `introducers`);
  if (isError(res, dispatch)) return false;

  const introducers = res.body;

  dispatch({
    type: RECEIVE_CORE,
    introducers,
  });
  return introducers;
};

export const getMembers = (organizationId) => async (dispatch) => {
  const res = await api.get(dispatch, `organization/${organizationId}/member`);
  if (isError(res, dispatch)) return false;

  const members = res.body;

  dispatch({
    type: RECEIVE_CORE,
    members,
  });
  return members;
};

export const getMember = (userId) => async (dispatch) => {
  const res = await api.get(dispatch, `user/${userId}`);
  if (isError(res, dispatch)) return false;

  const member = res.body;

  dispatch({
    type: RECEIVE_CORE,
    member,
  });
  return member;
};

export const updateMember = (profile, history) => async (dispatch) => {
  dispatch(clearError());

  const validation = validate(profile,
    required('firstName'),
    required('lastName'),
  );
  if (isValid(validation, dispatch)) return;

  const res = await api.put(dispatch, `user/${profile._id}`, profile);
  if (isError(res, dispatch)) return;

  const member = res.body;

  dispatch({
    type: RECEIVE_CORE,
    member,
  });

  history.goBack();
};

export const inviteMember = (organizationId, profile) => async (dispatch) => {
  dispatch(clearError());

  const validation = validate(profile,
    required('email'),
    patternEmail('email'),
  );
  if (isValid(validation, dispatch)) return;

  const res = await api.post(dispatch, `organization/${organizationId}/invite`, profile);
  return !isError(res, dispatch);
};

export const createOriginatorMember = (organizationId, profile) => async (dispatch) => {
  dispatch(clearError());

  const validation = validate(profile,
    required('email'),
    required('firstName'),
    required('lastName'),
    patternEmail('email'),
  );
  if (isValid(validation, dispatch)) return false;

  const res = await api.post(dispatch, `originator/${organizationId}/member`, profile);
  if (isError(res, dispatch)) return false;

  dispatch(getMembers(organizationId));
  return true;
};

export const deleteMembers = (organizationId, memberIds) => async (dispatch) => {
  dispatch(clearError());

  try {
    for (const memberId of memberIds) {
      const res = await api.delete(dispatch, `organization/${organizationId}/member/${memberId}`);
      if (isError(res, dispatch)) return false;
    }
    return true;
  } finally {
    dispatch(getMembers(organizationId));
  }
};

export const findLicensees = (words, status, page) => async (dispatch) => {
  const w = encodeURIComponent(words);
  const res = await api.get(dispatch, `organization/find?organizationType=licensee&words=${w}&status=${status}&page=${page}&limit=6`);
  if (isError(res, dispatch)) return;

  const total = Math.ceil(res.body.total / 6);
  const licensees = res.body.data;

  dispatch({
    type: RECEIVE_CORE,
    total,
    licensees,
  });
};

export const getLicensee = (licenseeId) => async (dispatch) => {
  dispatch({
    type: REQUEST_CORE,
    licensee: null,
  });

  const id = encodeURIComponent(licenseeId);
  const res = await api.get(dispatch, `organization/${id}`);
  if (isError(res, dispatch)) return;

  const licensee = res.body;

  dispatch({
    type: RECEIVE_CORE,
    licensee,
  });
};

export const createLicensee = (licensee, history) => async (dispatch) => {
  dispatch(clearError());

  const validation = validate(licensee,
    required('organizationName'),
    required('area'),
    required('country'),
    notEmpty('typeOfCompany'),
  );
  if (isValid(validation, dispatch)) return;

  const res = await api.post(dispatch, `organization`, licensee);
  if (isError(res, dispatch)) return;

  history.goBack();
};

export const updateLicensee = (licensee, history) => async (dispatch) => {
  dispatch(clearError());

  const validation = validate(licensee,
    required('_id'),
    required('organizationName'),
    required('area'),
    required('country'),
    notEmpty('typeOfCompany'),
  );
  if (isValid(validation, dispatch)) return;

  const res = await api.put(dispatch, `organization/${licensee._id}`, licensee);
  if (isError(res, dispatch)) return;

  history.goBack();
};

export const findOriginators = (words, status, page) => async (dispatch) => {
  const w = encodeURIComponent(words);
  const res = await api.get(dispatch, `organization/find?organizationType=originator&words=${w}&status=${status}&page=${page}&limit=6`);
  if (isError(res, dispatch)) return;

  const total = Math.ceil(res.body.total / 6);
  const originators = res.body.data;

  dispatch({
    type: RECEIVE_CORE,
    total,
    originators,
  });
};

export const getOriginator = (originatorId) => async (dispatch) => {
  dispatch({
    type: REQUEST_CORE,
    originator: null,
  });

  const id = encodeURIComponent(originatorId);
  const res = await api.get(dispatch, `organization/${id}`);
  if (isError(res, dispatch)) return;

  const originator = res.body;

  dispatch({
    type: RECEIVE_CORE,
    originator,
  });
};

export const getOriginatorNames = () => async (dispatch) => {
  const res = await api.get(dispatch, `originator_names`);
  if (isError(res, dispatch)) return;

  const names = res.body || [];
  const originatorNames = names.map(o => ({label: o.organizationName, value: o._id, status: o.status}));

  dispatch({
    type: RECEIVE_CORE,
    originatorNames,
  });
};

export const getLicenseeNames = () => async (dispatch) => {
  const res = await api.get(dispatch, `licensee_names`);
  if (isError(res, dispatch)) return;

  const names = res.body || [];
  const licenseeNames = names.map(o => ({label: o.organizationName, value: o._id, status: o.status}));

  dispatch({
    type: RECEIVE_CORE,
    licenseeNames,
  });
};

export const getNames = () => async (dispatch) => {
  const res = await api.get(dispatch, `names`);
  if (isError(res, dispatch)) return;

  const names = res.body || {};
  const originatorNames = names.originatorNames?.map(o => ({label: o.organizationName, value: o._id, status: o.status}));
  const licenseeNames = names.licenseeNames?.map(o => ({label: o.organizationName, value: o._id, status: o.status}));
  const assetNumbers = names.assetNumbers?.map(o => ({label: o.assetNumber, value: o._id, status: o.status}));

  dispatch({
    type: RECEIVE_NAMES,
    originatorNames,
    licenseeNames,
    assetNumbers,
  });
};

const originatorConflictHandler = (originator) => (res, dispatch) => {
  if (res.status === 409 && res.body?.conflict?.email) {
    const email = res.body.conflict.email;
    const conflict = originator.members.reduce((acm, member, i) => acm || member.email === email ? {[`members${i}_email`]: email} : null, null) || {};
    dispatch({
      type: ERROR,
      errorType: 'conflict',
      conflict,
    });
  } else {
    defaultErrorHandler(res, dispatch);
  }
  return true;
};

export const createOriginator = (originator, history) => async (dispatch) => {
  dispatch(clearError());

  const validation = validate(originator,
    required('organizationName'),
    required('area'),
    required('country'),
    notEmpty('typeOfCompany'),
    required('members.firstName'),
    required('members.lastName'),
    required('members.email'),
    patternEmail('members.email'),
  );
  if (isValid(validation, dispatch)) return;

  const res = await api.post(dispatch, `originator`, originator);
  if (isError(res, dispatch, originatorConflictHandler(originator))) return;

  history.goBack();
};

export const updateOriginator = (originator, history) => async (dispatch) => {
  dispatch(clearError());

  const validation = validate(originator,
    required('_id'),
    required('organizationName'),
    required('area'),
    required('country'),
    notEmpty('typeOfCompany'),
  );
  if (isValid(validation, dispatch)) return;

  const res = await api.put(dispatch, `originator/${originator._id}`, originator);
  if (isError(res, dispatch)) return;

  history.goBack();
};

export const updateOrganizationStatus = (organizationId, status) => async (dispatch) => {
  dispatch(clearError());

  const res = await api.put(dispatch, `organization/${organizationId}/status`, {status});
  if (isError(res, dispatch)) return;

  const organization = res.body;
  if (organization.organizationType === 'originator') {
    dispatch({
      type: RECEIVE_CORE,
      originator: organization,
    });
  } else {
    dispatch({
      type: RECEIVE_CORE,
      licensee: organization,
    });
  }
};

export const setSelectorOpen = (isOpen) => async (dispatch) => {
  dispatch({
    type: RECEIVE_CORE,
    isSelectorOpen: isOpen,
  });
};
