import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { ButtonBack } from '../../parts/ButtonBack';
import { Input1Line } from '../../parts/Input1Line';
import { InputSelect } from '../../parts/InputSelect';
import { CheckBoxGroup } from '../../parts/CheckBoxGroup';
import { Button } from '../../parts/Button';
import { Card } from '../../parts/Card';

import {
  DataTypeOfCompanyLicensee,
  DataDiseaseArea,
  DataRoute,
  DataModality,
  DataDevelopmentStage,
  DataTerritoryForInLicensing,
} from '../../reducers/datas';

import '../base.css';
import { fd, ref } from '../../parts/refs';
import { loadProfiles, updateOrganization } from '../../actions/core';
import { useCountries } from '../../parts/useCountries';
import { useTitle } from '../../parts/useTitle';

export const OrganizationEdit = () => {
  useTitle('Corporate Account edit');

  const [organization] = useSelector(s => [s.core.organization]);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(loadProfiles());
  }, [dispatch]);

  const forms = {};
  const [areas, countries, areaCallback, countryRef] = useCountries(organization?.area);

  const resolveData = () => {
    return {
      organizationName: forms.organizationName.value,
      area: forms.area.value,
      country: countryRef.current.value,
      typeOfCompany: forms.typeOfCompany.values,
      diseaseArea: forms.diseaseArea.values,
      route: forms.route.values,
      modality: forms.modality.values,
      developmentStage: forms.developmentStage.values,
      territoryForInLicensing: forms.territoryForInLicensing.values,
      licensingCriteria: forms.licensingCriteria.value,
      url: forms.url.value,
    };
  };

  return (
    <div id="container" className="layout-login contsOrganizationEdit">

      <main>
        <h1><ButtonBack/>Corporate account edit</h1>
        <div id='containerMain'>
          <Card>
            <table className="tableGray tableGrayThVertical">
              <tbody>
              <tr>
                <th><label className="tableGrayLabel">Date (Company Registered)</label></th>
                <td><p>{fd(organization?.createdAt)}</p></td>
                <th><label className="tableGrayLabel">Date (Company Updated)</label></th>
                <td><p>{fd(organization?.updatedAt)}</p></td>
              </tr>
              <tr>
                <th style={{paddingTop:"26px"}}><label className="tableGrayLabel">Company ID</label></th>
                <td style={{paddingTop:"26px"}}><p>{organization?.displayId}</p></td>
                <th style={{paddingTop:"26px"}}><label className="tableGrayLabel" htmlFor="input1Line-ID--licensee">Company</label></th>
                <td><Input1Line name="organizationName" ref={ref(forms)} size="100%" defaultValue={organization?.organizationName}/></td>
              </tr>
              <tr>
                <th style={{paddingTop:"26px"}}><label className="tableGrayLabel">Area, Country</label></th>
                <td colSpan="3">
                  <div className="buttonBoxL">
                    <InputSelect name="area" size="250px" ref={ref(forms)} placeholder="Area" value={organization?.area} valueGroup={areas} onChange={areaCallback}/>
                    <InputSelect name="country" size="350px" ref={countryRef} placeholder="Country" value={organization?.country} valueGroup={countries}/>
                  </div>
                </td>
              </tr>
              <tr>
                <th><label className="tableGrayLabel">Type of Company</label></th>
                <td colSpan="3">
                  <CheckBoxGroup name="typeOfCompany" ref={ref(forms)} values={organization?.typeOfCompany} valueGroup={DataTypeOfCompanyLicensee}/>
                </td>
              </tr>
              <tr>
                <th><label className="tableGrayLabel">Licensing Criteria<br/>(Disease Area)</label></th>
                <td colSpan="3">
                  <CheckBoxGroup name="diseaseArea" ref={ref(forms)} values={organization?.diseaseArea} valueGroup={DataDiseaseArea}/>
                </td>
              </tr>
              <tr>
                <th><label className="tableGrayLabel">Licensing Criteria (Route)</label></th>
                <td colSpan="3">
                  <CheckBoxGroup name="route" ref={ref(forms)} values={organization?.route} valueGroup={DataRoute}/>
                </td>
              </tr>
              <tr>
                <th><label className="tableGrayLabel">Licensing Criteria (Modality)</label></th>
                <td colSpan="3">
                  <CheckBoxGroup name="modality" ref={ref(forms)} values={organization?.modality} valueGroup={DataModality}/>
                </td>
              </tr>
              <tr>
                <th><label className="tableGrayLabel">Licensing Criteria<br/>(Development Stage)</label></th>
                <td colSpan="3">
                  <CheckBoxGroup name="developmentStage" ref={ref(forms)} values={organization?.developmentStage} valueGroup={DataDevelopmentStage}/>
                </td>
              </tr>
              <tr>
                <th><label className="tableGrayLabel">Licensing Criteria (Territory)</label></th>
                <td colSpan="3">
                  <CheckBoxGroup name="territoryForInLicensing" ref={ref(forms)} values={organization?.territoryForInLicensing} valueGroup={DataTerritoryForInLicensing}/>
                </td>
              </tr>
              <tr>
                <th style={{paddingTop:"26px"}}><label className="inputBoxLabel" htmlFor="input1Line-ID--licensingCriteria">Specific Requirements</label></th>
                <td colSpan="3"><Input1Line name="licensingCriteria" ref={ref(forms)} defaultValue={organization?.licensingCriteria} size="500px"/></td>
              </tr>
              <tr>
                <th style={{paddingTop:"26px"}}><label className="inputBoxLabel" htmlFor="input1Line-ID--url">Website</label></th>
                <td colSpan="3"><Input1Line name="url" size="500px" ref={ref(forms)} defaultValue={organization?.url}/></td>
              </tr>
              </tbody>
            </table>
            <div className="buttonBoxCenter">
              <Button label="Cancel" size="150px" type="gray" onClick={() => history.goBack()}/>
              <Button label="Save" size="150px" onClick={() => dispatch(updateOrganization(resolveData(), history))}/>
            </div>
          </Card>
        </div>
      </main>
    </div>
  );
};
