import React, {useContext, useEffect, useState} from 'react';
import PropTypes from 'prop-types';

export const MenuContext = React.createContext({});

export const MenuProvider = ({children}) => {
  const [currentMenuId, setCurrentMenuId] = useState(['']);
  return (
    <MenuContext.Provider value={{currentMenuId, setCurrentMenuId}}>
      {children}
    </MenuContext.Provider>
  );
};

export const MenuPlot = ({menuId}) => {
  const {currentMenuId, setCurrentMenuId} = useContext(MenuContext);
  useEffect(() => {
    if (currentMenuId !== menuId) {
      setCurrentMenuId(menuId);
    }
  }, [currentMenuId, setCurrentMenuId, menuId]);
  return (
    <span style={{display: 'none'}} key={menuId}/>
  );
};

MenuPlot.propTypes = {
  menuId: PropTypes.string,
};

MenuPlot.defaultProps = {
  menuId: '',
};
