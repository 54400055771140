import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import { PageNavi } from '../../parts/PageNavi';
import { RequestTitle } from '../../parts/RequstTitle';
import { Card } from '../../parts/Card';
import ScrollContainer from 'react-indiana-drag-scroll'

import '../base.css';
import { findNcRequestByAssets } from '../../actions/nc_request';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { fd, useQuery } from '../../parts/refs';
import { useTitle } from '../../parts/useTitle';

export const AssetDetailNcd = () => {
  useTitle('NCD disclosure list');

  const query = useQuery();
  const dispatch = useDispatch();
  const {assetId} = useParams();
  const page = parseInt(query.get('page') || '0');

  useEffect(() => {
    dispatch(findNcRequestByAssets(query, assetId));
  }, [dispatch, query, assetId]);

  const [ncRequestsByAssets, requestSummary, total] = useSelector(s => [s.nc_request.ncRequestsByAssets || [], s.nc_request.requestSummary, s.nc_request.total]);

  return (
    <div id="container" className="layout-login contsAssetDetailList">
      <main>
        <RequestTitle title="NCD disclosure list" request={requestSummary}/>
        <div id="containerMain">
          <Card>
            <div className="accountBody">
            <ScrollContainer className="scroll-container" hideScrollbars="false" vertical="false">
              <table className="tableGray tableGrayThHorizontal">
                <thead>
                <tr>
                  <th>NCD sent date</th>
                  <th>Licensee</th>
                </tr>
                </thead>
                <tbody>
                {ncRequestsByAssets.map(o => {
                  return (
                    <tr key={`assetDetailNcd:${o._id}`}>
                      <td>{fd(o?.approvedByOriginatorAt) || '*unknown'}</td>
                      <td><Link to={`/gemseki/licensee/${o?.licenseeOrgId}`}><strong>{o?.licenseeOrgName}</strong></Link></td>
                    </tr>
                  );
                })}
                {!ncRequestsByAssets?.length && (
                  <tr>
                    <td colSpan={2}>
                      <div className="tableCenter">No results.</div>
                    </td>
                  </tr>
                )}
                </tbody>
              </table>
              </ScrollContainer>
              {ncRequestsByAssets?.some(o => !o.approvedByOriginatorAt) &&
                <div className="unknownMessage">If the disclosure date column is "*unknown", this is because the status was changed to "Pending" without going through "Approved by Originator".</div>
              }
            </div>
            <PageNavi selected={page} total={total}/>
          </Card>
        </div>
      </main>
    </div>
  );
};
