import React, { useEffect } from 'react';

import { Card } from '../../parts/Card';
import { ListMessage } from '../../parts/ListMessage';
import { PageNavi } from '../../parts/PageNavi';
import {Search} from '../../parts/Search';
import {InputSelect} from '../../parts/InputSelect';

import '../base.css';

import { getNames } from '../../actions/core';
import { useDispatch, useSelector } from 'react-redux';
import { findRoomsByGemseki } from '../../actions/asset_room';
import { ShortMessageBody } from '../../parts/MessageBody';
import { useQuery, ref } from '../../parts/refs';
import { useTitle } from '../../parts/useTitle';
import { SortBy } from '../../parts/SortBy';

export const MessageList = () => {
  useTitle('Message list');

  const dispatch = useDispatch();
  const query = useQuery();
  const page = parseInt(query.get('page') || '0');

  useEffect(() => {
    dispatch(getNames());
  }, [dispatch]);

  useEffect(() => {
    dispatch(findRoomsByGemseki(query));
  }, [dispatch, query]);

  const [rooms, total, messageCount, user, originatorNames, licenseeNames, assetNumbers] = useSelector(s => [s.asset_room.rooms || [], s.asset_room.total || 1,s.dashboard.messageCount || 0, s.core.user, s.core.originatorNames || [], s.core.licenseeNames || [], s.asset.assetNumbers || []]);
  const pForms = {};
  const resolveParams = () => {
    const params = {};
    params.assetId = pForms.assetId.value;
    params.licenseeOrgId = pForms.licenseeOrgId.value;
    params.originatorOrgId = pForms.originatorOrgId.value;
    return params;
  };

  return (
    <div id="container" className="layout-login contsMessageList">
      <main>
        <h1>Message list{messageCount !== 0 && <span className='badge'>{messageCount}</span>}</h1>
        <div id='containerMain'>
          <Search selectType="messageList" resolveParams={resolveParams}>
            <InputSelect name="assetId" label="Asset No." ref={ref(pForms)} value={query.get('assetId')} placeholder="..." valueGroup={assetNumbers}/>
            <InputSelect name="licenseeOrgId" label="Licensee" ref={ref(pForms)} value={query.get('licenseeOrgId')} placeholder="..." valueGroup={licenseeNames}/>
            <InputSelect name="originatorOrgId" label="Originator" ref={ref(pForms)} value={query.get('originatorOrgId')} placeholder="..." valueGroup={originatorNames}/>
          </Search>
          <SortBy selected={0}/>
          {rooms.map(room => (
            <Card key={`room:${room._id}`} size="full">
              <ListMessage room={room} user={user} unread={!room.isRead} header={true}>
                <ShortMessageBody room={room} message={room.latestMessage}/>
              </ListMessage>
            </Card>
          ))}
          {!rooms?.length && (
            <Card>
              <div className="cardCenter">No messages.</div>
            </Card>
          )}
          <PageNavi selected={page} total={total}/>
        </div>
      </main>
    </div>
  );
};
