import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import { PageNavi } from '../../parts/PageNavi';
import { RequestTitle } from '../../parts/RequstTitle';
import { Card } from '../../parts/Card';
import ScrollContainer from 'react-indiana-drag-scroll'

import '../base.css';
import { findCdaRequestByAssets } from '../../actions/cda_request';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { fd, useQuery } from '../../parts/refs';
import { useTitle } from '../../parts/useTitle';

export const AssetDetailCda = () => {
  useTitle('CDA conclusion list');

  const query = useQuery();
  const dispatch = useDispatch();
  const {assetId} = useParams();
  const page = parseInt(query.get('page') || '0');

  useEffect(() => {
    dispatch(findCdaRequestByAssets(query, assetId));
  }, [dispatch, query, assetId]);

  const [cdaRequestsByAssets, requestSummary, total] = useSelector(s => [s.cda_request.cdaRequestsByAssets || [], s.cda_request.requestSummary, s.cda_request.total]);

  return (
    <div id="container" className="layout-login contsAssetDetailList">
      <main>
        <RequestTitle title="CDA conclusion list" request={requestSummary}/>
        <div id="containerMain">
          <Card>
            <div className="accountBody">
            <ScrollContainer className="scroll-container" hideScrollbars="false" vertical="false">
              <table className="tableGray tableGrayThHorizontal">
                <thead>
                <tr>
                  <th>Date (CDA Executed)</th>
                  <th>Licensee</th>
                </tr>
                </thead>
                <tbody>
                {cdaRequestsByAssets.map(o => {
                  return (
                    <tr>
                      <td>{fd(o?.evaluatingAt) || '*unknown'}</td>
                      <td><Link to={`/gemseki/licensee/${o?.licenseeOrgId}`}><strong>{o?.licenseeOrgName}</strong></Link></td>
                    </tr>
                  );
                })}
                {!cdaRequestsByAssets?.length && (
                  <tr>
                    <td colSpan={2}>
                      <div className="tableCenter">No results.</div>
                    </td>
                  </tr>
                )}
                </tbody>
              </table>
              </ScrollContainer>
             {cdaRequestsByAssets?.some(o => !o.evaluatingAt) &&
                <div className="unknownMessage">If the conclusion date column is "*unknown", this is because the status was changed to "Pending" without going through "Evaluating".</div>
              }
            </div>
            <PageNavi selected={page} total={total}/>
          </Card>
        </div>
      </main>
    </div>
  );
};
