import React from 'react';
import './assetMarks.css';
import PropTypes from 'prop-types';
import { isMark } from "./refs";

export class AssetMarks extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const marks = this.props.marks || {featured: null, updated: null};
    return (
      <div className="assetMarks">
        <span className={'assetMark-new'} style={{display: isMark(marks.created) ? 'inline-block' : 'none'}}>New</span>
        <span className={'assetMark-featured'} style={{display: isMark(marks.featured) ? 'inline-block' : 'none'}}>Featured</span>
        <span className={'assetMark-updated'} style={{display: isMark(marks.updated) ? 'inline-block' : 'none'}}>Updated</span>
      </div>
    );
  }
}

AssetMarks.propTypes = {
  /**
   * アセットマーク
   */
  marks: PropTypes.object,
};

AssetMarks.defaultProps = {};
