import './App.css';
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { Routes } from "./pages/Routes";
import store from "./store";
import { MenuProvider } from './parts/Menu';

function App() {
  return (
    <Provider store={store}>
      <MenuProvider>
        <BrowserRouter basename={process.env.PUBLIC_URL}>
          <div className="App">
            <Routes/>
          </div>
        </BrowserRouter>
      </MenuProvider>
    </Provider>
  );
}

export default App;
