import React, { useEffect, useState } from 'react';

import '../base.css';

import { CardNew } from '../../parts/CardNew';
import { ListMessage } from '../../parts/ListMessage';

import { useDispatch, useSelector } from 'react-redux';
import { loadLicenseeDashboard } from '../../actions/dashboard';
import { ShortMessageBody } from '../../parts/MessageBody';
import { Card } from '../../parts/Card';
import { useTitle } from '../../parts/useTitle';
import { Link } from "react-router-dom";
import TutorialImg from '../../parts/assets/tutorial_dashboard.png';

export const Dashboard = () => {
  useTitle('Dashboard');

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadLicenseeDashboard());
  }, [dispatch]);

  const [
    messageCount,
    messages,
    user,
    closedTutorial,
  ] = useSelector(s => [
    s.dashboard.messageCount || 0,
    s.dashboard.messages || [],
    s.core.user,
    s.dashboard.closedTutorial,
  ]);
  
  var [tutorialBoolOpen, setTutorialBoolOpen] = useState(true);
  const tutorialOpenClose = (b) => {
    setTutorialBoolOpen(tutorialBoolOpen = b);
  };

  return (
    <div id="container" className="layout-login contsDashboard">

      <main>
        <h1>Dashboard</h1>

        <div id='containerMain'>
          {(!closedTutorial && tutorialBoolOpen) && 
            <div className='tutorial'>
              <img src={TutorialImg} alt='tutorial'></img>
              <div className='tutorialMain'>
                <p>Use the keyword search and filters to customize a list of assets you might be interested in. You can also download the list in CSV format.</p>
                <Link to={`/licensee/assets`} className='tutorialLink'>Asset search&nbsp;&nbsp;&gt;</Link>
              </div>
              <button className='tutorialClose' onClick={() => tutorialOpenClose()}></button>
            </div>
          }
          <div className='contsDashboardHeader'>
            <h2 className='h2mail'>New message{messageCount !== 0 && <span className='badge'>{messageCount}</span>}</h2>
            <Link to={`/licensee/rooms`} className='contsDashboardHeaderMail'>Message list&nbsp;&nbsp;&gt;</Link>
          </div>
          {messages?.length ? (
            <CardNew mode="Message" user="Licensee">
              {messages.map(room => (
                <ListMessage key={`dashboardRoom:${room._id}`} room={room} user={user} unread={true}>
                  <ShortMessageBody room={room} message={room.latestMessage} isGemseki={false}/>
                </ListMessage>
              ))}
            </CardNew>
          ) : (
            <Card>
              <div className="cardCenter">No new messages.</div>
            </Card>
          )}
        </div>
      </main>
    </div>
  );
};
