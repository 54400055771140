import React, { useEffect } from 'react';

import '../base.css';
import { ButtonBack } from '../../parts/ButtonBack';
import { Input1Line } from '../../parts/Input1Line';
import { InputSelect } from '../../parts/InputSelect';
import { CheckBoxGroup } from '../../parts/CheckBoxGroup';
import { Button } from '../../parts/Button';
import { useCountries } from '../../parts/useCountries';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { DataDevelopmentStage, DataDiseaseArea, DataModality, DataRoute, DataTerritoryForInLicensing, DataTypeOfCompanyLicensee } from '../../reducers/datas';
import { getLicensee, updateLicensee } from '../../actions/core';
import { fd, ref } from '../../parts/refs';
import { useTitle } from '../../parts/useTitle';
import { Card } from '../../parts/Card';

export const LicenseeEdit = () => {
  useTitle('Licensee edit');

  const {licenseeId} = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(getLicensee(licenseeId));
  }, [dispatch, licenseeId]);

  const [licensee] = useSelector(s => [s.core.licensee]);

  const forms = {};

  const resolveData = () => {
    return {
      _id: licenseeId,
      organizationName: forms.organizationName.value,
      area: forms.area.value,
      country: countryRef.current.value,
      typeOfCompany: forms.typeOfCompany.values,
      diseaseArea: forms.diseaseArea.values,
      route: forms.route.values,
      modality: forms.modality.values,
      developmentStage: forms.developmentStage.values,
      territoryForInLicensing: forms.territoryForInLicensing.values,
      licensingCriteria: forms.licensingCriteria.value,
      url: forms.url.value,
    };
  };

  const [areas, countries, areaCallback, countryRef] = useCountries(licensee?.area);
  return (
    <div id="container" className="layout-login contsOrganizationEdit">
      <main>
        <h1><ButtonBack/>Licensee edit</h1>
        <div id="containerMain">
        <Card>
        <table className="tableGray tableGrayThVertical">
          <tbody>
          <tr>
            <th><label className="tableGrayLabel">Date (Company Registered)</label></th>
            <td><p>{fd(licensee?.createdAt)}</p></td>
            <th><label className="tableGrayLabel">Date (Company Updated)</label></th>
            <td><p>{fd(licensee?.updatedAt)}</p></td>
          </tr>
          <tr>
            <th style={{paddingTop:"26px"}}><label className="tableGrayLabel">Company ID</label></th>
            <td style={{paddingTop:"26px"}}><p>{licensee?.displayId}</p></td>
            <th style={{paddingTop:"26px"}}><label className="tableGrayLabel" htmlFor="input1Line-ID--licensee">Company</label></th>
            <td><Input1Line name="organizationName" ref={ref(forms)} size="100%" defaultValue={licensee?.organizationName}/></td>
          </tr>
          <tr>
            <th style={{paddingTop:"26px"}}><label className="tableGrayLabel">Area, Country</label></th>
            <td colSpan="3">
              <div className="buttonBoxL">
                <InputSelect name="area" ref={ref(forms)} size="185px" placeholder="Area" valueGroup={areas} value={licensee?.area} onChange={areaCallback}/>
                <InputSelect name="country" size="290px" placeholder="Country" valueGroup={countries} value={licensee?.country} ref={countryRef}/>
              </div>
            </td>
          </tr>
          <tr>
            <th><label className="tableGrayLabel">Type of Company</label></th>
            <td colSpan="3">
              <CheckBoxGroup name="typeOfCompany" ref={ref(forms)} valueGroup={DataTypeOfCompanyLicensee} values={licensee?.typeOfCompany}/>
            </td>
          </tr>
          <tr>
            <th><label className="tableGrayLabel">Licensing Criteria<br/>(Disease Area)</label></th>
            <td colSpan="3">
              <CheckBoxGroup name="diseaseArea" ref={ref(forms)} valueGroup={DataDiseaseArea} values={licensee?.diseaseArea}/>
            </td>
          </tr>
          <tr>
            <th><label className="tableGrayLabel">Licensing Criteria (Route)</label></th>
            <td colSpan="3">
              <CheckBoxGroup name="route" ref={ref(forms)} valueGroup={DataRoute} values={licensee?.route}/>
            </td>
          </tr>
          <tr>
            <th><label className="tableGrayLabel">Licensing Criteria (Modality)</label></th>
            <td colSpan="3">
              <CheckBoxGroup name="modality" ref={ref(forms)} valueGroup={DataModality} values={licensee?.modality}/>
            </td>
          </tr>
          <tr>
            <th><label className="tableGrayLabel">Licensing Criteria<br/>(Development Stage)</label></th>
            <td colSpan="3">
              <CheckBoxGroup name="developmentStage" ref={ref(forms)} valueGroup={DataDevelopmentStage} values={licensee?.developmentStage}/>
            </td>
          </tr>
          <tr>
            <th><label className="tableGrayLabel">Licensing Criteria (Territory)</label></th>
            <td colSpan="3">
              <CheckBoxGroup name="territoryForInLicensing" ref={ref(forms)} valueGroup={DataTerritoryForInLicensing} values={licensee?.territoryForInLicensing}/>
            </td>
          </tr>
          <tr>
            <th style={{paddingTop:"26px"}}><label className="inputBoxLabel" htmlFor="input1Line-ID--licensingCriteria">Specific Requirements</label></th>
            <td colSpan="3"><Input1Line name="licensingCriteria" ref={ref(forms)} size="500px" defaultValue={licensee?.licensingCriteria}/></td>
          </tr>
          <tr>
            <th style={{paddingTop:"26px"}}><label className="inputBoxLabel" htmlFor="input1Line-ID--url">Website</label></th>
            <td colSpan="3"><Input1Line name="url" ref={ref(forms)} size="500px" defaultValue={licensee?.url}/></td>
          </tr>
          </tbody>
        </table>
        <div className="buttonBoxCenter">
          <Button label="Cancel" size="150px" type="gray" onClick={() => history.goBack()}/>
          <Button label="Save" size="150px" onClick={() => dispatch(updateLicensee(resolveData(), history))}/>
        </div>
        </Card>
        </div>
      </main>
    </div>
  );
};
