import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useHistory, useParams } from 'react-router';

import { ButtonBack } from '../../parts/ButtonBack';
import { Button } from '../../parts/Button';
import { InputSelect } from '../../parts/InputSelect';
import { Popup } from '../../parts/Popup';

import '../base.css';
import '../../parts/memberList.css';
import { useDispatch, useSelector } from 'react-redux';
import { getGemsekiUsers, getIntroducers, getMembers, getOriginator, updateOrganizationStatus } from '../../actions/core';
import { fd } from '../../parts/refs';
import { MemberList } from '../../parts/MemberList';
import { useTitle } from '../../parts/useTitle';
import { Card } from '../../parts/Card';

export const OriginatorDetail = () => {
  useTitle('Originator details');

  const {originatorId} = useParams();
  const dispatch = useDispatch();

  const statusRef = useRef(null);
  const popupValid = useRef(null);
  const popupInvalid = useRef(null);

  useEffect(() => {
    dispatch(getOriginator(originatorId));
    dispatch(getGemsekiUsers());
    dispatch(getIntroducers());
    dispatch(getMembers(originatorId));
  }, [dispatch, originatorId]);

  const [originator, gemsekiUsers, members] = useSelector(s => [s.core.originator, s.core.gemsekiUsers || [], s.core.members || []]);
  const status = originator?.status;

  const gemsekiName = (id) => {
    const user = gemsekiUsers.find(u => u._id === id);
    return user ? `${user.firstName} ${user.lastName}` : '';
  };

  const history = useHistory();

  const onValid = async () => {
    await dispatch(updateOrganizationStatus(originatorId, 'valid'));
    popupValid.current.hide();
  };

  const onInvalid = async () => {
    await dispatch(updateOrganizationStatus(originatorId, 'invalid'));
    popupInvalid.current.hide();
  };

  const onCancel = () => {
    statusRef.current.value = status;
  };

  return (
    <div id="container" className="layout-login contsOriginatorDetail">

      <main>
        <h1><ButtonBack/>Originator details</h1>
        <div id='containerMain'>
        <div className="accountBody">
          <InputSelect
            name="originatorOrgStatus"
            size="185px"
            placeholder="..."
            ref={statusRef}
            isClearable={false}
            value={status}
            valueGroup={[{label: 'Valid', value: 'valid'},{label: 'Invalid', value: 'invalid'}]}
            onChange={(item) => {
              if (item?.value === status) return;
              if (item?.value === 'invalid') {
                popupInvalid.current.show();
              } else {
                popupValid.current.show();
              }
            }}/>
          <Card>
          <table className="tableGray tableGrayThVertical">
            <tbody>
            <tr>
              <th><label className="tableGrayLabel">Date (Company Registered) </label></th>
              <td><p>{fd(originator?.createdAt)}</p></td>
              <th><label className="tableGrayLabel">Date (Company Updated)</label></th>
              <td><p>{fd(originator?.updatedAt)}</p></td>
            </tr>
            <tr>
              <th><label className="tableGrayLabel">Company ID</label></th>
              <td><p>{originator?.displayId}</p></td>
              <th><label className="tableGrayLabel">Company</label></th>
              <td><p>{originator?.organizationName}</p></td>
            </tr>
            <tr>
              <th><label className="tableGrayLabel">Area</label></th>
              <td><p>{originator?.area}</p></td>
              <th><label className="tableGrayLabel">Country</label></th>
              <td><p>{originator?.country}</p></td>
            </tr>
            <tr>
              <th><label className="tableGrayLabel">Type of Company</label></th>
              <td colSpan="3"><p>{originator?.typeOfCompany?.join('　　')}</p></td>
            </tr>
            <tr>
              <th><label className="tableGrayLabel">MSA Info</label></th>
              <td colSpan="3"><p style={{whiteSpace:"pre-wrap"}}>{originator?.msaInfo}</p></td>
            </tr>
            <tr>
              <th><label className="tableGrayLabel">Note for originator</label></th>
              <td colSpan="3"><p style={{whiteSpace:"pre-wrap"}}>{originator?.noteForOriginator}</p></td>
            </tr>
            <tr>
              <th><label className="tableGrayLabel">Website</label></th>
              <td colSpan="3"><p><a href={originator?.url || ''} target="organization_url">{originator?.url}</a></p></td>
            </tr>
            <tr>
              <th><label className="tableGrayLabel">PM</label></th>
              <td colSpan="3"><p>{originator?.gemsekiPic?.map(gemsekiName)?.join('　　')}</p></td>
            </tr>
            <tr>
              <th><label className="tableGrayLabel">Introduced by</label></th>
              <td colSpan="3"><p>{originator?.introducer?.join('　　')}</p></td>
            </tr>
            </tbody>
          </table>
          </Card>
          <div className="buttonBoxL">
            <Button label="Edit" size="111px" onClick={() => history.push(`/gemseki/originator/${originatorId}/edit`)}/>
          </div>
        </div>
        <div className="accountBody">
          <h2>Member list</h2>
          <Card>
            <MemberList editMode={false} isLinkToMember={true} organization={originator} valueGroup={members}/>
          </Card>
          <div className="buttonBoxL">
            <Button label="Edit" size="111px" onClick={() => history.push(`/gemseki/originator/${originatorId}/member`)}/>
          </div>
        </div>
        <div className="accountBody">
          <h2>Asset list</h2>
          <div className="assetListBox">
            <div className="assetListCase">
              <h3>Drug Candidates</h3>
              <Card>
              <table className="tableGray tableGrayThHorizontal">
                <thead>
                <tr>
                  <th>Date<br/>(Asset Registered)</th>
                  <th>Asset No.</th>
                </tr>
                </thead>
                <tbody>
                {
                  originator?.seedsAssets?.map(seeds => (
                    <tr key={`seeds:${seeds._id}`}>
                      <td>{fd(seeds.createdAt)}</td>
                      <td><Link to={`../asset/${seeds._id}`}>{seeds.assetNumber}</Link></td>
                    </tr>
                  ))
                }
                </tbody>
              </table>
              </Card>
            </div>
            <div className="assetListCase">
              <h3>Technologies</h3>
              <Card>
              <table className="tableGray tableGrayThHorizontal">
                <thead>
                <tr>
                  <th>Date<br/>(Asset Registered)</th>
                  <th>Asset No.</th>
                </tr>
                </thead>
                <tbody>
                {
                  originator?.technologiesAssets?.map(tech => (
                    <tr key={`tech:${tech._id}`}>
                      <td>{fd(tech.createdAt)}</td>
                      <td><Link to={`../asset/${tech._id}`}>{tech.assetNumber}</Link></td>
                    </tr>
                  ))
                }
                </tbody>
              </table>
              </Card>
            </div>
          </div>
        </div>

        </div>
      </main>
      <Popup onSubmit={onValid} onCancel={onCancel} title="Are you sure you would like to make this Originator valid?" submitButton="OK" ref={popupValid} titleAlign="center">
        <p>Assets owned by this Originator will be displayed in a list.</p>
      </Popup>
      <Popup onSubmit={onInvalid} onCancel={onCancel} title="Are you sure you would like to make this Originator invalid?" submitButton="OK" ref={popupInvalid} titleAlign="center">
        <p>Assets owned by this Originator will be hidden from the list.</p>
      </Popup>
    </div>
  );
};
