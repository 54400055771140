import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import './asideList.css';
import {MenuContext} from './Menu';

export const AsideList = (
  {
    active,
    secondary,
    label,
    count,
    to,
    menuId,
    open,
    ...props
  }) => {
  const {currentMenuId, setCurrentMenuId} = useContext(MenuContext);
  return (
    <li
      className={['asideList', `asideList--${currentMenuId === menuId || active}`, `asideListIndent--${secondary}`, `${open ? '' : 'closeSideMenuChild'}`].join(' ')}
      {...props}
    >
      <Link to={to} onClick={() => setCurrentMenuId(menuId)}>{open ? label : ''}{(count > 0) && open ? <span>{count}</span> : ''}</Link>
    </li>
  );
};

AsideList.propTypes = {
  /**
   * アクティブかどうか
   */
  active: PropTypes.bool,
  /**
   * 1つ深い階層かどうか
   */
  secondary: PropTypes.bool,
  /**
   * リストの表示名
   */
  label: PropTypes.string.isRequired,
  /**
   * 入力エリアの幅
   */
  count: PropTypes.number,
  /**
   * 遷移先
   */
  to: PropTypes.string,
  /**
   * メニューID
   */
  menuId: PropTypes.string,
};

AsideList.defaultProps = {
  label: 'List Name',
  active: false,
  secondary: false,
  count: 0,
  to: '/',
  menuId: '',
  open: true,
};
