import React from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import './inputSelect.css';

export class InputSelect extends React.Component {
  constructor(props) {
    super(props);
    this.selectRef = React.createRef();
    this.state = {selected: this.props.valueGroup?.find(v => v.value === this.props.value)};
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.valueGroup === prevProps.valueGroup && this.props.value === prevProps.value) {
      return;
    }

    const next = this.props.valueGroup?.find(v => v.value === this.props.value) || null;
    this.setState({selected: next});
  }

  get name() {
    return this.props.name;
  }

  get value() {
    return this.state.selected?.value;
  }

  set value(value) {
    const next = this.props.valueGroup?.find(v => v.value === value) || null;
    this.setState({selected: next});
  }

  _onChange(item) {
    this.value = item?.value;
    this.props.onChange?.call(this, item);
  }

  render() {
    const {
      name,
      label,
      validationName,
      size,
      valueGroup,
      placeholder,
      isClearable,
    } = this.props;

    const selectStyles = {
      menu: (provided, state) => ({
        ...provided,
        width: state.selectProps.width,
      }),

      control: (provided, state) => ({
        ...provided,
        padding: '4px 8px',
        width: state.selectProps.width,
      }),

      singleValue: (provided) => {
        return {
          ...provided,
          paddingBottom: '0.3em',
          paddingTop: '0.3em',
          marginBottom: '-0.3em',
          marginTop: '-0.3em',
        };
      },
    };
    const options = valueGroup;
    const customFilter = (option, searchText) => {
      const target = option.label.toLowerCase();
      const search = searchText.toLowerCase();
      return target.includes(search);
    };
    const selectTag = isClearable ?
      (<Select
        isClearable
        className={['inputSelect', this.state.selected ? '' : 'default', `input-${validationName || name}`].join(' ')}
        id={`inputSelect-ID--${name}`}
        styles={selectStyles}
        width={size}
        ref={this.selectRef}
        value={this.state.selected}
        placeholder={placeholder}
        onChange={(item) => this._onChange(item)}
        options={options}
        filterOption={customFilter}
      />)
    :
      (<Select
        className={['inputSelect', this.state.selected ? '' : 'default'].join(' ')}
        id={`inputSelect-ID--${name}`}
        styles={selectStyles}
        width={size}
        ref={this.selectRef}
        value={this.state.selected}
        placeholder={placeholder}
        onChange={(item) => this._onChange(item)}
        options={options}
        filterOption={customFilter}
      />);

    if (label !== "") {
      return (
        <label className='inputSelectLabel'>
          <span>{label}</span>
          {selectTag}
        </label>
      );
    } else {
      return (
        selectTag
      );
    }
  }
}

InputSelect.propTypes = {
  /**
   * 要素の名前　重複注意
   */
  name: PropTypes.string.isRequired,
  /**
   * バリデーションエラー時に要素判別用として利用する名前（省略時は name）
   */
  validationName: PropTypes.string,
  /**
   * ラベル
   */
  label: PropTypes.string,
  /**
   * 入力エリアの幅
   */
  size: PropTypes.string,
  /**
   * 選択中の値
   */
  value: PropTypes.string,
  /**
   * チェックリストの配列
   */
  valueGroup: PropTypes.array,
  /**
   * placeholder
   */
  placeholder: PropTypes.string,
  /**
   * clearできるか
   */
  isClearable: PropTypes.bool,
  /**
   * 変更イベントコールバック
   */
  onChange: PropTypes.func,
};

InputSelect.defaultProps = {
  isClearable: true,
  name: 'name',
  label: '',
  placeholder: 'Select',
  size: '200px',
  valueGroup: [
    {label: 'label1', value: 'value1'},
    {label: 'label2', value: 'value2'},
    {label: 'label3', value: 'value3'},
  ],
};
