import { api } from './api.js';
import { clearError, defaultErrorHandler, isError } from './core';
import { RECEIVE_ASSET } from './asset';

export const REQUEST_NC_REQUEST = 'REQUEST_NC_REQUEST';
export const RECEIVE_NC_REQUEST = 'RECEIVE_NC_REQUEST';

const copyQuery = (original, ...keys) => {
  const query = new URLSearchParams();
  for (const key of keys) {
    if (original.has(key)) {
      query.set(key, original.get(key));
    }
  }
  return query;
};

export const findNcRequestByLicensee = (query) => async (dispatch) => {
  const q = copyQuery(query, 'status', 'page', 'words', 'route', 'modality', 'developmentStage', 'diseaseArea', 'order', 'dir');

  q.set('limit', '4');

  const res = await api.get(dispatch, `nc_request/find_by_licensee?${q}`);
  if (isError(res, dispatch)) return;

  const totalCount = res.body.total;
  const total = Math.ceil(totalCount / 4);
  const ncRequestsByLicensee = res.body.data;

  dispatch({
    type: RECEIVE_NC_REQUEST,
    totalCount,
    total,
    ncRequestsByLicensee,
  });
};

export const findNcRequestByGemseki = (query) => async (dispatch) => {
  const q = copyQuery(query, 'status', 'page', 'words', 'developmentStage', 'diseaseArea', 'licenseeOrgId', 'originatorOrgId', 'order', 'dir');

  q.set('limit', '4');

  const res = await api.get(dispatch, `nc_request/find_by_gemseki?${q}`);
  if (isError(res, dispatch)) return;

  const total = Math.ceil(res.body.total / 4);
  const ncRequestsByGemseki = res.body.data;

  dispatch({
    type: RECEIVE_NC_REQUEST,
    total,
    ncRequestsByGemseki,
  });
};

export const findNcRequestByAssets = (query, assetId) => async (dispatch) => {
  const q = copyQuery(query, 'page');

  q.set('limit', '8');
  q.set('assetId', assetId);

  const res = await api.get(dispatch, `nc_request/find_by_asset?${q}`);
  if (isError(res, dispatch)) return;

  const total = Math.ceil(res.body.total / 8);
  const requestSummary = res.body.requestSummary;
  const ncRequestsByAssets = res.body.data;

  dispatch({
    type: RECEIVE_NC_REQUEST,
    total,
    requestSummary,
    ncRequestsByAssets,
  });
};

const statusErrorHandler = (res, dispatch) => {
  if (res.status === 409 && res.body.conflict?.status) {
    return {ok: false, message: 'Update failed because the same NCD request already exists or status change is not allowed.'};
  } else {
    defaultErrorHandler(res, dispatch);
    return {ok: false, message: 'Failed to update status.'};
  }
};

export const requestNcd = (assetId, licenseeOrgId, comment = '') => async (dispatch) => {
  dispatch(clearError());

  const res = await api.post(dispatch, `nc_request`, {assetId, licenseeOrgId, comment});
  return isError(res, dispatch, statusErrorHandler, () => ({ok: true}));
};

export const requestNcds = (assets, licenseeOrgId, comment = '') => async (dispatch, getState) => {
  dispatch(clearError());

  const s = getState();
  let bag = s.asset.bag || [];

  try {
    const assetIds = assets.map(a => a._id);
    const res = await api.post(dispatch, `nc_request/multi`, {assetIds, licenseeOrgId, comment});
    if (!res.ok) {
      if (res.status !== 409) {
        defaultErrorHandler(res, dispatch);
        return {ok: false, message: 'Failed to update status.'};
      }
    } else {
      bag = [];
    }
  } finally {
    dispatch({
      type: RECEIVE_ASSET,
      bag,
    });
  }

  return bag.length ? {ok: false, message: 'Update failed because the same NCD request already exists or status change is not allowed.'} : {ok: true};
};

export const updateNcd = (ncId, status, comment = '', reasons = []) => async (dispatch) => {
  dispatch(clearError());

  const res = await api.put(dispatch, `nc_request/${ncId}/status`, {status, reasons, comment});
  return isError(res, dispatch, statusErrorHandler, () => ({ok: true}));
};
