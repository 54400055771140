import React, { useCallback, useEffect, useRef } from 'react';

import { ChatTitle } from '../../parts/ChatTitle';
import { ChatTalk } from '../../parts/ChatTalk';
import { ChatSend } from '../../parts/ChatSend';

import '../base.css';
import '../messageDetail.css';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { getRoom, loadNewMessages, loadOldMessages, sendMessage } from '../../actions/asset_room';
import { MessageBody, resolveContributor, resolveDirection } from '../../parts/MessageBody';
import { fd, ft } from '../../parts/refs';
import { useScroll } from '../../parts/useScrollPos';
import { loadGemsekiCounts } from '../../actions/dashboard';
import { useTitle } from '../../parts/useTitle';

export const MessageDetail = () => {
  useTitle('Message Detail');

  const dispatch = useDispatch();
  const {roomId} = useParams();

  const [room, messages, newestId, latestId, endMessages, user] = useSelector(s => [
    s.asset_room.room,
    s.asset_room.messages,
    s.asset_room.newestId,
    s.asset_room.latestId,
    s.asset_room.endMessages,
    s.core.user,
  ]);

  const loading = useRef(false);
  const scrollCallback = useCallback(async (scrollCurrent) => {
    if (endMessages || loading.current) return;
    loading.current = true;
    scrollCurrent.oldHeight = scrollCurrent.body.clientHeight;
    if (scrollCurrent.position < 100) {
      await dispatch(loadOldMessages(roomId));
    }
    loading.current = false;
  }, [dispatch, roomId, loading, endMessages]);
  const scroll = useScroll(scrollCallback);

  useEffect(() => {
    async function loadRoomAndUpdateCounts() {
      await dispatch(getRoom(roomId));
      await dispatch(loadGemsekiCounts());
    }

    loadRoomAndUpdateCounts().then();
  }, [dispatch, roomId]);

  useEffect(() => {
    const {body, container, oldHeight} = scroll.current;
    if (!latestId || !oldHeight) return;
    container.scrollTop = container.scrollTop + body.clientHeight - oldHeight;
  }, [latestId, scroll]);

  useEffect(() => {
    const {body, container} = scroll.current;
    container.scrollTop = body.clientHeight - window.innerHeight;
  }, [newestId, scroll]);

  return (
    <div id="container" className="layout-login contsMessageDetail">
      <main>
        <ChatTitle room={room} user={user}/>
        <div className="MessageDetailBody">
          {messages?.map(message => (
            <ChatTalk
              key={`message:${message._id}`}
              lr={resolveDirection(user, message)}
              contributor={resolveContributor(message)}
              postDate={fd(message.createdAt)}
              postTime={ft(message.createdAt)}>
              <MessageBody room={room} message={message} isGemseki={true}/>
            </ChatTalk>
          ))}
        </div>
        <ChatSend onSend={async (body) => {
          await dispatch(sendMessage(roomId, body));
          await dispatch(loadNewMessages(roomId));
          return true;
        }}/>
      </main>
    </div>
  );
};
