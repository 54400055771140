import React, { useEffect } from 'react';

import { ButtonBack } from '../../parts/ButtonBack';
import { Button } from '../../parts/Button';
import { Card } from '../../parts/Card';

import '../base.css';
import { useParams } from 'react-router';
import { getMember } from '../../actions/core';
import { useDispatch, useSelector } from 'react-redux';
import { fd } from '../../parts/refs';
import { useTitle } from '../../parts/useTitle';

export const OriginatorProfile = () => {
  useTitle('Originator user detail');

  const {originatorId, memberId} = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getMember(memberId));
  }, [dispatch, memberId]);

  const member = useSelector(s => s.core.member);

  return (
    <div id="container" className="layout-login contsLicenseeProfile">
      <main>
        <h1><ButtonBack/>Originator user detail</h1>
        <div id='containerMain'>
        <Card>

        <table className="tableGray tableGrayThVertical">
          <tbody>
          <tr>
            <th><label className="tableGrayLabel">Registration Date</label></th>
            <td><p>{fd(member?.createdAt)}</p></td>
            <th><label className="tableGrayLabel">Update Date</label></th>
            <td><p>{fd(member?.updatedAt)}</p></td>
          </tr>
          <tr>
            <th><label className="tableGrayLabel">User Name</label></th>
            <td><p>{member?.firstName} {member?.lastName}</p></td>
            <th><label className="tableGrayLabel">Job Title</label></th>
            <td><p>{member?.jobTitle}</p></td>
          </tr>
          <tr>
            <th><label className="tableGrayLabel">Email address</label></th>
            <td colSpan={3}><p><a href={`mailto:${member?.email}`} className="buttonSetting" target="mail">{member?.email}</a></p></td>
          </tr>
          </tbody>
        </table>
        </Card>
        <div className="buttonBoxL">
          <Button label="Edit" size="111px" to={`/gemseki/originator/${originatorId}/member/${memberId}/edit`}/>
        </div>
        </div>
      </main>
    </div>
  );
};
