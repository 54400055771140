import React, { useEffect, useMemo, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useHistory, useParams } from 'react-router';

import { ButtonBack } from '../../parts/ButtonBack';
import { InputSelect } from '../../parts/InputSelect';
import { Button } from '../../parts/Button';
import { NcdFiles } from '../../parts/NcdFiles';
import { Popup } from '../../parts/Popup';
import { Card } from '../../parts/Card';

import '../base.css';
import { useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { downloadNc, getAsset, updateAssetStatus } from '../../actions/asset';
import { fd } from '../../parts/refs';
import { getGemsekiUsers, getOriginatorNames } from '../../actions/core';
import { useTitle } from '../../parts/useTitle';
import StarImg from '../../parts/assets/icon-star-number.svg';

const EMPTY = [];

export const AssetDetail = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const {assetId} = useParams();

  const statusRef = useRef(null);
  const popupPrivate = useRef(null);
  const popupPublish = useRef(null);

  useEffect(() => {
    dispatch(getOriginatorNames());
    dispatch(getGemsekiUsers());
    dispatch(getAsset(assetId));
  }, [dispatch, assetId]);

  const [gemsekiUsers, assetDetail, names] = useSelector(s => [s.core.gemsekiUsers || [],s.asset.assetDetail, s.core.originatorNames || EMPTY]);
  const organizationId = assetDetail?.organizationId;
  const [name, originatorOrgStatus] = useMemo(() => {
    const org = names.find(n => n.value === organizationId);
    return [org?.label, org?.status];
  }, [organizationId, names]);

  useTitle(() => {
    if (assetDetail?._id === assetId && assetDetail?.assetNumber) {
      return `${assetDetail.assetNumber} | Asset detail`;
    } else {
      return false;
    }
  }, [assetId, assetDetail]);
  const gemsekiName = (id) => {
    const user = gemsekiUsers.find(u => u._id === id);
    return user ? `${user.firstName} ${user.lastName}` : '';
  };

  const isSeeds = assetDetail?.assetType === 'seeds';
  const isTech = assetDetail?.assetType === 'technologies';
  const status = assetDetail?.status;

  const onPrivate = async () => {
    await dispatch(updateAssetStatus(assetId, 'private'));
    popupPrivate.current.hide();
  };

  const onPublish = async () => {
    await dispatch(updateAssetStatus(assetId, 'public'));
    popupPublish.current.hide();
  };

  const onCancel = () => {
    statusRef.current.value = status;
  };

  return (
    <div id="container" className="layout-login contsAssetDetail">
      <main>
        <h1><ButtonBack/>{assetDetail?.assetNumber}<div className="starNumber"><img src={StarImg} alt="★"/>{assetDetail?.bookmarkCounts || 0}</div></h1>
        <div id='containerMain'>
        <div className="contsAssetStatus">
          <InputSelect
            name="release"
            value={status}
            ref={statusRef}
            isClearable={false}
            valueGroup={[
              {label: 'Public', value: 'public'},
              {label: 'Private', value: 'private'},
            ]}
            onChange={(item) => {
              if (item?.value === status) return;
              if (item?.value === 'public') {
                popupPublish.current.show();
              } else {
                popupPrivate.current.show();
              }
            }}
          />
        </div>
        <Card>
          <table className="tableGray tableGrayThVertical">
            <tbody>
            <tr>
              <th><label className="tableGrayLabel">Date (Asset Registered)</label></th>
              <td><p>{fd(assetDetail?.createdAt)}</p></td>
              <th><label className="tableGrayLabel">Date (Asset Updated)</label></th>
              <td><p>{fd(assetDetail?.updatedAt)}</p></td>
            </tr>
            <tr>
              <th><label className="tableGrayLabel">Asset No.</label></th>
              <td><p>{assetDetail?.assetNumber}</p></td>
              <th><label className="tableGrayLabel">Originator</label></th>
              <td><p><Link className={`isValid--${originatorOrgStatus}`} to={`../originator/${organizationId}`}><strong>{name}</strong></Link></p></td>
            </tr>
            {
              isSeeds &&
              <tr>
                <th><label className="tableGrayLabel">Mode of Action</label></th>
                <td colSpan={3}><p>{assetDetail?.mechanismOfAction}</p></td>
              </tr>
            }
            {
              isSeeds &&
              <tr>
                <th><label className="tableGrayLabel">Indication</label></th>
                <td colSpan={3}><p>{assetDetail?.indication}</p></td>
              </tr>
            }
            {
              isSeeds &&
              <tr>
                <th><label className="tableGrayLabel">Route</label></th>
                <td colSpan={3}><p>{assetDetail?.route?.join('　　')}</p></td>
              </tr>
            }
            {
              isSeeds &&
              <tr>
                <th><label className="tableGrayLabel">Modality</label></th>
                <td colSpan={3}><p>{assetDetail?.modality?.join('　　')}</p></td>
              </tr>
            }
            {
              isSeeds &&
              <tr>
                <th><label className="tableGrayLabel">Development Stage</label></th>
                <td colSpan={3}><p>{assetDetail?.developmentStage?.join('　　')}</p></td>
              </tr>
            }
            {
              isSeeds &&
              <tr>
                <th><label className="tableGrayLabel">Note</label></th>
                <td colSpan={3}>
                  <p style={{whiteSpace:"pre-wrap"}}>{assetDetail?.note}</p>
                </td>
              </tr>
            }
            {
              isSeeds &&
              <tr>
                <th><label className="tableGrayLabel">Disease Area</label></th>
                <td colSpan={3}><p>{assetDetail?.diseaseArea?.join('　　')}</p></td>
              </tr>
            }
            {
              isTech &&
              <tr>
                <th><label className="tableGrayLabel">Technology</label></th>
                <td colSpan={3}><p>{assetDetail?.technology}</p></td>
              </tr>
            }
            {
              isTech &&
              <tr>
                <th><label className="tableGrayLabel">Summary</label></th>
                <td colSpan={3}>
                  <p style={{whiteSpace:"pre-wrap"}}>{assetDetail?.summary}</p></td>
              </tr>
            }
            <tr>
              <th><label className="tableGrayLabel">Type of Preferred Partnering</label></th>
              <td colSpan={3}><p>{assetDetail?.typeOfPreferredPartnering}</p></td>
            </tr>
            <tr>
              <th><label className="tableGrayLabel">Licensing Available Area</label></th>
              <td colSpan={3}><p>{assetDetail?.territoryForLicensing}</p></td>
            </tr>
            <tr>
              <th><label className="tableGrayLabel">Licensing Unavailable Area</label></th>
              <td colSpan={3}><p>{assetDetail?.territoryNotAvailableForLicensing}</p></td>
            </tr>
            <tr>
              <th><label className="tableGrayLabel">Original Name of Compound</label></th>
              <td colSpan={3}><p>{assetDetail?.originalCompoundName}</p></td>
            </tr>
            <tr>
              <th><label className="tableGrayLabel">PM</label></th>
              <td colSpan={3}><p>{assetDetail?.gemsekiPic?.map(gemsekiName)?.join('　　')}</p></td>
            </tr>
            <tr>
              <th><label className="tableGrayLabel">Gemseki’s Memo</label></th>
              <td colSpan={3}><p>{assetDetail?.gemsekisNotes}</p></td>
            </tr>
            </tbody>
          </table>
        </Card>

        <Card><NcdFiles
          editMode={false}
          valueGroup={assetDetail?.ncs}
          onDownload={async (fileItem) => {
            dispatch(downloadNc(fileItem, assetId, ''));
          }}/></Card>
        <div className="buttonBoxL">
          <Button label="Edit" size="111px" onClick={() => history.push(`/gemseki/asset/${assetId}/edit`)}/>
          <Button label="NCD disclosure list" size="207px" onClick={() => history.push(`/gemseki/asset/${assetId}/ncd${location.hash}`)}/>
          <Button label="CDA conclusion list" size="207px" onClick={() => history.push(`/gemseki/asset/${assetId}/cda${location.hash}`)}/>
          </div>
        </div>
      </main>
      <Popup onSubmit={onPrivate} onCancel={onCancel} title="Can I make this asset private?" submitButton="OK" ref={popupPrivate}>
        <p>If you make it private, Licensee will not be able to search for it.</p>
      </Popup>
      <Popup onSubmit={onPublish} onCancel={onCancel} title="Can I publish this asset?" submitButton="OK" ref={popupPublish}>
        <p>As soon as you publish it, Licensee will be able to search for it.</p>
      </Popup>
    </div>
  );
};
