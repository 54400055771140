import {
  REQUEST_NC_REQUEST,
  RECEIVE_NC_REQUEST,
} from '../actions/nc_request';

export default function nc_request(state = {}, action) {
  const {type, ...data} = action;
  switch (type) {
    case REQUEST_NC_REQUEST:
    case RECEIVE_NC_REQUEST:
      return {
        ...state,
        ...data,
      };
    default:
      return state;
  }
};
