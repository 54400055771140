export const PAUSE = 'PAUSE';
export const RESUME = 'RESUME';

class WebApi {
  baseUrl = process.env.PUBLIC_URL;
  apiUrl = process.env.REACT_APP_API_URL;
  appKey = 'app-token';
  refreshKey = 'refresh-token';
  _issueToken = false;

  get hasToken() {
    const token = localStorage.getItem(api.appKey);
    return !!token;
  }

  async login(dispatch, email, password, recaptchaToken) {
    try {
      dispatch({type: PAUSE});
      const res = await this._internalFetch('login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email,
          password,
          recaptchaToken,
        }),
      });

      if (res.ok) {
        localStorage.setItem(this.appKey, res.body.token);
        localStorage.setItem(this.refreshKey, res.body.refreshToken);
      }

      return res;
    } finally {
      dispatch({type: RESUME});
    }
  }

  async logout(dispatch) {
    await api.post(dispatch, '/logout');
    localStorage.removeItem(this.appKey);
    localStorage.removeItem(this.refreshKey);
  }

  async get(dispatch, path) {
    return this.fetch(dispatch, path);
  }

  async post(dispatch, path, body) {
    return this.fetch(dispatch, path, {method: 'POST'}, body);
  }

  async delete(dispatch, path) {
    return this.fetch(dispatch, path, {method: 'DELETE'});
  }

  async put(dispatch, path, body) {
    return this.fetch(dispatch, path, {method: 'PUT'}, body);
  }

  async fetch(dispatch, path, options, jsonBody) {
    try {
      dispatch({type: PAUSE});
      let retry = false;
      let result = {ok: false};
      do {
        const token = localStorage.getItem(this.appKey);
        const opts = options || {};
        opts.headers = opts.headers || {};

        if (token) {
          opts.headers['Authorization'] = `Bearer ${token}`;
        }

        result = await this._internalFetch(path, opts, jsonBody);

        if (!retry && !result.ok && result.body?.errors?.length && result?.body?.errors[0]?.message === 'Token expired') {
          retry = await this._refreshToken();
        } else {
          retry = false;
        }
      } while (retry);

      return result;
    } finally {
      dispatch({type: RESUME});
    }
  }

  async _lockRefresh() {
    let retry;
    while (this._issueToken) {
      await standby(10);
      retry = true;
    }
    return retry;
  }

  async _refreshToken() {
    const retry = this._issueToken && await this._lockRefresh();
    const token = localStorage.getItem(this.refreshKey);
    const check = !token || retry;
    if (!token || retry) return check;

    this._issueToken = true;

    const res = await this._internalFetch('token', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify({}),
    });

    if (res.ok) {
      localStorage.setItem(this.appKey, res.body.token);
      localStorage.setItem(this.refreshKey, res.body.refreshToken);
      this._issueToken = false;
      return true;
    } else {
      localStorage.removeItem(this.appKey);
      localStorage.removeItem(this.refreshKey);
      this._issueToken = false;
      return false;
    }
  }

  async _internalFetch(path, options, jsonBody) {
    const opts = options || {};
    opts.headers = opts.headers || {};

    if (jsonBody) {
      opts.body = JSON.stringify(jsonBody);
      opts.headers['Content-Type'] = `application/json`;
    }

    const url = apiUrl(path);

    let res;
    try {
      res = await fetch(url, opts);
    } catch (e) {
      console.error(e);
      return {ok: false, status: -1, body: {}};
    }
    const contentType = res.headers.get('content-type');

    let body;
    if (contentType && contentType.includes('application/json')) {
      body = await res.json();
    } else {
      body = {rawBody: await res.text()};
    }

    return {ok: res.ok, status: res.status, body};
  }
}

export const api = new WebApi();

export function standby(milliseconds) {
  return new Promise((resolve) => {
    setTimeout(() => resolve(), milliseconds);
  });
}

export const contentUrl = (path) => {
  const p = path.startsWith('/') ? path.substring(1) : path;
  return `${api.baseUrl}/${p}`;
};

export const apiUrl = (path) => {
  const p = path.startsWith('/') ? path.substring(1) : path;
  return `${api.apiUrl}/${p}`;
};

export const copyQuery = (original, ...keys) => {
  const query = new URLSearchParams();
  for (const key of keys) {
    if (original.has(key)) {
      query.set(key, original.get(key));
    }
  }
  return query;
};

export const externalApi = async (dispatch, callback) => {
  try {
    dispatch({type: PAUSE});
    return await callback();
  } finally {
    dispatch({type: RESUME});
  }
};

export const putLocalStorage = (reducerName, key, value) => {
  const reducerStore = loadLocalStorage(reducerName);
  const store = {
    ...reducerStore,
    [key]: value,
  };
  localStorage.setItem(reducerName, JSON.stringify(store));
};

export const loadLocalStorage = (reducerName) => {
  return JSON.parse(localStorage.getItem(reducerName) || '{}');
}