import React, { useEffect, useMemo } from 'react';

import { ButtonBack } from '../../parts/ButtonBack';
import { Input1Line } from '../../parts/Input1Line';
import { CheckBoxGroup } from '../../parts/CheckBoxGroup';
import { InputSelect } from '../../parts/InputSelect';
import { Button } from '../../parts/Button';

import '../base.css';
import { useHistory, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { getGemsekiUsers, getIntroducers, getOriginator, updateOriginator } from '../../actions/core';
import { fd, ref } from '../../parts/refs';
import { DataTypeOfCompanyOriginator } from '../../reducers/datas';
import { InputMultiSelect } from '../../parts/InputMultiSelect';
import { useCountries } from '../../parts/useCountries';
import { useTitle } from '../../parts/useTitle';
import { Card } from '../../parts/Card';

export const OriginatorEdit = () => {
  useTitle('Originator edit');

  const {originatorId} = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(getOriginator(originatorId));
    dispatch(getGemsekiUsers());
    dispatch(getIntroducers());
  }, [dispatch, originatorId]);

  const [originator, ge, ir] = useSelector(s => [s.core.originator, s.core.gemsekiUsers || [], s.core.introducers || []]);
  const users = useMemo(() => ge.map(u => ({label: `${u.firstName} ${u.lastName}`, value: u._id})), [ge]);
  const introducers = useMemo(() => ir.map(i => ({label: i, value: i})), [ir]);

  const forms = {};
  const [areas, countries, areaCallback, countryRef] = useCountries(originator?.area);

  const resolveData = () => {
    return {
      _id: originatorId,
      organizationName: forms.organizationName.value,
      area: forms.area.value,
      country: countryRef.current.value,
      organizationType: 'originator',
      typeOfCompany: forms.typeOfCompany.values,
      msaInfo: forms.msaInfo.value,
      noteForOriginator: forms.noteForOriginator.value,
      url: forms.url.value,
      gemsekiPic: forms.gemsekiPic.values,
      introducer: forms.introducer.values || [],
    };
  };

  return (
    <div id="container" className="layout-login contsOrganizationEdit">

      <main>
        <h1><ButtonBack/>Originator edit</h1>
        <div id="containerMain">
          <Card>
            <table className="tableGray tableGrayThVertical">
              <tbody>
              <tr>
                <th><label className="tableGrayLabel">Date (Company Registered) </label></th>
                <td><p>{fd(originator?.createdAt)}</p></td>
                <th><label className="tableGrayLabel">Date (Company Updated)</label></th>
                <td><p>{fd(originator?.updatedAt)}</p></td>
              </tr>
              <tr>
                <th style={{paddingTop: '26px'}}><label className="tableGrayLabel">Company ID</label></th>
                <td style={{paddingTop: '26px'}}><p>{originator?.displayId}</p></td>
                <th style={{paddingTop: '26px'}}><label className="tableGrayLabel" htmlFor="input1Line-ID--organizationName">Company</label></th>
                <td><Input1Line name="organizationName" ref={ref(forms)} size="100%" defaultValue={originator?.organizationName}/></td>
              </tr>
              <tr>
                <th style={{paddingTop: '26px'}}><label className="tableGrayLabel">Area, Country</label></th>
                <td colSpan="3">
                  <div className="buttonBoxL">
                    <InputSelect name="area" ref={ref(forms)} size="185px" placeholder="Area" value={originator?.area} valueGroup={areas} onChange={areaCallback}/>
                    <InputSelect name="country" ref={countryRef} size="290px" placeholder="Country" value={originator?.country} valueGroup={countries}/>
                  </div>
                </td>
              </tr>
              <tr>
                <th><label className="tableGrayLabel">Type of Company</label></th>
                <td colSpan="3">
                  <CheckBoxGroup name="typeOfCompany" ref={ref(forms)} values={originator?.typeOfCompany} valueGroup={DataTypeOfCompanyOriginator}/>
                </td>
              </tr>
              <tr>
                <th style={{paddingTop: '26px'}}><label className="inputBoxLabel" htmlFor="input1Line-ID--msaInfo">MSA Info</label></th>
                <td colSpan="3"><textarea name="msaInfo" placeholder="Type here…" ref={ref(forms)} rows="5" defaultValue={originator?.msaInfo}/></td>
              </tr>
              <tr>
                <th style={{paddingTop: '26px'}}><label className="inputBoxLabel" htmlFor="input1Line-ID--noteForOriginator">Note for originator</label></th>
                <td colSpan="3"><textarea name="noteForOriginator" placeholder="Type here…" ref={ref(forms)} rows="5" defaultValue={originator?.noteForOriginator}/></td>
              </tr>
              <tr>
                <th style={{paddingTop: '26px'}}><label className="inputBoxLabel" htmlFor="input1Line-ID--url">Website</label></th>
                <td colSpan="3"><Input1Line name="url" ref={ref(forms)} size="500px" defaultValue={originator?.url}/></td>
              </tr>
              <tr>
                <th><label className="tableGrayLabel">PM</label></th>
                <td colSpan="3">
                  <CheckBoxGroup name="gemsekiPic" ref={ref(forms)} values={originator?.gemsekiPic} valueGroup={users}/>
                </td>
              </tr>
              <tr>
                <th style={{paddingTop: '26px'}}><label className="inputBoxLabel" htmlFor="input1Line-ID--introducer">Introduced by</label></th>
                <td colSpan="3"><InputMultiSelect name="introducer" ref={ref(forms)} size="500px" placeholder="Type here…" value={originator?.introducer} valueGroup={introducers}/></td>
              </tr>
              </tbody>
            </table>
            <div className="buttonBoxCenter">
              <Button label="Cancel" size="150px" type="gray" onClick={() => history.goBack()}/>
              <Button label="Save" size="150px" onClick={() => dispatch(updateOriginator(resolveData(), history))}/>
            </div>
          </Card>
        </div>
      </main>
    </div>
  );
};
