import React, { useRef } from 'react';
import { accountRequests } from '../actions/core';
import './base.css';
import { Input1Line } from '../parts/Input1Line';
import { Button } from '../parts/Button';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { useTitle } from '../parts/useTitle';
import { externalApi } from '../actions/api';
import { useRecaptcha } from '../parts/useRecaptcha';

export const Signup = () => {
  const location = useLocation();
  const hash = location.hash || '';
  let pageTitle;
  if((hash === '#ja')||(hash === '#send_ja')){
    pageTitle = "サインアップ"
  } else {
    pageTitle = "Sign up"
  }
  useTitle(pageTitle);
  const {executeRecaptcha} = useRecaptcha();

  const history = useHistory();
  const dispatch = useDispatch();
  const mailRef = useRef(null);
  if (hash === '#send') {
    return (
      <div id="container" className="layout-white contentsForgot">
        <main>
          <h1>Sign up</h1>

          <p>
            Thank you for your interest, we will verify your email address and contact you soon.
          </p>
          
          <a href="/en/" className="backToHome">Back to Home</a>
        </main>
      </div>
    );
  } else if (hash === '#send_ja') {
    return (
      <div id="container" className="layout-white contentsForgot lang-ja">
        <main>
          <h1>サインアップ</h1>

          <p>ご登録ありがとうございました。<br/>折り返しご連絡させていただきますので少々お待ちください。</p>
          
          <a href="/" className="backToHome">Back to Home</a>
        </main>
      </div>
    );
  } else if (hash === '#ja') {
    return (
      <div id="container" className="layout-white contentsForgot lang-ja">
        <main>
          <h1>サインアップ</h1>

          <p style={{marginBottom:30}}>下記にメールアドレスのご記入をお願いします。</p>

          <div className="inputBox">
            <Input1Line type="email" name="email" placeholder="Type here…" label="Eメールアドレス" ref={mailRef}/>
          </div>
          <p style={{margin:0}}>*Gmail, Hotmail, Yahoo!メールではご登録をお断りする場合がございます。</p>
          <p>貴社・貴チームで既にご登録されている方がいらっしゃる場合、招待を受けることが可能です。<br/>ご質問は<a href="/contact/">こちら</a>から承ります。</p>

          <Button label="サインアップ" onClick={async () => {
            const recaptchaToken = await externalApi(dispatch, async () => await executeRecaptcha('SignUp'));
            dispatch(accountRequests(mailRef.current.value, recaptchaToken, history,"#send_ja"));
          }}/>
        </main>
      </div>
    );
  } else {
    return (
      <div id="container" className="layout-white contentsForgot">
        <main>
          <h1>Sign up</h1>

          <p style={{marginBottom:30}}>Please enter your work email address to sign up.</p>

          <div className="inputBox">
            <Input1Line type="email" name="email" placeholder="Type here…" label="Email address" ref={mailRef}/>
          </div>
          <p style={{margin:0}}>*Applications using personal email address might be declined (Gmail, Hotmail, Yahoo, etc.)</p>
          <p>Has your colleague registered already?<br/>Ask him/her/them to invite you to join the team or <a href="/en/contact/">contact us</a> for any assistance. </p>

          <Button label="Sign up" onClick={async () => {
            const recaptchaToken = await externalApi(dispatch, async () => await executeRecaptcha('SignUp'));
            dispatch(accountRequests(mailRef.current.value, recaptchaToken, history,"#send"));
          }}/>
        </main>
      </div>
    );
  }
};
