import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import React from 'react';

export const resolveListContributor = (message) => {
  if (message.messageType === 'message') {
    return `${message.organizationName}`;
  } else if (message.messageType.startsWith('remind')) {
    return `System message [Reminder]`;
  } else if (message.organizationType === 'licensee') {
    return `System message [to: ${message.organizationName}]`;
  } else {
    return `System message [from: ${message.organizationName}]`;
  }
};

export const resolveContributor = (message) => {
  if (message.messageType === 'message') {
    return `${message.firstName} ${message.lastName}`;
  } else if (message.messageType.startsWith('remind')) {
    return `System message [Reminder]`;
  } else if (message.organizationType === 'licensee') {
    return `System message [to: ${message.firstName} ${message.lastName}]`;
  } else {
    return `System message [from: ${message.firstName} ${message.lastName}]`;
  }
};

export const resolveDirection = (user, message) => {
  if (message.messageType === 'message') {
    return user?.organizationType === message.organizationType ? 'r' : 'l';
  } else {
    return user?.organizationType === 'licensee' ? 'l' : 'r';
  }
};

export const MessageBody = ({room, message, isGemseki}) => {
  if (!room || !message) return '';
  const assetUrl = isGemseki ? `/gemseki/asset/${room.assetId}` : `/licensee/asset/${room.assetId}`;
  switch (message.messageType) {
    case 'ncNewRequest':
      return (
        <span className="message">
          {message.multi && '[Multiple Requests] '}Thank you for requesting the NCD of {room.asset.assetNumber}. Your request has been submitted successfully and will be processed shortly. You will receive an notification once the status is updated.<br/>
          Your message for the request is as stated below.<br/>
          If you have any questions, please type your questions in the message box.<br/>
          -------<br/>
          <span className="messageBody">{message.body}</span><br/>
          -------<br/>
          <Link to={assetUrl}>&gt;&gt;{room.asset?.assetNumber}</Link>
        </span>
      );
    case 'ncConfirming':
      return (
        <span className="message">
          Gemseki would like to inform you that the status of your request has been changed to "Confirming." We are currently confirming with the originator for the NCD approval of {room.asset?.assetNumber}. Please wait for the originator's response.<br/>
          <Link to={assetUrl}>&gt;&gt;{room.asset?.assetNumber}</Link>
        </span>
      );
    case 'ncDeclinedByOriginator':
      return (
        <span className="message">
          Gemseki would like to inform you that the status has been changed to "Declined by Originator." Unfortunately, we cannot share the information regarding {room.asset?.assetNumber}.<br/>
          The reason(s) for declining from the originator are as stated below.<br/>
          -------<br/>
          <span className="messageBody">{message.additionalParams?.join('\n')}</span><br/>
          -------<br/>
          <Link to={assetUrl}>&gt;&gt;{room.asset?.assetNumber}</Link>
        </span>
      );
    case 'ncApprovedByOriginator':
      return (
        <span className="message">
          Gemseki would like to inform you that the status has been changed to "Approved by Originator." You can download the NCD of {room.asset?.assetNumber} from the link below. If you would like to see additional information, please request a CDA.<br/>
          <Link to={assetUrl}>&gt;&gt;{room.asset?.assetNumber}</Link>
        </span>
      );
    case 'ncPending':
      return (
        <span className="message">
          Gemseki would like to inform you that the status has been changed to "Pending".<br/>
          If you'd like to see further information, please click the "CDA Request" button from the link below.<br/>
          If you are no longer interested in this asset, please change the status to "Declined by licensee"<br/>
          -------<br/>
          <span className="messageBody">{message.additionalParams?.join('\n')}</span><br/>
          -------<br/>
          <Link to={assetUrl}>&gt;&gt;{room.asset?.assetNumber}</Link>
        </span>
      );
    case 'ncDeclinedByLicensee':
      return (
        <span className="message">
          Gemseki would like to inform you that the status has been changed to "Declined by Licensee."<br/>
          Your reason(s) for declining are as stated below.<br/>
          -------<br/>
          <span className="messageBody">{message.additionalParams?.join('\n')}</span><br/>
          -------<br/>
          <Link to={assetUrl}>&gt;&gt;{room.asset?.assetNumber}</Link>
        </span>
      );
    case 'cdaNewRequest':
      return (
        <span className="message">
          Thank you for requesting the CDA of {room.asset?.assetNumber}. Your request has been submitted successfully and will be processed shortly. You will receive an notification once the status is updated.<br/>
          Your message for the request is as stated below.<br/>
          If you have any questions, please type your questions in the message box.<br/>
          <Link to={assetUrl}>&gt;&gt;{room.asset?.assetNumber}</Link>
        </span>
      );
    case 'cdaConfirming':
      return (
        <span className="message">
          Gemseki would like to inform you that the status of your request has been changed to "Confirming." We are currently confirming with the originator for the CDA agreement. Please wait for the originator's response. Once the originator gives approval, we will send you a CDA draft for your check.<br/>
          <Link to={assetUrl}>&gt;&gt;{room.asset?.assetNumber}</Link>
        </span>
      );
    case 'cdaDeclinedByOriginator':
      return (
        <span className="message">
          Gemseki would like to inform you that the status has been changed to "Declined by Originator." Unfortunately, the originator has declined to proceed to the CDA.<br/>
          <Link to={assetUrl}>&gt;&gt;{room.asset?.assetNumber}</Link>
        </span>
      );
    case 'cdaEvaluating':
      return (
        <span className="message">
          Gemseki would like to inform you that the status has been changed to "Evaluating." The CDA has been successfully executed. Please review the confidential materials provided by the originator, and let us know your evaluation result when it's ready.<br/>
          <Link to={assetUrl}>&gt;&gt;{room.asset?.assetNumber}</Link>
        </span>
      );
    case 'cdaPending':
      return (
        <span className="message">
          Gemseki would like to inform you that the status has been changed to "Pending." Please contact us if you would like to review it again.<br/>
          <Link to={assetUrl}>&gt;&gt;{room.asset?.assetNumber}</Link>
        </span>
      );
    case 'cdaDeclinedByLicensee':
      return (
        <span className="message">
          Gemseki would like to inform you that the status has been changed to "Declined by Licensee".<br/>
          We care about your thoughts and would appreciate it if you could share with us, please type your reason for the decline in the message box, thank you.<br/>
          <Link to={assetUrl}>&gt;&gt;{room.asset?.assetNumber}</Link>
        </span>
      );
    case 'remindNcApproved1':
      return (
        <span className="message">
          It's been a month since the NCD of the following asset was shared with you and we'd love to hear your thoughts.<br/>
          Would you like to proceed with a further discussion under a CDA?<br/>
          Or if you have any questions, please feel free to let us know.<br/>
          <Link to={assetUrl}>&gt;&gt;{room.asset?.assetNumber}</Link>
        </span>
      );
    case 'remindNcApproved2':
      return (
        <span className="message">
          Just a friendly reminder here, have you had a chance to review the NCD of the following asset? Would you like to proceed with a further discussion under CDA or decline this opportunity? We'd love to hear from you.<br/>
          <Link to={assetUrl}>&gt;&gt;{room.asset?.assetNumber}</Link>
        </span>
      );
    case 'remindNcPending1':
      return (
        <span className="message">
          It's been 6 months since the review status of the following asset was changed to "Pending", would you like to continue the review?<br/>
          <Link to={assetUrl}>&gt;&gt;{room.asset?.assetNumber}</Link>
        </span>
      );
    case 'remindNcPending2':
      return (
        <span className="message">
          It's been a year since the review status of the following asset was changed to "Pending", would you like to continue the review?<br/>
          <Link to={assetUrl}>&gt;&gt;{room.asset?.assetNumber}</Link>
        </span>
      );
    default:
      return (
        <span className="message">
          <span className="messageBody">{message.body}</span>
        </span>
      );
  }
};

MessageBody.propTypes = {
  /**
   * ルーム情報
   */
  room: PropTypes.object,
  /**
   * メッセージ
   */
  message: PropTypes.object,
  /**
   * Gemseki用表示
   */
  isGemseki: PropTypes.bool,
};

MessageBody.defaultProps = {};

export const ShortMessageBody = ({room, message}) => {
  if (!room || !message) return '';
  switch (message.messageType) {
    case 'ncNewRequest':
      return (
        <span className="message">
          {message.multi && '[Multiple Requests] '}Thank you for requesting the NCD of {room.asset.assetNumber}. Your request has been submitted successfully and will be processed shortly...
        </span>
      );
    case 'ncConfirming':
      return (
        <span className="message">
          Gemseki would like to inform you that the status of your request has been changed to "Confirming." We are currently confirming with the ...
        </span>
      );
    case 'ncDeclinedByOriginator':
      return (
        <span className="message">
          Gemseki would like to inform you that the status has been changed to "Declined by Originator." Unfortunately, we cannot share the ...
        </span>
      );
    case 'ncApprovedByOriginator':
      return (
        <span className="message">
          Gemseki would like to inform you that the status has been changed to "Approved by Originator." You can download the NCD of {room.asset?.assetNumber} ...
        </span>
      );
    case 'ncPending':
      return (
        <span className="message">
          Gemseki would like to inform you that the status has been changed to "Pending". If you'd like to see further information, please ...
        </span>
      );
    case 'ncDeclinedByLicensee':
      return (
        <span className="message">
          Gemseki would like to inform you that the status has been changed to "Declined by Licensee." Your reason(s) for declining are as stated below.
        </span>
      );
    case 'cdaNewRequest':
      return (
        <span className="message">
          Thank you for requesting the CDA of {room.asset?.assetNumber}. Your request has been submitted successfully and will be processed shortly...
        </span>
      );
    case 'cdaConfirming':
      return (
        <span className="message">
          Gemseki would like to inform you that the status of your request has been changed to "Confirming." We are currently confirming with the ...
        </span>
      );
    case 'cdaDeclinedByOriginator':
      return (
        <span className="message">
          Gemseki would like to inform you that the status has been changed to "Declined by Originator." Unfortunately, the originator has declined ...
        </span>
      );
    case 'cdaEvaluating':
      return (
        <span className="message">
          Gemseki would like to inform you that the status has been changed to "Evaluating." The CDA has been successfully executed. Please review ...
        </span>
      );
    case 'cdaPending':
      return (
        <span className="message">
          Gemseki would like to inform you that the status has been changed to "Pending." Please contact us if you would like to review it again.
        </span>
      );
    case 'cdaDeclinedByLicensee':
      return (
        <span className="message">
          Gemseki would like to inform you that the status has been changed to "Declined by Licensee". We care about your thoughts and would ...
        </span>
      );
    case 'remindNcApproved1':
      return (
        <span className="message">
          It's been a month since the NCD of the following asset was shared with you and we'd love to hear your thoughts. ...
        </span>
      );
    case 'remindNcApproved2':
      return (
        <span className="message">
          Just a friendly reminder here, have you had a chance to review the NCD of the following asset? ...
        </span>
      );
    case 'remindNcPending1':
      return (
        <span className="message">
          It's been 6 months since the review status of the following asset was changed to "Pending", would you like to continue the review?
        </span>
      );
    case 'remindNcPending2':
      return (
        <span className="message">
          It's been a year since the review status of the following asset was changed to "Pending", would you like to continue the review?
        </span>
      );
    default:
      return (
        <span className="message">
          <span className="messageBody">{message?.body?.length > 50 ? message?.body?.substring(0, 50) + '...' : message.body}</span>
        </span>
      );
  }
};

ShortMessageBody.propTypes = {
  /**
   * ルーム情報
   */
  room: PropTypes.object,
  /**
   * メッセージ
   */
  message: PropTypes.object,
  /**
   * Gemseki用表示
   */
  isGemseki: PropTypes.bool,
};

ShortMessageBody.defaultProps = {};
