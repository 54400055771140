import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import './searchTab.css';

export const SearchTab = React.forwardRef(({
  selected,
  valueGroup,
  onSelected,
}, ref) => {

  return (
    <div className="searchTab" ref={ref}>
      {valueGroup?.map((values) => (
        <div className={`tab tabActive--${(values.value === selected)}`} key={`ButtonH1Side:${values.value}`}>
          {
            onSelected
              ? <div onClick={() => onSelected(values.value)}>{values.label}</div>
              : <Link to={values.url}>{values.label}</Link>
          }
        </div>
      ))}
    </div>
  );
});

SearchTab.propTypes = {
  /**
   * 選択されている項目
   */
   selected: PropTypes.string,
   /**
    * チェックリストの配列
    */
   valueGroup: PropTypes.array,
   /**
    * 選択コールバック
    */
   onSelected: PropTypes.func,
 };

SearchTab.defaultProps = {
  selected: 'value1',
  valueGroup: [
    {label: 'label1', value: 'value1', url: '/'},
    {label: 'label2', value: 'value1', url: '/'},
    {label: 'label3', value: 'value1', url: '/'},
  ],
};
