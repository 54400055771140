import React, { useEffect } from 'react';

import '../base.css';
import { useHistory, useParams } from 'react-router';

import { ButtonBack } from '../../parts/ButtonBack';
import { Input1Line } from '../../parts/Input1Line';
import { CheckBoxGroup } from '../../parts/CheckBoxGroup';
import { AssetMarkChecks } from '../../parts/AssetMarkChecks';
import { InputSelect } from '../../parts/InputSelect';
import { NcdFiles } from '../../parts/NcdFiles';
import { Button } from '../../parts/Button';
import { Card } from '../../parts/Card';

import { useDispatch, useSelector } from 'react-redux';
import { getOriginatorNames } from '../../actions/core';
import { downloadNc, getAsset, updateAsset, uploadNc } from '../../actions/asset';
import { fd, ref } from '../../parts/refs';
import { DataDevelopmentStage, DataDiseaseArea, DataModality, DataRoute, DataTerritoryForInLicensing, DataTypeOfPreferredPartnering } from '../../reducers/datas';
import { useTitle } from '../../parts/useTitle';

const EMPTY = [];

export const AssetEdit = () => {
  useTitle('Asset edit');

  const history = useHistory();
  const dispatch = useDispatch();
  const {assetId} = useParams();

  useEffect(() => {
    dispatch(getOriginatorNames());
    dispatch(getAsset(assetId));
  }, [dispatch, assetId]);

  const [assetDetail, names, temporaryId] = useSelector(s => [s.asset.assetDetail, s.core.originatorNames || EMPTY, s.asset.temporaryId]);
  const organizationId = assetDetail?.organizationId;
  const assetType = assetDetail?.assetType;

  const isSeeds = assetType === 'seeds';
  const isTech = assetType === 'technologies';

  const forms = {};

  const resolveData = () => {
    return {
      assetType,
      assetNumber: forms.assetNumber.value,
      mechanismOfAction: isSeeds ? forms.mechanismOfAction.value : undefined,
      indication: isSeeds ? forms.indication.value : undefined,
      route: isSeeds ? forms.route.values : undefined,
      modality: isSeeds ? forms.modality.values : undefined,
      developmentStage: isSeeds ? forms.developmentStage.values : undefined,
      note: isSeeds ? forms.note.value : undefined,
      diseaseArea: isSeeds ? forms.diseaseArea.values : undefined,
      technology: isTech ? forms.technology.value : undefined,
      summary: isTech ? forms.summary.value : undefined,
      typeOfPreferredPartnering: forms.typeOfPreferredPartnering.value,
      territoryForLicensing: forms.territoryForLicensing.value,
      territoryNotAvailableForLicensing: forms.territoryNotAvailableForLicensing.value,
      originalCompoundName: forms.originalCompoundName.value,
      gemsekisNotes: forms.gemsekisNotes.value,
      ncs: forms.ncs.values,
      organizationId: forms.organizationId.value,
      temporaryId,
      markFeatured: forms.marks.markFeatured,
      markUpdated: forms.marks.markUpdated,
      status: assetDetail?.status,
    };
  };

  return (
    <div id="container" className="layout-login contsAssetEdit">
      <main>
        <h1><ButtonBack/>Asset edit</h1>
        <div id='containerMain'>
          <Card>
            <table className="tableGray tableGrayThVertical">
              <tbody>
              <tr>
                <th><label className="tableGrayLabel">Date (Asset Registered)</label></th>
                <td><p>{fd(assetDetail?.createdAt)}</p></td>
                <th><label className="tableGrayLabel">Date (Asset Updated)</label></th>
                <td><p>{fd(assetDetail?.updatedAt)}</p></td>
              </tr>

              <tr>
                <th style={{paddingTop: "26px"}}><label className="tableGrayLabel">Asset No.</label></th>
                <td><Input1Line name="assetNumber" ref={ref(forms)} size="177px" defaultValue={assetDetail?.assetNumber} placeholder="Type here…"/></td>
                <th style={{paddingTop: "26px"}}><label className="tableGrayLabel">Originator</label></th>
                <td>
                  <div className="tableGraySearch"><InputSelect name="organizationId" ref={ref(forms)} placeholder="Select" size="400px" value={organizationId} valueGroup={names}/></div>
                </td>
              </tr>

              {isSeeds &&
                <tr>
                  <th style={{paddingTop: "26px"}}><label className="tableGrayLabel">Mode of Action</label></th>
                  <td colSpan={3}><Input1Line name="mechanismOfAction" ref={ref(forms)} size="100%" defaultValue={assetDetail?.mechanismOfAction} placeholder="Type here…"/></td>
                </tr>
              }

              {isSeeds &&
                <tr>
                  <th><label className="tableGrayLabel">Indication</label></th>
                  <td colSpan={3}>
                    <div className="input1LineBox"><textarea name="indication" ref={ref(forms)} placeholder="Type here…" defaultValue={assetDetail?.indication}/></div>
                  </td>
                </tr>
              }

              {isSeeds &&
                <tr>
                  <th><label className="tableGrayLabel">Route</label></th>
                  <td colSpan={3}><CheckBoxGroup name="route" ref={ref(forms)} valueGroup={DataRoute} values={assetDetail?.route}/></td>
                </tr>
              }

              {isSeeds &&
                <tr>
                  <th><label className="tableGrayLabel">Modality</label></th>
                  <td colSpan={3}><CheckBoxGroup name="modality" ref={ref(forms)} valueGroup={DataModality} values={assetDetail?.modality}/></td>
                </tr>
              }

              {isSeeds &&
                <tr>
                  <th><label className="tableGrayLabel">Development Stage</label></th>
                  <td colSpan={3}><CheckBoxGroup name="developmentStage" ref={ref(forms)} valueGroup={DataDevelopmentStage} values={assetDetail?.developmentStage}/></td>
                </tr>
              }

              {isSeeds &&
                <tr>
                  <th><label className="tableGrayLabel">Note</label></th>
                  <td colSpan={3}>
                    <div className="input1LineBox"><textarea name="note" ref={ref(forms)} rows="5" placeholder="Type here…" defaultValue={assetDetail?.note}/></div>
                  </td>
                </tr>
              }

              {isSeeds &&
                <tr>
                  <th><label className="tableGrayLabel">Disease Area</label></th>
                  <td colSpan={3}><CheckBoxGroup name="diseaseArea" ref={ref(forms)} valueGroup={DataDiseaseArea} values={assetDetail?.diseaseArea}/></td>
                </tr>
              }

              {isTech &&
                <tr>
                  <th><label className="tableGrayLabel">Technology</label></th>
                  <td colSpan={3}><Input1Line name="technology" ref={ref(forms)} size="100%" defaultValue={assetDetail?.technology} placeholder="Type here…"/></td>
                </tr>
              }

              {isTech &&
                <tr>
                  <th><label className="tableGrayLabel">Summary</label></th>
                  <td colSpan={3}>
                    <div className="input1LineBox"><textarea name="summary" ref={ref(forms)} rows={5} placeholder="Type here…" defaultValue={assetDetail?.summary}/></div>
                  </td>
                </tr>
              }

              <tr>
                <th style={{paddingTop: "26px"}}><label className="tableGrayLabel">Type of Preferred Partnering</label></th>
                <td colSpan={3}>
                  <div className="input1LineBox"><InputSelect name="typeOfPreferredPartnering" ref={ref(forms)} size="436px" valueGroup={DataTypeOfPreferredPartnering} value={assetDetail?.typeOfPreferredPartnering}/></div>
                </td>
              </tr>
              <tr>
                <th style={{paddingTop: "26px"}}><label className="tableGrayLabel">Licensing Available Area</label></th>
                <td colSpan={3}>
                  <div className="input1LineBox"><InputSelect name="territoryForLicensing" ref={ref(forms)} size="436px" valueGroup={DataTerritoryForInLicensing} value={assetDetail?.territoryForLicensing}/></div>
                </td>
              </tr>
              <tr>
                <th style={{paddingTop: "26px"}}><label className="tableGrayLabel">Licensing Unavailable Area</label></th>
                <td colSpan={3}><Input1Line name="territoryNotAvailableForLicensing" ref={ref(forms)} size="100%" defaultValue={assetDetail?.territoryNotAvailableForLicensing} placeholder="Type here…"/></td>
              </tr>
              <tr>
                <th style={{paddingTop: "26px"}}><label className="tableGrayLabel">Original Name of Compound</label></th>
                <td colSpan={3}><Input1Line name="originalCompoundName" ref={ref(forms)} size="100%" defaultValue={assetDetail?.originalCompoundName} placeholder="Type here…"/></td>
              </tr>
              <tr>
                <th style={{paddingTop: "26px"}}><label className="tableGrayLabel">Gemseki’s Memo</label></th>
                <td colSpan={3}>
                  <div className="input1LineBox"><textarea name="gemsekisNotes" ref={ref(forms)} rows={5} placeholder="Type here…" defaultValue={assetDetail?.gemsekisNotes}/></div>
                </td>
              </tr>
              <tr>
                <th style={{paddingTop: "26px"}}><label className="tableGrayLabel">Mark</label></th>
                <td colSpan={3}><AssetMarkChecks name="marks" ref={ref(forms)} marks={assetDetail?.marks}/></td>
              </tr>
              </tbody>
            </table>
            <NcdFiles
              name="ncs"
              ref={ref(forms)}
              editMode={true}
              valueGroup={assetDetail?.ncs}
              onUpload={async (inputFile) => {
                return await dispatch(uploadNc(temporaryId, inputFile));
              }}
              onDownload={async (fileItem) => {
                dispatch(downloadNc(fileItem, assetId, temporaryId));
              }}/>
            <div className="buttonBoxCenter">
              <Button label="Cancel" size="150px" type="gray" onClick={() => history.goBack()}/>
              <Button label="Save" size="150px" onClick={() => dispatch(updateAsset(assetId, resolveData(), history))}/>
            </div>
          </Card>
        </div>
      </main>
    </div>
  );
};
