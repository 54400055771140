import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import './card.css';
import { Button } from './Button';
import { fd } from './refs';
import { resolveReasons } from './RequestPopups';
import { InputSelectRequest } from './InputSelectRequest';

export const ListRequest = ({requestData, user, unread, mode, onChangeStatus, onClickNewCda}) => {
  const isGemseki = user === 'Gemseki';
  const isLicensee = user === 'Licensee';
  const hasCriteria = mode === 'NCD' && isGemseki && requestData?.status === 'newRequest' && !!requestData.licensingCriteria;
  const messageTitle = isLicensee ? 'Note or Summary ' : 'Message';
  const messageBody = isLicensee ? requestData?.note || requestData?.summary : requestData?.comment;
  const userDir = isLicensee ? 'licensee' : 'gemseki';
  
  return (
    <li className="listRequest">
      <ul className="listRequestHeader">
          <li className="listRequestDate">{unread && <div className='unread'/>}{fd(requestData?.updatedAt)}</li>
          <li><Link to={`/${userDir}/asset/${requestData?.assetId}`}>{requestData?.assetNumber}</Link></li>
          {isGemseki && <li><span>Licensee</span><Link className={`isValid--${requestData?.licenseeOrgStatus}`} to={`/${userDir}/licensee/${requestData?.licenseeOrgId}`}>{requestData?.licenseeOrgName}</Link></li>}
          {isGemseki && <li><span>Originator</span><Link className={`isValid--${requestData?.originatorOrgStatus}`} to={`/${userDir}/originator/${requestData?.originatorOrgId}`}>{requestData?.originatorOrgName}</Link></li>}
      </ul>
      {hasCriteria && <div className="listRequestBody"><label>Specific Requirements</label>
        <div className="listRequestMain">{requestData?.licensingCriteria}</div>
      </div>}
      {messageBody && <div className="listRequestBody"><label>{messageTitle}</label>
        <div className="listRequestMain">{messageBody}</div>
      </div>}
      <div className={['cardRequestFooter', `cardRequestFooter--${requestData?.status}`].join(' ')}>
        <div className="cardRequestFooterLeft">
          <InputSelectRequest isGemseki={isGemseki} mode={mode} status={requestData?.status} onChangeStatus={onChangeStatus}/>
        </div>
        <div className="cardRequestFooterRight">
          {
            mode === 'NCD' && (
              !requestData?.cdaStatus
                ? <Button label="CDA request" size="207px" type="request" onClick={() => onClickNewCda(requestData)}/>
                : <Button label="CDA request" size="207px" type="request" disabled/>
              )
          }
          {requestData?.assetRoomId && <Button label="Message" to={`/${userDir}/room/${requestData?.assetRoomId}`} size="174px"/>}
        </div>
      </div>
      {resolveReasons(requestData) && <p className='cardRequestReason' style={{whiteSpace: 'pre-wrap'}}>{resolveReasons(requestData)}</p>}
    </li>
  );
};

ListRequest.propTypes = {
  /**
   * User種別
   */
  user: PropTypes.oneOf(['Licensee', 'Gemseki']),
  /**
   * Request の状態
   */
  mode: PropTypes.oneOf(['NCD', 'CDA']),
  /**
   * ステータス変更コールバック
   */
  onChangeStatus: PropTypes.func,
  /**
   * ステータス変更コールバック
   */
   onClickNewCda: PropTypes.func,
   /**
   * 未読かどうか
   */
  unread: PropTypes.bool,
};

ListRequest.defaultProps = {
  user: 'Gemseki',
  mode: 'NCD',
  onChangeStatus: () => {},
  onClickNewCda: () => {},
  unread: false,
};
