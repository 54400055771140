import React from 'react';
import PropTypes from 'prop-types';
import { Button } from './Button';

import './ncdFiles.css';
import { differentValueGroup, fd } from './refs';

function parseDate(text) {
  try {
    return new Date(text);
  } catch {
    return new Date(1);
  }
}

function compareFile(left, right) {
  const l = parseDate(left?.updatedAt);
  const r = parseDate(right?.updatedAt);
  return l.getTime() > r.getTime() ? left : right;
}

export class NcdFiles extends React.Component {
  constructor(props) {
    super(props);
    this.state = {files: this.props.valueGroup || []};
  }

  get name() {
    return this.props.name;
  }

  get values() {
    return this.state.files;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (differentValueGroup(this.props.valueGroup, prevProps.valueGroup, target => target?.fileName)) {
      this.setState({files: this.props.valueGroup || []});
    }
  }

  async _upload(e) {
    const input = e.target;
    if (input.files.length) {
      const fileItem = await this.props.onUpload?.call(this, input.files[0]);
      if (fileItem) {
        const {files, ...others} = this.state;
        const newFiles = [...files.filter(f => f.fileName !== fileItem.fileName), {...fileItem, isTemporary: true}];
        this.setState({files: newFiles, ...others});
      }
    }
    input.value = '';
  }

  async _download(file) {
    const dlInfo = this.props.dlInfo
    if(dlInfo){
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'event': 'NCDdownload',
        'assetName': dlInfo.assetName,
        'organizationId': dlInfo.orgLinkageId,
        'userId': dlInfo.userId,
        'fileName': file.fileName,  
      });
    }
    this.props.onDownload?.call(this, file);
  }

  async _delete(fileName) {
    const {files, ...others} = this.state;
    const newFiles = files.filter(f => f.fileName !== fileName);
    this.setState({files: newFiles, ...others});
  }

  render() {
    const {editMode, name} = this.props;
    const latest = this.state.files.reduce(compareFile, null);
    return (
      <div className={`ncdFiles ncdFiles--${editMode} name-${name}`}>
        <div className="ncdFilesHeader">
          <h3>NCD files</h3>
          <p>Update date | {!!latest?.updatedAt ? fd(latest?.updatedAt) : '-'}</p>
        </div>
        <ul>
          {this.state.files.map((file, i) => (
            <li key={`ncd-files:${i}:${file.fileName}`}>
              <p>{file.fileName}</p>
              <div className="ncdFilesBtn">
                <Button label="download" size="ncdFile" onClick={() => this._download(file)}/>
                {editMode && <Button label="Delete" type="gray" size="ncdFile" onClick={() => this._delete(file.fileName)}/>}
              </div>
            </li>
          ))}
        </ul>
        {(editMode && this.state.files.length < 3 &&
          <div className="ncdFilesFooter">
            <label className={['button', `button--170px`].join(' ')} style={{textAlign: 'center'}}>file selection<input type="file" onChange={e => this._upload(e)} style={{display: 'none'}}/></label>
            <p>You can upload up to three files, each of which can be up to 10 MB in size.</p>
          </div>)}
      </div>
    );
  }
}

NcdFiles.propTypes = {
  /**
   * 編集モードかどうか
   */
  editMode: PropTypes.bool,
  /**
   * ファイル毎の配列
   */
  valueGroup: PropTypes.array,
  /**
   * ファイルアップロードコールバック
   */
  onUpload: PropTypes.func,
  /**
   * ファイルダウンロードコールバック
   */
  onDownload: PropTypes.func,
};

NcdFiles.defaultProps = {
  editMode: false,
  valueGroup: [],
};
