import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import '../base.css';
import { useDispatch, useSelector } from 'react-redux';
import { ButtonStar } from '../../parts/ButtonStar';
import { PageNavi } from '../../parts/PageNavi';
import { toggleBookmark, getBookmarks } from '../../actions/bookmark';
import { fd, useQuery } from '../../parts/refs';
import { useTitle } from '../../parts/useTitle';
import { Card } from '../../parts/Card';
import StarImg from '../../parts/assets/icon-star-white.svg';
import ScrollContainer from 'react-indiana-drag-scroll'
import TutorialImg from '../../parts/assets/tutorial_bookmark.png';

export const AssetBookmark = () => {
  useTitle('Asset bookmark');

  const dispatch = useDispatch();
  const query = useQuery();
  const page = parseInt(query.get('page') || '0');

  useEffect(() => {
    dispatch(getBookmarks(query));
  }, [dispatch, query]);

  const [bookmarks, totalCount, total] = useSelector(s => [s.bookmark.bookmarks || [], s.bookmark.totalCount || 0, s.bookmark.total]);

  const changeBookmark = async (assetId, bookmarkId) => {
    await dispatch(toggleBookmark(assetId, bookmarkId));
    dispatch(getBookmarks(query));
  };

  var [tutorialBoolOpen, setTutorialBoolOpen] = useState(true);
  const tutorialOpenClose = (b) => {
    setTutorialBoolOpen(tutorialBoolOpen = b);
  };

  return (
    <div id="container" className="layout-login contsAssetBookmark">

      <main>
        <h1>Asset bookmark</h1>
        <div id='containerMain'>
          {(!totalCount && tutorialBoolOpen) && 
            <div className='tutorial'>
              <img src={TutorialImg} alt='tutorial'></img>
              <div className='tutorialMain'>
                <p>Click on the star sign to bookmark the asset. <br/>You can check all the bookmarked assets here.</p>
                <Link to={`/licensee/assets`} className='tutorialLink'>Asset search&nbsp;&nbsp;&gt;</Link>
              </div>
              <button className='tutorialClose' onClick={() => tutorialOpenClose(false)}></button>
            </div>
          }
          <Card>
          <ScrollContainer className="scroll-container" hideScrollbars="false" vertical="false">
        <table className="tableGray tableGrayThHorizontal">
          <thead>
          <tr>
            <th><img src={StarImg} alt="★"/></th>
            <th>Bookmarked<br/>date</th>
            <th>Asset No.</th>
            <th>Indication</th>
            <th>Development Stage</th>
            <th>Date<br/>(Asset Updated)</th>
          </tr>
          </thead>
          <tbody>
          {bookmarks.map(bookmark => {
            return (
              <tr key={`bookmark:${bookmark?._id}`}>
                <td><ButtonStar selected={true} onClick={() => changeBookmark(bookmark?.assetId, bookmark?._id)}/></td>
                <td>{fd(bookmark?.createdAt)}</td>
                <td><Link to={`asset/${bookmark?.assetId}`} className="tableID">{bookmark?.asset?.assetNumber}</Link></td>
                <td>{bookmark?.asset?.indication}</td>
                <td>{bookmark?.asset?.developmentStage?.map(l => <span key={l}>{l}</span>)}</td>
                <td>{fd(bookmark?.asset?.updatedAt)}</td>
              </tr>
            );
          })}
          {!bookmarks?.length && (
            <tr>
              <td colSpan={4}>
                <div className="tableCenter">No results.</div>
              </td>
            </tr>
          )}
          </tbody>
        </table>
        </ScrollContainer>
        <PageNavi selected={page} total={total}/>
          </Card>
        </div>
      </main>
    </div>
  );
};
