import React from 'react';
import './buttonBack.css';
import { useHistory } from 'react-router';

export const ButtonBack = () => {
  const history = useHistory();
  return (
    <div className="buttonBack">
      <button onClick={() => history.goBack()}></button>
    </div>
  );
};
