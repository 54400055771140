import React from 'react';
import PropTypes from 'prop-types';
import { Input1Line } from './Input1Line';
import { Button } from './Button';

import './memberList.css';

export class MemberAdd extends React.Component {
  constructor(props) {
    super(props);
    this.firstNameRef = React.createRef();
    this.lastNameRef = React.createRef();
    this.jobTitleRef = React.createRef();
    this.emailRef = React.createRef();
  }

  get value() {
    return {
      _id: this.props.value?._id || null,
      firstName: this.firstNameRef.current.value,
      lastName: this.lastNameRef.current.value,
      jobTitle: this.jobTitleRef.current.value,
      email: this.emailRef.current.value,
    };
  }

  render() {
    const {parentName, value, onRemove} = this.props;
    return (
      <li className="memberAdd">
        <div className="memberAddInput">
          <label>
            <span>User Name</span>
            <div className="buttonBoxL">
              <Input1Line type="text" placeholder="…" name="firstName" validationName={`${parentName}_firstName`} ref={this.firstNameRef} defaultValue={value?.firstName} size="133px"/>
              <Input1Line type="text" placeholder="…" name="lastName" validationName={`${parentName}_lastName`} ref={this.lastNameRef} defaultValue={value?.lastName} size="133px"/>
            </div>
          </label>
          <Input1Line type="text" label="Job Title" placeholder="…" name="job" validationName={`${parentName}_job`} ref={this.jobTitleRef} defaultValue={value?.jobTitle} size="209px"/>
          <Input1Line type="email" label="Email address" placeholder="…" name="email" validationName={`${parentName}_email`} ref={this.emailRef} defaultValue={value?.email} disabled={!!value?._id} size="232px" maxLength={80}/>
        </div>
        <div className="memberAddButton">
          <Button label="ー" onClick={onRemove}/>
        </div>
      </li>
    );
  }
}

MemberAdd.propTypes = {
  /**
   * 親要素の名前
   */
  parentName: PropTypes.string,
  /**
   * メンバー配列
   */
  value: PropTypes.object,
  /**
   * 行削除アクション
   */
  onRemove: PropTypes.func,
};

MemberAdd.defaultProps = {
  value: {},
};
