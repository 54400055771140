import React from 'react';
import LogoImg from './assets/logo.svg';
import LogoImgSnbl from './assets/snbl-logo.svg';
import LogoImgLinkedIn from './assets/linkedin-logo.svg';
import './footer.css';
import {useLocation} from 'react-router';

export const Footer = () => {
  const location = useLocation();
  const hash = location.hash || '';
  const langEn = hash === "#ja" || hash === "#send_ja" ? false : true;
  const langDir = langEn ? "/en/" : "/";
  const langClass = langEn ? "en" : "ja";
  const langTitleAbout = langEn ? "About Gemseki" : "会社概要";
  const langTitleMessage = langEn ? "Message" : "社長メッセージ";
  const langTitleMembers = langEn ? "Members" : "メンバー";
  const langTitleLicensing = langEn ? "What is Licensing" : "ライセンスとは";
  const langTitleYakuichi = langEn ? "Drug Candidate Marketplace" : "創薬流通市場「薬市楽座」";
  const langTitleFund = langEn ? "Fund" : "投資事業に関して";
  const langTitleWhy = langEn ? "Why us?" : "Gemsekiが選ばれる理由";
  const langTitleHow = langEn ? "How it Works?" : "「薬市楽座」のご利用方法";
  const langTitleNews = langEn ? "News" : "ニュース";
  const langTitleSignUp = langEn ? "Sign up" : "サインアップ";
  const langTitleContact = langEn ? "Contact" : "お問合わせ";
  const langTitlePolicy = langEn ? "Privacy Policy" : "プライバシーポリシー";
  const langTitleTerm = langEn ? "Term of Use" : "利用規約";
  const langFooterUrl = langEn ? "https://www.snbl.co.jp.e.aoy.hp.transer.com/" : "https://www.snbl.co.jp/";
  const headlineLicensing = langEn ? "Licensing Business" : "ライセンス事業";
  const headlineFund = langEn ? "Fund Business" : "投資事業";

  return (
    <footer className={`lang-${langClass}`}>
      <div className="f_in">
        <div className="f_in_in1 clfix">
          <div className="f_in_in1_1">
            <p>About</p>
            <ul>
              <li><a href={`${langDir}about/about_gemseki/`}><span data-hover={langTitleAbout}>{langTitleAbout}</span></a></li>
              <li><a href={`${langDir}about/message/`}><span data-hover={langTitleMessage}>{langTitleMessage}</span></a></li>
              <li><a href={`${langDir}about/members/`}><span data-hover={langTitleMembers}>{langTitleMembers}</span></a></li>
            </ul>
          </div>
          <div className="f_in_in1_2">
            <p>Service</p>
            <p class="f_sub">{`${headlineLicensing}`}</p>
            <ul>
              <li><a href={`${langDir}about/licensing/`}><span data-hover={langTitleLicensing}>{langTitleLicensing}</span></a></li>
              <li><a href={`${langDir}service/drugcandidatemarket/`}><span data-hover={langTitleYakuichi}>{langTitleYakuichi}</span></a></li>
              <li><a href={`${langDir}service/whyus/`}><span data-hover={langTitleWhy}>{langTitleWhy}</span></a></li>
              <li><a href={`${langDir}service/howitworks/`}><span data-hover={langTitleHow}>{langTitleHow}</span></a></li>
            </ul>
            <p class="f_sub">{`${headlineFund}`}</p>
            <ul>
              <li><a href={`${langDir}service/fund/`}><span data-hover={langTitleFund}>{langTitleFund}</span></a></li>
            </ul>
          </div>
          <div className="f_in_in1_3">
            <p>News</p>
            <ul>
              <li><a href={`${langDir}news/`}><span data-hover={langTitleNews}>{langTitleNews}</span></a></li>
            </ul>
          </div>
          <div className="f_in_in1_4">
            <div className="sign-up"><a href={`/app/signup${langEn ? "" : "#ja"}`}><span>{langTitleSignUp}</span></a></div>
            <div className="contact"><a href={`${langDir}contact/`}><span>{langTitleContact}</span></a></div>
          </div>
        </div>
        <div className="f_in_in2">
          <ul>
            <li className="f_logo1"><a href={langDir}><img src={LogoImg} alt="GEMSEKI"/></a></li>
            <li className="f_logo2"><a href={langFooterUrl} target="_blank" rel="noreferrer"><img src={LogoImgSnbl} alt="SNBL"/></a></li>
            <li className="f_logo3"><a href="https://jp.linkedin.com/company/gemseki" target="_blank" rel="noreferrer"><img src={LogoImgLinkedIn} alt="Linkedin"/></a></li>
          </ul>
        </div>
        <div className="f_in_in3">
          <small>copyright &copy; Gemseki Inc. All Rights Reserved.</small>
          <ul>
            <li><a href={`${langDir}privacy/`}>{langTitlePolicy}</a></li>
            <li><a href={`${langDir}terms/`}>{langTitleTerm}</a></li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

Footer.propTypes = {};
