import React, { useCallback, useEffect } from 'react';
import { useHistory, useParams } from 'react-router';

import { ButtonBack } from '../../parts/ButtonBack';
import { ButtonStar } from '../../parts/ButtonStar';
import { Button } from '../../parts/Button';
import { NcdFiles } from '../../parts/NcdFiles';
import { Card } from '../../parts/Card';
import { InputSelectRequest } from '../../parts/InputSelectRequest';

import '../base.css';
import { useDispatch, useSelector } from 'react-redux';
import { downloadNc, getAsset } from '../../actions/asset';
import { toggleBookmark } from '../../actions/bookmark';
import { loadLicenseeCounts } from '../../actions/dashboard';
import { RequestPopups, useCdaControl, useNcdControl } from '../../parts/RequestPopups';
import { requestNcd, updateNcd } from '../../actions/nc_request';
import { requestCda, updateCda } from '../../actions/cda_request';
import { useTitle } from '../../parts/useTitle';

const EMPTY = [];

export const AssetDetail = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {assetId} = useParams();

  useEffect(() => {
    dispatch(getAsset(assetId));
  }, [dispatch, assetId]);

  const linkageId = useSelector(s => s.core.organization.linkageId);
  const [assetDetail, user] = useSelector(s => [s.asset.assetDetail || EMPTY, s.core.user]);
  useTitle(() => {
    if (assetDetail?._id === assetId && assetDetail?.assetNumber) {
      return `${assetDetail.assetNumber} | Asset detail`;
    } else {
      return false;
    }
  }, [assetId, assetDetail]);

  const isSeeds = assetDetail?.assetType === 'seeds';
  const isTech = assetDetail?.assetType === 'technologies';

  const changeBookmark = async (assetId, bookmarkId) => {
    await dispatch(toggleBookmark(assetId, bookmarkId));
    dispatch(getAsset(assetId));
  };

  const reload = () => {
    dispatch(getAsset(assetId));
    dispatch(loadLicenseeCounts());
  };

  const onNewNcd = useCallback(async (assetId, licenseeOrgId, comment) => {
    return await dispatch(requestNcd(assetId, licenseeOrgId, comment));
  }, [dispatch]);

  const onUpdateNcd = useCallback(async (ncId, newStatus, comment, reasons) => {
    return await dispatch(updateNcd(ncId, newStatus, comment, reasons));
  }, [dispatch]);

  const [showNcdChange, ncdControl] = useNcdControl(user.organizationType, onNewNcd, onUpdateNcd, reload, !assetDetail?.ncStatus);

  const onNewCda = useCallback(async (assetId, licenseeOrgId, comment) => {
    return await dispatch(requestCda(assetId, licenseeOrgId, comment));
  }, [dispatch]);

  const onUpdateCda = useCallback(async (targetId, newStatus, comment, reasons) => {
    return await dispatch(updateCda(targetId, newStatus, comment, reasons));
  }, [dispatch]);

  const [showCdaChange, cdaControl] = useCdaControl(user.organizationType, onNewCda, onUpdateCda, reload, !assetDetail?.cdaStatus);
  
  const dlInfo = {
    assetName: assetDetail?.assetNumber,
    orgLinkageId: linkageId,
    userId: user._id,
  }

  return (
    <div id="container" className="layout-login contsCreateAccount">
      <main>
        <h1><ButtonBack/>{assetDetail?.assetNumber}<ButtonStar selected={!!assetDetail?.bookmarkId} onClick={() => changeBookmark(assetDetail?._id, assetDetail?.bookmarkId)}/></h1>
        <div id="containerMain">
          <Card>
            <table className="tableGray tableGrayThVertical">
              <tbody>
              <tr>
                <th><label className="tableGrayLabel">Asset No.</label></th>
                <td colSpan={3}><p>{assetDetail?.assetNumber}</p></td>
              </tr>
              {
                isSeeds &&
                <tr>
                  <th><label className="tableGrayLabel">Mode of Action</label></th>
                  <td colSpan={3}><p>{assetDetail?.mechanismOfAction}</p></td>
                </tr>
              }
              {
                isSeeds &&
                <tr>
                  <th><label className="tableGrayLabel">Indication</label></th>
                  <td colSpan={3}><p>{assetDetail?.indication}</p></td>
                </tr>
              }
              {
                isSeeds &&
                <tr>
                  <th><label className="tableGrayLabel">Route</label></th>
                  <td colSpan={3}><p>{assetDetail?.route?.join('　　')}</p></td>
                </tr>
              }
              {
                isSeeds &&
                <tr>
                  <th><label className="tableGrayLabel">Modality</label></th>
                  <td colSpan={3}><p>{assetDetail?.modality?.join('　　')}</p></td>
                </tr>
              }
              {
                isSeeds &&
                <tr>
                  <th><label className="tableGrayLabel">Development Stage</label></th>
                  <td colSpan={3}><p>{assetDetail?.developmentStage?.join('　　')}</p></td>
                </tr>
              }
              {
                isSeeds &&
                <tr>
                  <th><label className="tableGrayLabel">Note</label></th>
                  <td colSpan={3}><p style={{whiteSpace: 'pre-wrap'}}>{assetDetail?.note}</p></td>
                </tr>
              }
              {
                isSeeds &&
                <tr>
                  <th><label className="tableGrayLabel">Disease Area</label></th>
                  <td colSpan={3}><p>{assetDetail?.diseaseArea?.join('　　')}</p></td>
                </tr>
              }
              {
                isTech &&
                <tr>
                  <th><label className="tableGrayLabel">Technology</label></th>
                  <td colSpan={3}><p>{assetDetail?.technology}</p></td>
                </tr>
              }
              {
                isTech &&
                <tr>
                  <th><label className="tableGrayLabel">Summary</label></th>
                  <td colSpan={3}>
                    <p style={{whiteSpace: 'pre-wrap'}}>{assetDetail?.summary}</p></td>
                </tr>
              }
              </tbody>
            </table>
          </Card>
          {
            !!assetDetail?.ncs?.length &&
            <Card><NcdFiles
              dlInfo={dlInfo}
              editMode={false}
              valueGroup={assetDetail?.ncs}
              onDownload={async (fileItem) => {
                dispatch(downloadNc(fileItem, assetId, ''));
              }}/></Card>
          }
          <div className="buttonBoxL buttonBoxLBottom">
            {
              !!assetDetail?.assetRoomId &&
              <Button label="Message" size="174px" onClick={() => history.push(`/licensee/room/${assetDetail?.assetRoomId}`)}/>
            }
            {!assetDetail?.ncStatus && <Button label="NCD request" size="207px" onClick={() => showNcdChange(user?.organizationId, assetId, '', {value: 'newRequest'})}/>}
            {assetDetail?.ncStatus && <div className={['buttonBoxLSelect', `cardRequestFooter--${assetDetail?.ncStatus}`].join(' ')}><label>NCD Status</label>
            <InputSelectRequest mode="NCD" status={assetDetail?.ncStatus} onChangeStatus={(newStatus) => showNcdChange(user?.organizationId, assetId, assetDetail?.ncId, newStatus)}/>
            </div>}
            {!assetDetail?.cdaStatus && <Button label="CDA request" size="207px" onClick={() => showCdaChange(user?.organizationId, assetId, '', {value: 'newRequest'})}/>}
            {assetDetail?.cdaStatus && <div className={['buttonBoxLSelect', `cardRequestFooter--${assetDetail?.cdaStatus}`].join(' ')}><label>CDA Status</label>
            <InputSelectRequest mode="CDA" status={assetDetail?.cdaStatus} onChangeStatus={(newStatus) => showCdaChange(user?.organizationId, assetId, assetDetail?.cdaId, newStatus)}/>
            </div>}
          </div>
          <RequestPopups {...ncdControl}/>
          <RequestPopups {...cdaControl}/>
        </div>
      </main>
    </div>
  );
};
