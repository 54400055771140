import React from 'react';
import LogoImg from './assets/logo.svg';
import './header.css';
import { useLocation } from 'react-router';

export const Header = () => {
  const location = useLocation();
  const hash = location.hash || '';
  const pathName = location.pathname.split("/").pop() || '';
  const langEn = hash === "#ja" || hash === "#send_ja" ? false : true;
  const langDir = langEn ? "/en/" : "/";
  const langClass = langEn ? "en" : "ja";
  const langTitleYakuichi = langEn ? "Drug Candidate Marketplace" : "創薬流通市場「薬市楽座」";
  const langTitleAboutTop = langEn ? "About" : "企業紹介";
  const langTitleAbout = langEn ? "About Gemseki" : "会社概要";
  const langTitleMessage = langEn ? "Message" : "社長メッセージ";
  const langTitleMembers = langEn ? "Members" : "メンバー";
  const langTitleLicensing = langEn ? "What is Licensing" : "ライセンスとは";
  const langTitleService = langEn ? "Service" : "サービス";
  const langTitleFund = langEn ? "Fund" : "投資事業に関して";
  const langTitleWhy = langEn ? "Why us?" : "Gemsekiが選ばれる理由";
  const langTitleHow = langEn ? "How it Works?" : "「薬市楽座」のご利用方法";
  const langTitleNews = langEn ? "News" : "ニュース";
  const langTitleContact = langEn ? "Contact" : "お問合せ";
  const langTitleSignIn = langEn ? "Sign in" : "サインイン";
  const langTitleSignUp = langEn ? "Sign up" : "サインアップ";
  const headlineLicensing = langEn ? "Licensing Business" : "ライセンス事業";
  const headlineFund = langEn ? "Fund Business" : "投資事業";

  return (
    <header className={`lang-${langClass}`}>
      <div className="h_in">
        <h1><a href={langDir}><img src={LogoImg} alt="GEMSEKI"/></a></h1>
        <nav className="gnavbox">
          <ul>
            <li className="drug-candidate"><a href={`${langDir}service/drugcandidatemarket/`}><span data-hover={langTitleYakuichi}>{langTitleYakuichi}</span></a></li>
            <li>
              {/* eslint-disable-next-line */}
              <a><span data-hover={langTitleAboutTop}>{langTitleAboutTop}</span></a>
          		<div className="droplist">
            		<div>
                  <a href={`${langDir}about/about_gemseki/`}>{langTitleAbout}</a>
                  <a href={`${langDir}about/message/`}>{langTitleMessage}</a>
                  <a href={`${langDir}about/members/`}>{langTitleMembers}</a>
            		</div>
          		</div>
            </li>
            <li>
              {/* eslint-disable-next-line */}
              <a><span data-hover={langTitleService}>{langTitleService}</span></a>
          		<div className="droplist">
            		<div>
                  <p>{`${headlineLicensing}`}</p>
                  <a href={`${langDir}about/licensing/`}>{langTitleLicensing}</a>
                  <a href={`${langDir}service/drugcandidatemarket/`}>{langTitleYakuichi}</a>
                  <a href={`${langDir}service/whyus/`}>{langTitleWhy}</a>
                  <a href={`${langDir}service/howitworks/`}>{langTitleHow}</a>
                  <p>{`${headlineFund}`}</p>
                  <a href={`${langDir}service/fund/`}>{langTitleFund}</a>
            		</div>
          		</div>
            </li>
            <li><a href={`${langDir}news/`}><span data-hover={langTitleNews}>{langTitleNews}</span></a></li>
            <li><a href={`${langDir}contact/`}><span data-hover={langTitleContact}>{langTitleContact}</span></a></li>
            <li className="sign-in"><a href="/app/"><span data-hover={langTitleSignIn}>{langTitleSignIn}</span></a></li>
            <li className="sign-up"><a href={`/app/signup${langEn ? "" : "#ja"}`}><span>{langTitleSignUp}</span></a></li>
            {pathName === 'signup' ? 
              <>
              {langEn ? 
                <li class="lang ja"><a rel="alternate" hreflang="ja" href="#ja" title="JP"><span data-hover="JP">JP</span></a></li> : 
                <li class="lang en-US en"><a rel="alternate" hreflang="en-US" href="/app/signup" title="EN"><span data-hover="EN">EN</span></a></li>
              }
              </>
              : <div className='langDummy'></div>
            }
          </ul>
        </nav>
      </div>
    </header>
  );
};

Header.propTypes = {
};
