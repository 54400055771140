export const DataDiseaseArea = [
  {label: 'Cardiology', value: 'Cardiology'},
  {label: 'CNS', value: 'CNS'},
  {label: 'Dermatosis', value: 'Dermatosis'},
  {label: 'Diagnosis', value: 'Diagnosis'},
  {label: 'Endocrinology', value: 'Endocrinology'},
  {label: 'Gastroenterology', value: 'Gastroenterology'},
  {label: 'Gynecology', value: 'Gynecology'},
  {label: 'Hematology', value: 'Hematology'},
  {label: 'Immunology/Inflammation', value: 'Immunology/Inflammation'},
  {label: 'Infection', value: 'Infection'},
  {label: 'Liver disease', value: 'Liver disease'},
  {label: 'Metabolic disorder', value: 'Metabolic disorder'},
  {label: 'Oncology: Hematological cancer', value: 'Oncology: Hematological cancer'},
  {label: 'Oncology: Solid cancer', value: 'Oncology: Solid cancer'},
  {label: 'Oncology: Supportive care', value: 'Oncology: Supportive care'},
  {label: 'Ophthalmology', value: 'Ophthalmology'},
  {label: 'Orthopedic', value: 'Orthopedic'},
  {label: 'Otolaryngology', value: 'Otolaryngology'},
  {label: 'Pain/Neuropathy', value: 'Pain/Neuropathy'},
  {label: 'Pediatric', value: 'Pediatric'},
  {label: 'Rare disease', value: 'Rare disease'},
  {label: 'Regenerative medicine', value: 'Regenerative medicine'},
  {label: 'Renal disease', value: 'Renal disease'},
  {label: 'Respiratory', value: 'Respiratory'},
  {label: 'Rheumatology', value: 'Rheumatology'},
  {label: 'Urology', value: 'Urology'},
  {label: 'Vaccine', value: 'Vaccine'},
  {label: 'Others', value: 'Others'},
];

export const DataRoute = [
  {label: 'oral', value: 'oral'},
  {label: 'i.v.', value: 'i.v.'},
  {label: 'topical', value: 'topical'},
  {label: 'transdermal', value: 'transdermal'},
  {label: 's.c.', value: 's.c.'},
  {label: 'inhalation', value: 'inhalation'},
  {label: 'infusion', value: 'infusion'},
  {label: 'IM', value: 'IM'},
  {label: 'implantation', value: 'implantation'},
  {label: 'intraarticular', value: 'intraarticular'},
  {label: 'intracoronary', value: 'intracoronary'},
  {label: 'intravitreal', value: 'intravitreal'},
  {label: 'intranasal', value: 'intranasal'},
  {label: 'intratumoral', value: 'intratumoral'},
  {label: 'Medical device', value: 'Medical device'},
  {label: 'Others', value: 'Others'},
];

export const DataModality = [
  {label: 'Small molecule', value: 'Small molecule'},
  {label: 'Antibody', value: 'Antibody'},
  {label: 'Peptide', value: 'Peptide'},
  {label: 'Protein', value: 'Protein'},
  {label: 'Vaccine', value: 'Vaccine'},
  {label: 'Cell therapy', value: 'Cell therapy'},
  {label: 'Gene therapy', value: 'Gene therapy'},
  {label: 'Natural Ingredients', value: 'Natural Ingredients'},
  {label: 'Nucleic acid', value: 'Nucleic acid'},
  {label: 'Medical device', value: 'Medical device'},
  {label: 'Others', value: 'Others'},
];

export const DataDevelopmentStage = [
  {label: 'Discovery', value: 'Discovery'},
  {label: 'Preclinical', value: 'Preclinical'},
  {label: 'Phase 1', value: 'Phase 1'},
  {label: 'Phase 2', value: 'Phase 2'},
  {label: 'Phase 3', value: 'Phase 3'},
  {label: 'Submission to approval', value: 'Submission to approval'},
  {label: 'Launched', value: 'Launched'},
  {label: 'Others', value: 'Others'},
];

export const DataTypeOfPreferredPartnering = [
  {label: 'Out-licensing', value: 'Out-licensing'},
  {label: 'Co-development', value: 'Co-development'},
  {label: 'Asset sale', value: 'Asset sale'},
  {label: 'Company sale', value: 'Company sale'},
  {label: 'Look for investor', value: 'Look for investor'},
];

export const DataTerritoryForInLicensing = [
  {label: 'Worldwide only', value: 'Worldwide only'},
  {label: 'Worldwide except Licensor’s territory', value: 'Worldwide except Licensor’s territory'},
  {label: 'Regional license', value: 'Regional license'},
  {label: 'Flexible', value: 'Flexible'},
];

export const DataTypeOfCompanyLicensee = [
  {label: 'Pharmaceutical/biotech with sales activities', value: 'Pharmaceutical/biotech with sales activities'},
  {label: 'Start-up pharma/biotech without sales', value: 'Start-up pharma/biotech without sales'},
  {label: 'Drug discovery company', value: 'Drug discovery company'},
  {label: 'Medical device company', value: 'Medical device company'},
  {label: 'CRO/CMO', value: 'CRO/CMO'},
  {label: 'Venture capital', value: 'Venture capital'},
];

export const DataTypeOfCompanyOriginator = [
  {label: 'Pharma', value: 'Pharma'},
  {label: 'Biotech', value: 'Biotech'},
  {label: 'Academy', value: 'Academy'},
  {label: 'Others', value: 'Others'},
];

export const DataPendingReasons = [
  {label: 'Wait for the Progress', value: 'Wait for the Progress'},
  {label: 'Internal Discussion Ongoing', value: 'Internal Discussion Ongoing'},
];

export const DataDeclinedGemsekiReasons = [
  {label: 'Too Early', value: 'Too Early'},
  {label: 'Data Insufficient', value: 'Data Insufficient'},
  {label: 'Strategy Changed', value: 'Strategy Changed'},
  {label: 'Less Competitive', value: 'Less Competitive'},
  {label: 'Not Innovative (MOA)', value: 'Not Innovative (MOA)'},
  {label: 'Unsure Market Size', value: 'Unsure Market Size'},
  {label: 'RD Time and Cost', value: 'RD Time and Cost'},
  {label: 'Weak Patent Protection', value: 'Weak Patent Protection'},
  {label: 'Out of Focus', value: 'Out of Focus'},
  {label: 'Reviewed Before(Direct Contact)', value: 'Reviewed Before(Direct Contact)'},
  {label: 'Others', value: 'Others'},
  {label: 'Originator Declined', value: 'Originator Declined'},
  {label: 'No Response from Potential Licensee', value: 'No Response from Potential Licensee'},
];

export const DataDeclinedLicenseeReasons = [
  {label: 'Too Early', value: 'Too Early'},
  {label: 'Data Insufficient', value: 'Data Insufficient'},
  {label: 'Strategy Changed', value: 'Strategy Changed'},
  {label: 'Less Competitive', value: 'Less Competitive'},
  {label: 'Not Innovative (MOA)', value: 'Not Innovative (MOA)'},
  {label: 'Unsure Market Size', value: 'Unsure Market Size'},
  {label: 'RD Time and Cost', value: 'RD Time and Cost'},
  {label: 'Weak Patent Protection', value: 'Weak Patent Protection'},
  {label: 'Out of Focus', value: 'Out of Focus'},
  {label: 'Reviewed Before(Direct Contact)', value: 'Reviewed Before(Direct Contact)'},
  {label: 'Others', value: 'Others'},
  {label: 'Cancel the Request', value: 'Cancel the Request'},
];

export const DataDeclinedByOriginatorReasons = [
  {label: 'Asset N/A', value: 'Asset N/A'},
  {label: 'Strategy not matched', value: 'Strategy not matched'},
  {label: 'Direct Contact', value: 'Direct Contact'},
  {label: 'Already Partnered', value: 'Already Partnered'},
  {label: 'Reason not disclosed', value: 'Reason not disclosed'},
  {label: 'Other reasons', value: 'Other reasons'},
];
