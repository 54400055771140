import { useLocation } from 'react-router';
import { useMemo } from 'react';

const marksLimit = 1000 * 60 * 60 * 24 * 30;

export const isMark = (dateText) => {
  return dateText && Date.now() < (new Date(dateText).getTime() + marksLimit);
};

export const zero = (v, n = 2) => {
  const vv = v.toString().padStart(n, '0');
  return vv.substring(vv.length - n);
};

export const fdt = (dateText) => {
  if (!dateText) return '';
  return `${fd(dateText)}\u00A0\u00A0${ft(dateText)}`;
};

export const fd = (dateText) => {
  if (!dateText) return '';
  const date = new Date(dateText);
  return `${zero(date.getMonth() + 1)}/${zero(date.getDate())}/${zero(date.getFullYear())}`;
};

export const ft = (dateText) => {
  if (!dateText) return '';
  const date = new Date(dateText);
  return `${zero(date.getHours())}:${zero(date.getMinutes())}`;
};

export const ref = (target, name) => {
  if (name) {
    return (c) => {
      target[name] = c;
    };
  }
  return (c) => {
    if (c) {
      target[c.name] = c;
    }
  };
};

export const useQuery = () => {
  const location = useLocation();
  return useMemo(() => new URLSearchParams(location.search), [location.search]);
};

export const differentValueGroup = (array1, array2, value = (target) => target?.value) => {
  if (array1 === array2) return false;
  if (array1?.length !== array2?.length) return true;
  const len = array1?.length || 0;
  for (let i = 0; i < len; i++) {
    if (value(array1[i]) !== value(array2[i])) return true;
  }
  return false;
};
