import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';

import './pageNavi.css';
import {useLocation} from 'react-router';
import {useQuery} from './refs';

export const PageNavi = ({selected, total}) => {
  const location = useLocation();
  const query = useQuery();
  const naviArray = [];
  selected++;
  for (let i = 0; i < total; i++) {
    if (i === selected - 1) {
      naviArray.push(<span key={`navi:${i}`}>{(i + 1)}</span>);
    } else {
      query.set('page', i.toString());
      naviArray.push(<Link key={`navi:${i}`} to={`?${query}${location.hash}`}>{(i + 1)}</Link>);
    }
  }
  const naviShowArray = [true, true, true, true, true, true, true, true];
  const naviCenter = [];

  if (total <= 1) {
    // ページ数が1しか無い時（全体を非表示）
    naviShowArray[7] = false;
  } else if (total <= 6) {
    // ページ数が6以下の時
    naviShowArray[0] = false;
    naviShowArray[1] = false;
    naviShowArray[2] = false;
    naviShowArray[4] = false;
    naviShowArray[6] = false;
    if (total <= 5) {
      // ページ数が5以下の時
      naviShowArray[5] = false;
      for (let i = 0; i < total; i++) {
        naviCenter.push(naviArray[i]);
      }
    } else {
      // ページ数が6の時
      for (let i = 0; i < 5; i++) {
        naviCenter.push(naviArray[i]);
      }
    }
  } else {
    if (selected < 4) {
      // 現在ページが前寄りの時
      naviShowArray[0] = false;
      naviShowArray[1] = false;
      naviShowArray[2] = false;
      for (let i = 0; i < 5; i++) {
        naviCenter.push(naviArray[i]);
      }
    } else if (total - selected < 3) {
      // 現在ページが後寄りの時
      naviShowArray[4] = false;
      naviShowArray[5] = false;
      naviShowArray[6] = false;
      for (let i = total - 5; i < total; i++) {
        naviCenter.push(naviArray[i]);
      }
    } else {
      // それ以外全て（主にここ）
      for (let i = selected - 3; i < selected + 2; i++) {
        naviCenter.push(naviArray[i]);
      }
    }
  }

  const arrowLink = (num) => {
    const q = new URLSearchParams(query);
    q.set('page', num.toString());
    return `?${q}${location.hash}`;
  };

  return (
    <div className={`pageNavi pageNaviShow--${naviShowArray[7]}`}>
      {selected === 1
        ? <div className="pageNaviArrow pageNaviArrowL"><span style={{opacity: '0.3'}}/></div>
        : <div className="pageNaviArrow pageNaviArrowL"><Link to={arrowLink(selected - 2)}/></div>
      }
      <div className="pageNaviMain">
        <div className={`pageNaviMainL pageNaviShow--${naviShowArray[1]}`}>{naviArray[0]}</div>
        <div className={`pageNaviDivider pageNaviShow--${naviShowArray[2]}`}>…</div>
        <div className={`pageNaviMainC pageNaviShow--${naviShowArray[3]}`}>{naviCenter}</div>
        <div className={`pageNaviDivider pageNaviShow--${naviShowArray[4]}`}>…</div>
        <div className={`pageNaviMainR pageNaviShow--${naviShowArray[5]}`}>{naviArray[total - 1]}</div>
      </div>
      {selected === total
        ? <div className="pageNaviArrow pageNaviArrowR"><span style={{opacity: '0.3'}}/></div>
        : <div className="pageNaviArrow pageNaviArrowR"><Link to={arrowLink(selected)}/></div>
      }
    </div>
  );
};

PageNavi.propTypes = {
  /**
   * 現在ページ
   */
  selected: PropTypes.number,
  /**
   * 総ページ数
   */
  total: PropTypes.number,
};

PageNavi.defaultProps = {
  selected: 0,
  total: 18,
};
