import React, { useEffect } from 'react';

import { ButtonBack } from '../../parts/ButtonBack';
import { Input1Line } from '../../parts/Input1Line';
import { Button } from '../../parts/Button';
import { Card } from '../../parts/Card';

import '../base.css';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { fd, ref } from '../../parts/refs';
import { loadProfiles, updateOrganization } from '../../actions/core';
import { useTitle } from '../../parts/useTitle';

export const OrganizationEdit = () => {
  useTitle('Corporate Account edit');

  const [organization] = useSelector(s => [s.core.organization]);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(loadProfiles());
  }, [dispatch]);

  const forms = {};

  const resolveData = () => {
    return {
      organizationName: forms.organizationName.value,
    };
  };

  return (
    <div id="container" className="layout-login contsOrganizationEdit">
      <main>
        <h1><ButtonBack/>Corporate account edit</h1>
        <div id='containerMain'>
          <Card>
        <table className="tableGray tableGrayThVertical">
          <tbody>
          <tr>
            <th><label className="tableGrayLabel">Date (Company Registered)</label></th>
            <td><p>{fd(organization?.createdAt)}</p></td>
            <th><label className="tableGrayLabel">Date (Company Updated)</label></th>
            <td><p>{fd(organization?.updatedAt)}</p></td>
          </tr>
          <tr>
            <th><label className="tableGrayLabel">Company ID</label></th>
            <td><p>{organization?.displayId}</p></td>
            <th><label className="tableGrayLabel" htmlFor="input1Line-ID--licensee">Company</label></th>
            <td><Input1Line name="organizationName" ref={ref(forms)} size="100%" defaultValue={organization?.organizationName}/></td>
          </tr>
          </tbody>
        </table>
        <div className="buttonBoxCenter">
          <Button label="Cancel" size="150px" type="gray" onClick={() => history.goBack()}/>
          <Button label="Save" size="150px" onClick={() => dispatch(updateOrganization(resolveData(), history, true))}/>
        </div>
        </Card>
        </div>
      </main>
    </div>
  );
};
