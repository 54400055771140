import React from 'react';
import CreatableSelect from 'react-select/creatable';
import Select from 'react-select';
import PropTypes from 'prop-types';
import './inputSelect.css';

export class InputMultiSelect extends React.Component {
  constructor(props) {
    super(props);
    this.selectRef = React.createRef();

    const value = this.props.value || [];
    this.selectedValue = this.props.valueGroup?.filter(v => value.includes(v.value)) || [];
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.valueGroup === prevProps.valueGroup && this.props.value === prevProps.value) {
      return;
    }

    const value = this.props.value || [];
    this.selectedValue = this.props.valueGroup.filter(v => value.includes(v.value));
    this.selectRef.current?.setValue(this.selectedValue);
  }

  get name() {
    return this.props.name;
  }

  get values() {
    const values = this.selectRef.current.getValue();
    return values?.map(v => v?.value).filter(v => !!v) || [];
  }

  render() {
    const {
      name,
      label,
      size,
      valueGroup,
      placeholder,
      selectOnly,
    } = this.props;

    const selectStyles = {
      menu: (provided, state) => ({
        ...provided,
        width: state.selectProps.width,
      }),

      control: (provided, state) => ({
        ...provided,
        padding: '4px 8px',
        width: state.selectProps.width,
      }),

      singleValue: (provided) => {
        return {...provided};
      },
    };

    
    const selectMultiStyles = {
      menu: (provided, state) => ({
        ...provided,
        width: state.selectProps.width,
      }),

      control: (provided, state) => ({
        ...provided,
        padding: '4px 8px',
        width: state.selectProps.width,
      }),

      singleValue: (provided) => {
        return {
          ...provided,
          paddingBottom: '0.3em',
          paddingTop: '0.3em',
          marginBottom: '-0.3em',
          marginTop: '-0.3em',
        };
      },

      multiValue: (provided) => ({
        ...provided,
        backgroundColor: '#D6D6D6',
        borderRadius: '11px',
        color: '#000000',
        margin: '2px 4px',
      }),

      multiValueRemove: (provided) => ({
        ...provided,
        borderRadius: '0 11px 11px 0',
        backgroundColor: '#D6D6D6',
        color: '#000000',
        ':hover': {
          color: '#FFFFFF',
          backgroundColor: '#64C8D2',
        },
      }),
    };

    const selectTag = !selectOnly ?
    (      
    <CreatableSelect
      isClearable
      isMulti
      className={['inputSelect', this.selectedValue ? '' : 'default'].join(' ')}
      id={`inputSelect-ID--${name}`}
      styles={selectStyles}
      width={size}
      ref={this.selectRef}
      defaultValue={this.selectedValue}
      placeholder={placeholder}
      options={valueGroup}
    />)
  :
    ( 
    <Select
      isClearable
      isMulti
      className={['inputSelect inputSelectMulti', this.selectedValue ? '' : 'default'].join(' ')}
      id={`inputSelect-ID--${name}`}
      styles={selectMultiStyles}
      width={size}
      ref={this.selectRef}
      defaultValue={this.selectedValue}
      placeholder={placeholder}
      options={valueGroup}
      closeMenuOnSelect={false}
    />);

    if (label !== "") {
      return (
        <label className='inputSelectLabel'>
          <span>{label}</span>
          {selectTag}
        </label>
      );
    } else {
      return (
        selectTag
      );
    }
  }
}

InputMultiSelect.propTypes = {
  /**
   * 要素の名前　重複注意
   */
  name: PropTypes.string.isRequired,
  /**
   * 入力エリアの幅
   */
  size: PropTypes.string,
  /**
   * 選択中の値
   */
  value: PropTypes.arrayOf(PropTypes.string),
  /**
   * チェックリストの配列
   */
  valueGroup: PropTypes.array,
  /**
   * placeholder
   */
  placeholder: PropTypes.string,
};

InputMultiSelect.defaultProps = {
  name: 'name',
  placeholder: 'Select',
  size: '200px',
  valueGroup: [
    {label: 'label1', value: 'value1'},
    {label: 'label2', value: 'value2'},
    {label: 'label3', value: 'value3'},
  ],
};
