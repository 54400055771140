import React, { useEffect, useRef, useState } from 'react';

import '../base.css';
import { ButtonBack } from '../../parts/ButtonBack';
import { Input1Line } from '../../parts/Input1Line';
import { Button } from '../../parts/Button';
import { MemberList } from '../../parts/MemberList';
import { Card } from '../../parts/Card';

import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { deleteMembers, inviteMember, loadProfiles } from '../../actions/core';
import { Popup } from '../../parts/Popup';
import { useTitle } from '../../parts/useTitle';

export const Members = () => {
  useTitle('Edit & invite members');

  const dispatch = useDispatch();
  const history = useHistory();
  const [targets, setTargets] = useState([]);

  useEffect(() => {
    dispatch(loadProfiles());
  }, [dispatch]);

  const [user, organization, members] = useSelector(s => [s.core.user, s.core.organization, s.core.members || []]);

  const emailRef = useRef(null);
  const membersRef = useRef(null);
  const popupRef = useRef(null);

  const resolveData = () => {
    return {
      email: emailRef.current.value,
    };
  };

  return (
    <div id="container" className="layout-login contsMemberInvite">
      <main>
      <h1><ButtonBack/>Edit &amp; invite members</h1>
      <div id='containerMain'>
      <div className="accountBody">
            <h2>New member invitation</h2>
            <Card>
            <p>Enter the email address of the person you want to invite below, and click the Send button. An email will be sent to the email address you entered.<br/>Please click on the URL in the email to complete the procedure.</p>
            <div className="memberInviteSend">
              <Input1Line type="email" name="email" ref={emailRef} size="400px" label="Email address" iconType="none"/>
              <Button label="Send" size="150px" onClick={async () => {
                if (await dispatch(inviteMember(organization._id, resolveData()))) {
                  emailRef.current.value = '';
                }
              }}/>
            </div>
            </Card>
          </div>
          <div className="accountBody">
            <h2>New member invitation</h2>
              <Card>
        <MemberList
          name="members"
          ref={membersRef}
          editMode={true}
          organization={organization}
          valueGroup={members}
          gemsekiId={user?._id}
          onChanged={() => {
            setTargets(membersRef.current.values.map(id => {
              const member = members.find(m => m._id === id);
              return {_id: id, name: `${member?.firstName} ${member?.lastName}`};
            }));
          }}
        />
        <div className="buttonBoxCenter">
          <Button label="Cancel" size="150px" type="gray" onClick={() => history.goBack()}/>
          <Button label="Delete" size="150px" disabled={!targets.length} onClick={() => popupRef.current.show()}/>
        </div>
              </Card>
              </div>
        <Popup name="popup" ref={popupRef} title="Delete the following user accounts." submitButton="OK" onSubmit={async () => {
          if (await dispatch(deleteMembers(organization._id, targets.map(t => t._id)))) {
            popupRef.current.hide();
          }
        }}>
          <p>
            {targets.map(t => <strong style={{display: 'block'}} key={`deleteTarget:${t._id}`}>{t.name}</strong>)}
          </p>
          <p>Please note that not only will you be removed from company, but also your entire user account.</p>
        </Popup>
        </div>
      </main>
    </div>
  );
};
