import React from 'react';

import '../base.css';
import Template from '../../temp/Gemseki_Active Promotion.png';
import { DebugMenu } from '../../parts/DebugMenu';
import { useTitle } from '../../parts/useTitle';

export const ActivePromotion = () => {
  useTitle('Active Promotion');

  return (
    <div>
      <img src={Template} alt="template"/>
      <DebugMenu links={[
        {name: 'Dashboard', to: '/gemseki'},
        {name: 'Asset search', to: '/gemseki/assets'},
        {name: 'Asset detail', to: '/gemseki/asset/xxx'},
        {name: 'Licensee detail', to: '/gemseki/licensee/xxx'},
        {name: 'Originator detail', to: '/gemseki/originator/xxx'},
        {name: 'Message detail', to: '/gemseki/room/xxx'},
      ]}/>
    </div>
  );
};
