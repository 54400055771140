import { useHistory, useLocation } from 'react-router';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { redirectToLogin } from '../actions/core';

export const AssetRedirection = () => {
  const [loginError, user] = useSelector(s => [s.core.loginError, s.core.user]);
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    if (loginError) {
      dispatch(redirectToLogin(history, location));
    } else if (user?.organizationType) {
      history.replace(`/${user.organizationType}${location.pathname}`);
    }
  }, [dispatch, history, location, loginError, user]);

  return '';
};
