import { ButtonBack } from '../../parts/ButtonBack';
import { Input1Line } from '../../parts/Input1Line';
import { CheckBoxGroup } from '../../parts/CheckBoxGroup';
import { InputSelect } from '../../parts/InputSelect';
import { Button } from '../../parts/Button';
import { ref } from '../../parts/refs';
import { Card } from '../../parts/Card';

import '../base.css';
import { useDispatch } from 'react-redux';
import { DataDevelopmentStage, DataDiseaseArea, DataModality, DataRoute, DataTerritoryForInLicensing, DataTypeOfCompanyLicensee } from '../../reducers/datas';
import { createLicensee } from '../../actions/core';
import { useHistory } from 'react-router';
import { useCountries } from '../../parts/useCountries';
import { useTitle } from '../../parts/useTitle';

export const LicenseeAdd = () => {
  useTitle('Licensee add');

  const dispatch = useDispatch();
  const history = useHistory();

  const forms = {};

  const resolveData = () => {
    return {
      organizationType: 'licensee',
      organizationName: forms.organizationName.value,
      area: forms.area.value,
      country: countryRef.current.value,
      typeOfCompany: forms.typeOfCompany.values,
      diseaseArea: forms.diseaseArea.values,
      route: forms.route.values,
      modality: forms.modality.values,
      developmentStage: forms.developmentStage.values,
      territoryForInLicensing: forms.territoryForInLicensing.values,
      licensingCriteria: forms.licensingCriteria.value,
      url: forms.url.value,
    };
  };
  const [areas, countries, areaCallback, countryRef] = useCountries();

  return (
    <div id="container" className="layout-login contsOrganizationEdit">
      <main>
        <h1><ButtonBack/>Licensee add</h1>
        <div id="containerMain">
        <Card>
        <table className="tableGray tableGrayThVertical">
          <tbody>
          <tr>
            <th><label className="tableGrayLabel">Date (Company Registered)</label></th>
            <td><p>-</p></td>
            <th><label className="tableGrayLabel">Date (Company Updated)</label></th>
            <td><p>-</p></td>
          </tr>
          <tr>
            <th style={{paddingTop:"26px"}}><label className="tableGrayLabel">Company ID</label></th>
            <td style={{paddingTop:"26px"}}><p>-</p></td>
            <th style={{paddingTop:"26px"}}><label className="tableGrayLabel" htmlFor="input1Line-ID--licensee">Company</label></th>
            <td><Input1Line name="organizationName" ref={ref(forms)} size="100%" placeholder="Type here…"/></td>
          </tr>
          <tr>
            <th style={{paddingTop:"26px"}}><label className="tableGrayLabel">Area, Country</label></th>
            <td colSpan="3">
              <div className="buttonBoxL">
                <InputSelect name="area" ref={ref(forms)} size="185px" placeholder="Area" valueGroup={areas} onChange={areaCallback}/>
                <InputSelect name="country" ref={countryRef} size="290px" placeholder="Country" valueGroup={countries}/>
              </div>
            </td>
          </tr>
          <tr>
            <th><label className="tableGrayLabel">Type of Company</label></th>
            <td colSpan="3">
              <CheckBoxGroup name="typeOfCompany" ref={ref(forms)} valueGroup={DataTypeOfCompanyLicensee}/>
            </td>
          </tr>
          <tr>
            <th><label className="tableGrayLabel">Licensing Criteria<br/>(Disease Area)</label></th>
            <td colSpan="3">
              <CheckBoxGroup name="diseaseArea" ref={ref(forms)} valueGroup={DataDiseaseArea}/>
            </td>
          </tr>
          <tr>
            <th><label className="tableGrayLabel">Licensing Criteria (Route)</label></th>
            <td colSpan="3">
              <CheckBoxGroup name="route" ref={ref(forms)} valueGroup={DataRoute}/>
            </td>
          </tr>
          <tr>
            <th><label className="tableGrayLabel">Licensing Criteria (Modality)</label></th>
            <td colSpan="3">
              <CheckBoxGroup name="modality" ref={ref(forms)} valueGroup={DataModality}/>
            </td>
          </tr>
          <tr>
            <th><label className="tableGrayLabel">Licensing Criteria<br/>(Development Stage)</label></th>
            <td colSpan="3">
              <CheckBoxGroup name="developmentStage" ref={ref(forms)} valueGroup={DataDevelopmentStage}/>
            </td>
          </tr>
          <tr>
            <th><label className="tableGrayLabel">Licensing Criteria (Territory)</label></th>
            <td colSpan="3">
              <CheckBoxGroup name="territoryForInLicensing" ref={ref(forms)} valueGroup={DataTerritoryForInLicensing}/>
            </td>
          </tr>
          <tr>
            <th style={{paddingTop:"26px"}}><label className="inputBoxLabel" htmlFor="input1Line-ID--licensingCriteria">Specific Requirements</label></th>
            <td colSpan="3"><Input1Line name="licensingCriteria" ref={ref(forms)} size="500px" placeholder="Type here…"/></td>
          </tr>
          <tr>
            <th style={{paddingTop:"26px"}}><label className="inputBoxLabel" htmlFor="input1Line-ID--url">Website</label></th>
            <td colSpan="3"><Input1Line name="url" ref={ref(forms)} size="500px" placeholder="Type here…"/></td>
          </tr>
          </tbody>
        </table>
        <div className="buttonBoxCenter">
          <Button label="Cancel" size="150px" type="gray" onClick={() => history.goBack()}/>
          <Button label="Save" size="150px" onClick={() => dispatch(createLicensee(resolveData(), history))}/>
        </div>
        </Card>
        </div>
      </main>
    </div>
  );
};
