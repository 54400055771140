import core from './core.js';
import asset from './asset.js';
import bookmark from './bookmark.js';
import cda_request from './cda_request.js';
import nc_request from './nc_request.js';
import dashboard from './dashboard.js';
import asset_room from './asset_room.js';

/**
 * @typedef {object} DefaultRootState
 * @property {object} core
 * @property {object} asset
 * @property {object} bookmark
 * @property {object} cda_request
 * @property {object} nc_request
 * @property {object} dashboard
 * @property {object} asset_room
 */
export const reducers = {
  core,
  asset,
  bookmark,
  cda_request,
  nc_request,
  dashboard,
  asset_room,
};
