import { loadLocalStorage } from '../actions/api.js';
import {
  REQUEST_DASHBOARD,
  RECEIVE_DASHBOARD,
} from '../actions/dashboard';

const initState = loadLocalStorage('dashboard');

export default function dashboard(state = initState, action) {
  const {type, ...data} = action;
  switch (type) {
    case REQUEST_DASHBOARD:
    case RECEIVE_DASHBOARD:
      return {
        ...state,
        ...data,
      };
    default:
      return state;
  }
};
