import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './input1Line.css';

export const Input1Line = React.forwardRef((
  {
    name,
    validationName,
    type,
    size,
    label,
    required,
    iconType,
    ...props
  }, ref) => {

  var [isOpen, setIsOpen] = useState(true);
  const onOpenClose = () => {
    setIsOpen(!isOpen);
  };
  
  return (
    <div
      className={['input1LineBox', `input1LineBoxOpen--${isOpen}`].join(' ')}
    >
      {(label !== '') &&
        <label htmlFor={`input1Line-ID--${name}`}>{label}{required === true && <span className="required_ico">*</span>}</label>
      }
      <input
        className={['input1Line', `input1Line--${iconType}`, `input-${validationName || name}`].join(' ')}
        id={`input1Line-ID--${name}`}
        name={name}
        style={{width: size}}
        type={(type === 'password' && isOpen === false) ? "text" : type}
        ref={ref}
        maxLength="255"
        {...props}
      >
      </input>
      {(type === 'password') &&<div className='input1LineEye' onClick={() => onOpenClose()}></div>}
    </div>
  );
});

Input1Line.propTypes = {
  /**
   * Input のタイプ
   */
  type: PropTypes.oneOf(['text', 'email', 'password', 'tel']),
  /**
   * 要素の名前　重複注意
   */
  name: PropTypes.string.isRequired,
  /**
   * バリデーションエラー時に要素判別用として利用する名前（省略時は name）
   */
  validationName: PropTypes.string,
  /**
   * 入力エリアの幅
   */
  size: PropTypes.string,
  /**
   * 空でない場合に入力エリアの上にラベル表示
   */
  label: PropTypes.string,
  /**
   * 初期入力値
   */
  value: PropTypes.string,
  /**
   * placeholder　文字を入力すると消える
   */
  placeholder: PropTypes.string,
  /**
   * 必須か
   */
  required: PropTypes.bool,
  /**
   * アイコンのタイプ
   */
  iconType: PropTypes.oneOf(['none', 'search']),
};

Input1Line.defaultProps = {
  type: 'text',
  name: 'name',
  size: '100%',
  label: '',
  required: false,
  iconType: 'none',
  placeholder: 'Type here…',
};
