import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { Route, Switch } from 'react-router-dom';
import { useEffect } from 'react';

import { RoutesLicensee } from './RoutesLicensee';
import { RoutesGemseki } from './RoutesGemseki';
import { RoutesCommon } from './RoutesCommon';
import { fetchUser } from '../actions/core';
import { api } from '../actions/api';

const showUrl = process.env.REACT_APP_SHOW_API_URL === 'true';

export const Routes = () => {
  const loading = useSelector(s => s.core.loading);
  const location = useLocation();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchUser());
  }, [dispatch]);
  useEffect(() => {
    const body = document.getElementsByTagName('html')[0] || document.getElementsByTagName('body')[0];
    body.scrollTop = 0;
  }, [location]);
  return (
    <div id="ContentRoute" className={loading ? 'loading' : ''}>
      <Switch>
        <Route path="/licensee" component={RoutesLicensee}/>
        <Route path="/gemseki" component={RoutesGemseki}/>
        <Route path="/" component={RoutesCommon}/>
      </Switch>
      <div className='loadingPanel'></div>
      {showUrl && <div style={{position: 'fixed', left: '16px', bottom: 0}}><p>Api URL: {api.apiUrl}</p></div>}
    </div>
  );
};
